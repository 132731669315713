import { paymentAPIService } from "../services/paymentAPIService";
import { useMutation, useQuery } from "@tanstack/react-query"


export const useGetPaymentMethods = (onSuccess ) => {
    const { mutateAsync, data, isLoading, error, refetch, isFetched } = 
    useMutation(payload => paymentAPIService.getPaymentMethods(payload), {
        onSuccess: data => {
          onSuccess(data)
        },
        onError: e => {
          onSuccess(e?.response?.data)
          console.log("e?.response?.data", e?.response?.data)
          console.error(e)
        }
    })
  
    return {
        getPymentData: mutateAsync,
        paymentMethods: data,
        isLoading,
        isFetched,
        refetchPaymentMethods: refetch,
        error
    }
}

export const useGetBusinessAccounts = (onSuccess) => {
    const { mutateAsync, data, isLoading, error, refetch, isFetched } = 
    useMutation(payload => paymentAPIService.getBusinessAccounts(payload),{
      onSuccess: res => {
        // console.log("data  ++ ", res)
        onSuccess(res)
      },
      onError: e => {
        console.log(e?.response?.data)
        console.log("e?.response?.data", e?.response?.data)
        console.error(e)
      }}
    )
  
    return {
      getBusinessAccounts: mutateAsync,
      businessAccounts: data,
      isLoadingBA: isLoading,
      isFetchedBA: isFetched,
      refetchBusinessAccounts: refetch,
      error
    }
}

export const useAddPaymentMethod = (onSuccess, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => paymentAPIService.addPaymentMethod(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        onError(e)
      }
    })

  return {
    addPaymentMethod: mutateAsync,
    isAddingPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useAddBusinessAccount = onSuccess => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(
      payload => paymentAPIService.addBusinessAccount(payload),
      {
        onSuccess: data => {
          onSuccess(data)
        },
        onError: e => {
          console.error(e)
        }
      }
    )

  return {
    addBusinessAccount: mutateAsync,
    isAddingBusinessAccount: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const usePayQuote = (onSuccess = () => {}, onError = () => {}) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => paymentAPIService.payQuote(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        console.error(e)
        onError(e?.response)
      }
    })

  return {
    payQuote: mutateAsync,
    isPayingQuotation: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useGetPaymentMethod = (onSuccess = () => {}, onError = () => {}) => {
  const { mutateAsync, data, isLoading, error, refetch } = 
    useMutation((payload) => paymentAPIService.getPaymentMethod(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        console.error(e)
        onError(e?.response)
      }
    }
  )

  return {
    getPaymentMethod: mutateAsync,
    paymentMethod: data,
    isLoading,
    refetchPaymentMethod: refetch,
    error
  }
}

export const useSetDefaultPaymentMethod = (onSuccess, onErrorDefault) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => paymentAPIService.setDefaultPaymentMethod(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        console.error(e)
        onErrorDefault(e?.response)
      }
    })

  return {
    setDefaultPaymentMethod: mutateAsync,
    isSettingDefaultPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useDeletePaymentMethod = onSuccess => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(payload => paymentAPIService.deletePaymentMethod(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        console.error(e)
      }
    })

  return {
    deletePaymentMethod: mutateAsync,
    isDeletingPaymentMethod: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useGetBusinessAccount = onSuccess => {
  const { mutateAsync, data, isLoading, error, refetch } = 
    useMutation((payload) => paymentAPIService.getBusinessAccount(payload), {
      onSuccess: data => {
        onSuccess(data)
      },
      onError: e => {
        console.error(e)
      }
    }
  )

  return {
    getPaymentMethod: mutateAsync,
    businessAccount: data,
    isLoading,
    refetchBusinessAccount: refetch,
    error
  }
}

export const useDeleteBusinessAccount = onSuccess => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(
      payload => paymentAPIService.deleteBusinessAccount(payload),
      {
        onSuccess: data => {
          onSuccess(data)
        },
        onError: e => {
          console.error(e)
        }
      }
    )

  return {
    deleteBusinessAccount: mutateAsync,
    isDeletingBusinessAccount: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}

export const useUpdateBusinessAccount = onSuccess => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } =
    useMutation(
      payload => paymentAPIService.updateBusinessAccount(payload),
      {
        onSuccess: data => {
          onSuccess(data)
        },
        onError: e => {
          console.error(e)
        }
      }
    )

  return {
    updateBusinessAccount: mutateAsync,
    isUpdatingBusinessAccount: isLoading,
    isSuccess,
    data,
    isError,
    error
  }
}