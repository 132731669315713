
import { useEffect, useState } from 'react';
import { Dropdown, Menu, Pagination, } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import BusinessIcon from '@mui/icons-material/Business';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useLocation, useNavigate } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

import user from "../../assets/Ellipse 14.png";
import TextInput from '../../common/inputs/TextInput';
import filter from "../../assets/filter_small.png";
import HyperLinkTest from '../../common/HyperLinkText';
import select from "../../assets/vendor-select.png";
import VendorProfile from '../../components/VendorProfile';
import LocationIcon from "../../assets/location_sign.png"
import Layout from '../../components/Layout';
import { useGetDealers, useDeleteDealer } from '../../container/hooks/useDealers';
import logo from '../../assets/jones_logo.png'
import Button from '../../common/Button';
import AddDealer from "../../components/AddDealer"
import Utils from '../../../utils';
import EmptyVendors from '../EmptyVendors';
import { translate } from '../../../helper';
import LocalStorage from '../../../defined/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../themes/Colors';
import { useCreateOrder, useUpdateOrder } from '../../container/hooks/useOrders';
import OrderPlacedModal from '../../components/OrderPlacedModal';
import { UserAction } from '../../../redux/action';

const brand = JSON.parse(LocalStorage.getItem('branding'))

const filterOptions = (
    <Menu
        className="dropdown-logout"
        items={[
            {
                label: translate('searchDealerByName'),
                key: '1',
                onClick: () => {
                    // setTimeout(()=>{
                    //   i18n.changeLanguage('en', ()=>{
                    //     window.location.reload()
                    //   })
                    // }, 500)
                },
                className: 'item-dropdown-user menu-text'
            },
            {
                label: translate('searchDealerByLocation'),
                key: '2',
                onClick: () => {
                    // setTimeout(()=>{
                    //   i18n.changeLanguage('es', ()=>{
                    //     window.location.reload()
                    //   })
                    // }, 500)
                },
                className: 'item-dropdown-user menu-text'
            }
        ]}
    />
)

async function urlToFile(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename);
}

function Vendors(props) {
    const {
        showCheckbox,
        // handleSubmit,
        // isOrdering,
        // selectedID,
        // setSelectedID,
        // isCreatingOrder
    } = props
    const location = useLocation()
    // console.log("location", location.state)
    const {
        isCreatingOrder=false,
        isOrdering=false,
        orderData,
    } = location.state || {}
    const newOrder = location.state
    // console.log("location.state: venodrs ", location.state)
    const whiteLabel = useSelector((state) => state.User?.branding);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [vendors, setVendors] = useState([])
    const [isViewProfile, setIsViewPrifile] = useState(false)
    const [profile, setProfile] = useState({})
    const [showAddDealerModal, setShowAddDealerModal] = useState(false)
    const [searchBy, setSearchBy] = useState('address')
    const [orderBy, setOrderBy] = useState("address")
    const [OrderData, setOrderData] = useState({})
    const [searchInput, setSearchInput] = useState('')
    const [dealers, setDealers] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalVendors, setTotalVendors] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [selectedID, setSelectedID] = useState([])
    const [finalData, setFinalData] = useState({})
    const [isOrderPlaced, setIsOrderPlaced] = useState(false)
    const [orderResponse, setOrderResponse] = useState('')
    const [vendorSelectError, setVendorSelectError] = useState('')

    const { getDealers, reset, error, isSuccess, isError } = useGetDealers(getDealersData, errorGetDealerData)
    const { createOrder, isCreatingOrder: isCreatingOrderLoading, isOrderError, orderError, resetOrder } =
        useCreateOrder(onCreateOrder, onCreateOrderError)
    const { updateOrder, isUpdatingOrder } = useUpdateOrder(onSuccessUpdate)
    // console.log("get dealers ======> ", isLoading, error, isSuccess)

    const search = {
        "business_name": translate('name'),
        "address": translate('location')
    }

    const { deleteDealer, isDeletingDealer } = useDeleteDealer(
        onSuccessDelete,
        onErrorDelete
    )

    function onCreateOrder(data) {
        setFinalData(data)
        setIsOrderPlaced(true)
        setOrderResponse('success')
    }

    function onCreateOrderError(data) {
        // console.log(data)
        setIsOrderPlaced(true)
        setOrderResponse('error')
    }

    function onSuccessUpdate() {
        navigate(Utils.EndPoint.userMobileEndpoints.createOrder)
        LocalStorage.removeItem('newOrder')
        handleClearImages()
    }

    function onSuccessDelete(data) {
        // console.log(data)
        setIsViewPrifile(false)
        Utils.showAlert(1, data.message || 'Dealer deleted successfully!!')
    }

    function onErrorDelete(data) {
        // console.log(data)
        Utils.showAlert(2, data.message || 'Something went wrong!!')
    }

    const vendorId = dealers && dealers[0]?.vendor?.id
    function getDealersData(data) {
        // console.log("dealersss", data, dealers)
        setDealers(data?.data || [])
        setTotalVendors(data?.page?.total_records || 0)
        setIsLoading(false)
    }

    function errorGetDealerData(data) {
        Utils.showAlert(1, data.message || 'Something went wrong!!')
        setIsLoading(false)
    }

    const handleClearImages = () => {
        dispatch(UserAction.resetOrderDetails())
    }

    const handleAddDealer = () => {
        setShowAddDealerModal(!showAddDealerModal)
        if (showAddDealerModal === true) {
            setIsLoading(true)
            getDealers({
                search: '',
                page: page,
                page_size: pageSize
            })
        }
    }

    const searchDealer = (value) => {
        // console.log("value ", value)
        setSearchInput(value)
    }

    const viewProfile = (id) => {
        let selectedProfile = dealers.filter(e => e.id === id)
        setProfile(selectedProfile)
        setIsViewPrifile(true)
    }

    const closeProfile = () => {
        setIsViewPrifile(false);
    };

    const handleOrderPlacedModal = (type) => {
        setIsOrderPlaced(!isOrderPlaced)
        if (isOrderPlaced) {
            if(type != 'err') {
                // setNewOrder({})
                setSelectedID([])
                LocalStorage.removeItem('newOrder')
                handleClearImages()
                navigate(Utils.EndPoint.userMobileEndpoints.createOrder, { replace: true })
                // getOrders({
                //     user__id: user?.id,
                //     ordering: orderBy,
                //     search: searchQuery,
                //     page: page,
                //     page_size: pageSize,
                // })
                // setShowDealers(false)
            }
        }
    }

    const handleSubmit = async (id) => {
        if (isCreatingOrderLoading) {
            return
        }
        setVendorSelectError('')
        const formData = new FormData()
        // console.log("newOrder ", newOrder)
        // for (let i = 0; i < images.length; i++) {
        //     let newImg = await urlToFile(images[i].file, images[i].name)
        //     let obj = {
        //         file: newImg,
        //         id: images[i].id,
        //         type: images[i].type
        //     }
        //     // newImgs.push(obj)
        // }
        for (let index = 0; index < newOrder?.images?.length; index++) {
            const itemImage = newOrder?.images[index]?.file
            let newImg = await urlToFile(newOrder?.images[index]?.file, newOrder?.images[index]?.name)
            // console.log("itemImage ", typeof itemImage, itemImage instanceof File, itemImage.size)
            try {
                formData.append(`images[${index}]image`, newImg, newOrder?.images[index]?.name)
            } catch (error) {
                formData.append(`images[${index}]image`, newImg, newOrder?.images[index]?.name)
            }
        }
        // console.log(newOrder.orderData)
        Object.keys(newOrder.orderData).map(key => {
            if (key === "images") return
            let value = newOrder.orderData[key]
            if (key === "quantity") {
                value = Number(value)
            }
            if (key === "year") {
                // value = Number(value)
                formData.append('make', value)
            }
            if (key === "order_name") {
                // value = Number(value)
                formData.append('name', value)
            }
            if (key === "product_data") {
                // value = Number(value)
                formData.append('vin', value)
            }
            formData.append(key, value)
        })
        //   formData.append("vin", "")
        formData.append("dynamic_attributes", JSON.stringify(newOrder.dynamic_attributes))
        // formData.append(`vendors`, selectedID)
        selectedID.map(vendor => {
            formData.append(`vendors`, vendor)
          })
        // console.log(formData)
        if (!id) {
            createOrder(formData)
        } else {
            updateOrder({formData, id})
        }
    }

    const submitOrder = () => {
        // console.log("submit ")
        if (selectedID?.length==0) {
            setVendorSelectError('Please select a location')
            return;
        }
        handleSubmit(vendorId)
    }

    const orderOptions = (
        <Menu
            className="dropdown-logout"
            items={[
                {
                    label: translate('orderByLocation'),
                    key: '1',
                    onClick: () => {
                        setTimeout(() => {
                            setOrderBy('address')
                        }, 500)
                    },
                    className: 'item-dropdown-user menu-text'
                },
                {
                    label: translate('orderByName'),
                    key: '2',
                    onClick: () => {
                        setTimeout(() => {
                            setOrderBy('business_name')
                        }, 500)
                    },
                    className: 'item-dropdown-user menu-text'
                }
            ]}
        />
    )

    const handleDeleteVendor = (id) => {
        deleteDealer({ id })
    }

    useEffect(() => {
        // const delayDebounceFn = setTimeout(() => {
            setIsLoading(true)
            getDealers({
                search: searchInput,
                page: page,
                page_size: pageSize
            })
        // }, 800)
        // return () => clearTimeout(delayDebounceFn)
    }, [searchInput, page, pageSize])
    // console.log("showAddDealerModal ", showAddDealerModal)
    const layoutProps = {
        ...(isOrdering&&props?.backTo?{backTo: ()=>props?.backTo()}:{})
    }
    return (
        <Layout showProfileIcon={!isOrdering} showNavigation={isOrdering} {...layoutProps} showNotification title={isOrdering ? 'selectLocation' : 'locations'}>
            <div
                style={{
                    // padding: "20px 15px",
                    width: "100%",
                    backgroundColor: whiteLabel?.mobile_background_color||brand?.mobile_background_color||"#FFFFFF",
                    borderRadius: "22px 22px 0 0",
                    marginTop: "-5px",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    
                    // justifyContent: "center",
                    height: "100%",
                }}
            >

                <div className='filter-vendors' style={{padding:'25px 20px 0 20px', height: "15%"}}>
                    <TextInput
                        rows={1}
                        margin={true}
                        value={searchInput}
                        // label={'Order Name'}
                        onChange={({ target: { value } })=>searchDealer(value)}
                        placeholder={translate('searchLocation')}
                        className='filter-area'
                        outerStyle={{
                            margin: 0,
                            padding: "0px 20px 0 20px",
                        }}
                    // rightElement={
                    //     <Dropdown
                    //         className="container-dropdown-logout cursor-pointer"
                    //         overlay={filterOptions}
                    //         trigger={['click']}
                    //     >
                    //         <div className="user-dropdown-link">
                    //             <div className="layout-username">
                    //                 <div className="flex-column">
                    //                     <span className="header-user-name">
                    //                         <img className='filter-icon' src={filter} />
                    //                     </span>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </Dropdown>
                    // }
                    />
                </div>
                {
                    !isLoading && dealers?.length > 0 ? (
                        <>
                            <div className='more-options' style={{paddingRight: 30, justifyContent: "flex-end", alignItems: "center", height: "7%", margin: 0, paddingTop: "10px 0"}}>

                                <Pagination
                                    size="small"
                                    total={totalVendors}
                                    current={page}
                                    onChange={setPage}
                                    showSizeChanger={true}
                                    pageSize={pageSize}

                                />
                                
                            </div>
                            <div className='vendor-list' style={{ height: !isOrdering? '78%': '66%', overflow: "auto", overflowX: 'hidden', padding: '20px 20px'}}>
                                {/* {!isOrdering && <Button onClick={handleAddDealer} style={{ display: "none", marginBottom: "1rem", marginTop: "0", background: "#fff", color: "#000", boxShadow: '0px 4px 20px 0px #02114517' }} ><AddCircleOutlineIcon style={{ marginRight: "5px" }} />Add Dealer</Button>} */}
                                {
                                    dealers?.map((vendor) => (
                                        <div key={vendor.id} className='vendor-profile-data'>
                                            <div className='vendor-board'>
                                                <div className='vendor-data'>
                                                    <div className='vendor-profile'>
                                                        {
                                                            vendor?.logo ? <img onClick={() => isOrdering?setSelectedID([vendor.id]):viewProfile(vendor.id)} src={vendor?.logo ? vendor.logo : logo} style={{ width: "100px", height: "100px", objectFit: "cover"}}/>: <BusinessIcon onClick={() => isOrdering?setSelectedID([vendor.id]):viewProfile(vendor.id)} style={{fontSize: "70px", color: Colors.primary}} />
                                                        }
                                                    </div>
                                                    <div className='vendor-info'>
                                                        <div onClick={() => isOrdering?setSelectedID([vendor.id]):viewProfile(vendor.id)} className='vendor-label'>{vendor.business_name || 'N/A'}</div>
                                                        {
                                                            vendor.description?.length > 0 ? <div className='vendor-desc'>{`${vendor.description?.substring(0, 100)}${vendor.description?.length > 100 ? "..." : ""}`}</div> : <div style={{ height: "45px" }} />
                                                        }
                                                    </div>
                                                    {isOrdering&&<div style={{width: "20px"}} />}
                                                    <div className='vendor-select' style={{ position: "absolute", top: 0, right: "15px" }}>
                                                        {isOrdering ? <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            checked={selectedID?.includes(vendor.id)}
                                                            sx={{
                                                                color: "#E5E7EB",
                                                                '&.Mui-checked': {
                                                                    color: "#10B981",
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                // const ids = [...selectedID];
                                                                // if(e.target.checked) {
                                                                //     ids.push(vendor.id);
                                                                // } else {
                                                                //     const idx = ids.indexOf(vendor.id);
                                                                //     if(idx != -1) {
                                                                //         ids.splice(idx, 1);
                                                                //     }
                                                                // }
                                                                setSelectedID([vendor.id]);
                                                            }}
                                                        />: <></> }
                                                    </div>
                                                </div>
                                                <div className='vendor-address'>
                                                    <img src={LocationIcon} />
                                                    <div style={{fontSize: '11px'}}>{vendor.address}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {isOrdering && <div style={{position: 'unset', bottom: 120, height: "12%", width: '90%', display: "flex", alignItems: "center", flexDirection: "column"}}>
                                {vendorSelectError?<div style={{color: 'red', width: '100%', marginTop: "1rem"}}>{vendorSelectError}</div>: <div style={{width: '100%', marginTop: "1rem", padding: "10px"}} />}
                                <Button style={{ padding: "25px", marginTop: "0rem"}} onClick={submitOrder}>
                                    {isCreatingOrderLoading&&<CircularProgress style={{width: "15px", height: "15px", marginRight: "10px"}}/>}
                                    {translate('sendToLocation')}
                                </Button>
                            </div>}
                        </>
                    ) : (
                        isLoading ? (
                            <CircularProgress/>
                        ) : (
                            <EmptyVendors handleAddDealer={handleAddDealer} />
                        )
                    )
                }
            </div>
            <AddDealer
                open={showAddDealerModal}
                onClose={handleAddDealer}
            />
            <VendorProfile
                open={isViewProfile}
                data={profile}
                className='vendor-modal'
                close={closeProfile}
                onSubmit={handleDeleteVendor}
            />
            <OrderPlacedModal
                open={isOrderPlaced}
                onClose={handleOrderPlacedModal}
                mode={orderResponse}
                data={{
                    po: finalData?.id,
                    order: finalData?.name,
                    created: finalData?.created_at?.split(' ')[0]
                }}
            />
        </Layout>
    )
}

export default Vendors