import LocalStorage from '../../../../defined/localStorage'
import { Utils } from '../../../../utils'
import { signin } from '../Slice/authSlice'

export const login = (values, setSubmitting, navigate, dispatch, onError) => {
  const { email, password } = values
  const dataToSend = {
    username: email,
    password,
  }
  Utils.api.postApiCall(
    Utils.EndPoint.userMobileEndpoints.loginApi,
    dataToSend,
    (respData) => {
      const { data } = respData
      Utils.showAlert(1, data.message || 'Login Success!!')
      dispatch(signin({ data: data }))

      LocalStorage.setItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN, data.token);
      LocalStorage.setItem(LocalStorage.DEFINE_KEY.ROLE, 'User');

      navigate(Utils.EndPoint.userMobileEndpoints.createOrder);
      setSubmitting(false)
    },
    (error) => {
      const { data } = error
      console.error(data);
      onError(data)
      Utils.showAlert(2, data?.non_field_errors?.[0] || "Invalid Credentials");
      setSubmitting(false)
    }
  )
}

export const signup = (values, setSubmitting, navigate, dispatch) => {
  const dataToSend = values;

  Utils.api.postApiCallForm(
    Utils.EndPoint.userMobileEndpoints.signupApi,
    dataToSend,
    (respData) => {
      const { data } = respData
      Utils.showAlert(1, "Verification mail is sent to the registered email");
      navigate(Utils.EndPoint.userMobileEndpoints.signin);
    },
    (error) => {
      const { data } = error
      Utils.showAlert(2, data?.email[0] || 'Some error');
      setSubmitting(false)
    }
  )
}

export const sendOTPAPI = (values, setSubmitting, navigate, dispatch) => {
  const dataToSend = values;

  Utils.api.postApiCallForm(
    Utils.EndPoint.userMobileEndpoints.getOTP,
    dataToSend,
    (respData) => {
      const { data } = respData
      Utils.showAlert(1, "OTP has been sent to your Email ID");
      // navigate(Utils.EndPoint.userMobileEndpoints.signin);
    },
    (error) => {
      const { data } = error
      Utils.showAlert(2, "Failed to send the OTP, try again later")
      // setSubmitting(false)
    }
  )
}

export const verifyOTPAPI = (values, navigate) => {
  Utils.api.postApiCall(
    Utils.EndPoint.userMobileEndpoints.validateOTP,
    values,
    (respData) => {
      const {data:{token}} = respData;
      console.log(token)
      Utils.showAlert(1, "OTP has been verifed");
      navigate(Utils.EndPoint.userMobileEndpoints.reset_password, {state: {token}});
    },
    (error) => {
      console.error("err", error)
      const { data } = error
      Utils.showAlert(2, "Entered OTP is wrong try again")
    }
  )
}

export const resetPassAPI = (values, navigate, forgotPass=false) => {
  const dataToSend = values;
  return new Promise((resolve, reject) => {
    Utils.api.postApiCall(
      Utils.EndPoint.userMobileEndpoints.resetPassAPI,
      dataToSend,
      () => {
        Utils.showAlert(1, "Password has been changed");
        if(forgotPass){
            navigate(Utils.EndPoint.userMobileEndpoints.signin);
            LocalStorage.clear()
        } else {
            navigate(Utils.EndPoint.userMobileEndpoints.settings);
        }
      },
      (error) => {
        console.error("err", error)
        const { data } = error
        Utils.showAlert(2, data)
        resolve(data)
      }
    )
  })
}

export const deleteAccountAPI = (values, navigate) => {
  const dataToSend = values;

  Utils.api.deleteApiCall(
    Utils.EndPoint.userMobileEndpoints.deleteAPI+values,
    '',
    (respData) => {
      const { data } = respData
      Utils.showAlert(1, "Account has been deleted");
      navigate(Utils.EndPoint.userMobileEndpoints.signin);
    },
    (error) => {
      console.error("err", error)
      const { data } = error
      Utils.showAlert(2, data)
      // setSubmitting(false)
    }
  )
}