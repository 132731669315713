import React from 'react';
import { connect } from 'react-redux';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { ROUTER_NAME } from '../../router/routers';
import LoginInput from '../../common/login-input';
import BasePage from '../base';
import withNavigate from '../../component/WithNavigate';
import { convertPxToRem, getValueFromObjectByKeys, MESSAGE_TYPE, messageManager, myLog, translate } from '../../helper';
import { UserAction } from '../../redux/action';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import LocalStorage from '../../defined/localStorage';
import FormBase from '../../common/form-base';
import { SVGImageComponentReact } from '../../themes/Images';
import { animate } from '../../utils/defined';
import { motion } from 'framer-motion';
import { Col, Row } from 'antd';
import './index.module.css';
import Utils from '../../utils';

class Login extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      isLogin: false,
      error: ''
    };
    this.signupAsWhiteLabelPartner = true;
  }

  componentWillMount() {
    this.signupAsWhiteLabelPartner = window.location.hostname.startsWith('move2-llc-move') || window.location.hostname.startsWith('stagem2o') || window.location.hostname.startsWith('localhost');
  }

  onChangeEmail = value => {
    this.setState({
      email: value,
      password:
        value === LocalStorage.getItem(LocalStorage.DEFINE_KEY.userEmailCache)
          ? LocalStorage.getItem(LocalStorage.DEFINE_KEY.userPasswordCache)
          : this.state.password
    });
  };
  onChangePassword = value => {
    this.setState({ password: value });
  };
  handlerLogin = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (this.state.loging) return;
    try {
      const { email, password, rememberMe } = this.state;
      const { navigate, login, saveUserCache } = this.props;

      if (!email || !password) {
        messageManager({
          messageShow: translate('inputWarning'),
          type: MESSAGE_TYPE.warning
        });
        return;
      } else {
        this.setState({ loging: true });
        const resLogin = await login({
          username: email,
          password
        });

        this.setState({ loging: false, error: '' });
        console.log('resLogin ', resLogin);
        if (resLogin?.data?.user?.role === 'White Label Partner Admin') {
          navigate(ROUTER_NAME.dashboard);
        } else {
          navigate(ROUTER_NAME.order);
        }
      }
    } catch (error) {
      console.log('error ', error);
      this.setState({ loging: false, error: error?.error || '' });

      if (error.non_field_errors) {
        messageManager({
          type: MESSAGE_TYPE.error,
          messageShow: error?.error || error.non_field_errors || 'Login Failed!'
        });
      } else {
        const { message, email, detail, password, username } = error;
        messageManager({
          type: MESSAGE_TYPE.error,
          messageShow: username?.[0] || error?.detail || password?.[0] || 'Login Failed!'
        });
      }
    }
  };
  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  onKeyDown = e => {
    myLog('onKeyDowwn=>', e.key);
    if (e.key === 'Enter') {
      // this.handlerLogin();
    }
  };

  renderContent() {
    myLog('renderContent=>', this.props);
    const { email, password, showPassword, isLogin, loging } = this.state;
    const { navigate } = this.props;

    const IconEyePasswordConfirm = showPassword
      ? SVGImageComponentReact.IconEyeOffPassword
      : SVGImageComponentReact.IconEyePassword;
    return (
      <motion.div
        initial={animate.initial}
        animate={animate.animate}
        transition={animate.transition}
      >
        <div
          className="max-width-100 login-wrapper"
          style={{
            paddingTop: convertPxToRem(140),
            paddingBottom: convertPxToRem(20)
          }}
        >
          <TextBase
            style={{ marginBottom: convertPxToRem(11) }}
            size={convertPxToRem(32)}
            fontWeight={600}
            color={Colors.black}
          >
            signIn
          </TextBase>
          <TextBase size={convertPxToRem(18)} color={Colors.grey2}>
            signInMessage
          </TextBase>
          <div className="x">
            <FormBase onSubmit={this.handlerLogin}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={24}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left', marginTop: 40 }}
                      size={convertPxToRem(18)}
                      color={Colors.black}
                    >
                      businessEmail
                    </TextBase>
                    <LoginInput
                      value={email}
                      onChange={this.onChangeEmail}
                      placeholder="enterEmail"
                    />
                  </div>
                </Col>

                <Col span={24} style={{ marginTop: 16 }}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(18)}
                      color={Colors.black}
                    >
                      password
                    </TextBase>
                    <LoginInput
                      value={password}
                      onChange={this.onChangePassword}
                      placeholder="enterPassword"
                      type={showPassword ? 'text' : 'password'}
                      style={{ top: -20 }}
                      rightElement={
                        <IconEyePasswordConfirm
                          className="cursor-pointer"
                          onClick={() => {
                            this.setState({ showPassword: !showPassword });
                          }}
                        />
                      }
                    />
                  </div>
                </Col>
              </Row>
            </FormBase>
          </div>

          <div style={{ marginTop: 16 }}>
            <Row justify={'end'}>
              <TextBase
                onClick={() => navigate(ROUTER_NAME.forgotPassword)}
                pointer
                style={{ textAlign: 'right' }}
                size={convertPxToRem(12)}
                color={Colors.grey}
                className="forgot-password"
              >
                forgotPassword
              </TextBase>
            </Row>
          </div>
          {
            this.state.error && <div
              style={{
                width: '70%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 5px',
                marginLeft: 'auto',
                borderRadius: '8px',
                background: 'rgba(255, 32, 32, 0.05)',
                marginTop: '20px'
              }}
            >
              <ReportProblemIcon style={{ color: '#FF2020' }} />
              <span style={{ fontSize: '14px', fontWeight: 400, color: '#FF2020' }}>{this.state.error}</span>
            </div>
          }
          <div
            style={{ marginBottom: 19, marginTop: 50 }}
            className="flex-center-all max-width-100"
          >
            <ButtonGradient
              onClick={this.handlerLogin}
              textStyle={{ fontWeight: 600 }}
              title="signIn"
              loading={loging}
            />
          </div>
          <div className="flex-row-center-center">
            <TextBase size={convertPxToRem(14)} color={Colors.black}>
              iamUser
            </TextBase>
            <TextBase
              pointer
              fontWeight={600}
              style={{ marginLeft: convertPxToRem(5) }}
              size={convertPxToRem(14)}
              color={Colors.mainColor}
              onClick={() => navigate(`${Utils.EndPoint.userMobileEndpoints.landing}`)}
            >
              user
            </TextBase>
          </div>

          {
            this.signupAsWhiteLabelPartner && (
              <div className="flex-row-center-center">
                <TextBase size={convertPxToRem(14)} color={Colors.black}>
                  signupWhiteLabelPartner
                </TextBase>
                <TextBase
                  pointer
                  fontWeight={600}
                  style={{ marginLeft: convertPxToRem(5) }}
                  size={convertPxToRem(14)}
                  color={Colors.mainColor}
                  onClick={() => navigate(`${ROUTER_NAME.signUpWhiteLabelPartner}`)}
                >
                  clickHere
                </TextBase>
              </div>
            )
          }
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    userCache: getValueFromObjectByKeys(state, ['User', 'userCache'])
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: params => {
      return dispatch(UserAction.login(params));
    },
    saveUserCache: data => {
      return dispatch(UserAction.saveUserCache(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Login));
