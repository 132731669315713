import { 
    // requestRest, 
    request } from "../utils/request";
import {$axios} from "../../../utils/axios.instance"

function getDealers(payload) {
  // console.log("useGetDealers payload ", payload)
  let params = ""
  let field = payload?.field

  if (payload?.search) {
    params = params + `search=` + payload?.search
  }

  // if (payload?.orderBy) {
  //   if (payload?.search) {
  //     params = params + "&"
  //   }
  //   params = params + "ordering=" + payload?.orderBy
  // }
  if (payload?.page_size) {
    if (payload?.search) {
      params = params + "&"
    }
    params = params + "page_size=" + payload?.page_size
  }
  if (payload?.page) {
    if (payload?.search||payload?.page_size) {
      params = params + "&"
    }
    params = params + "page=" + payload?.page
  }
  params = params.length>0? "?"+params: params
  // console.log("params ", )
  return $axios
    .get(`vendors/all/`+params)
    .then(res => {
      // console.log("params ", res)
      return res.data
    })
    .catch(function (error) {
      // console.log("error=>", error)
      throw error
    })
}

function addDealer(payload) {
  return $axios
    .post(`vendors/customer-vendors/`, payload)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      // console.log("error=>", error?.response)
      throw error
    })
}

function deleteDealer(payload) {
  return $axios
    .delete(`vendors/customer-vendors/${payload?.id}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      // console.log("error=>", error?.response)
      throw error
    })
}

export const dealersAPIService = {
  getDealers,
  addDealer,
  deleteDealer
}
