import React from "react";
import BasePage from "../base";

export default class Introduction extends BasePage {
  constructor(props) {
    super(props);
    this.title = "Introduction";
  }
  renderContent() {
    return <div></div>;
  }
}
