import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Navigate, Routes, Outlet, useNavigate } from 'react-router-dom';
import { ROUTER_NAME } from './routers';
import {
  HomePage,
  Login,
  NotFound,
  ForgotPassword,
  Dashboard,
  SignUp,
  Settings,
  Subscription,
  ConnectStripe,
  Payment,
  AddPaymentMethod,
  EditPaymentMethod,
  Order,
  OrderDetail,
  Quotes,
  CreateProfile,
  Analytics,
  Privacy,
  Terms,
  Notification,
  // WhiteLabel
} from '../pages';
import SignUpStepTwo from '../pages/sign-up/signUpStepTwo/SignUpStepTwo.page';

import LocalStorage from '../defined/localStorage';
import LayoutAppPrivate from '../layout/private';
import LayoutAppPublic from '../layout/public';
import LayoutAppStripped from '../layout/stripped';
import EmailVerified from '../pages/email-verified/EmailVerified';
import ResetPassword from '../pages/reset-password';
import Utils from '../utils';
import CreateOrder from '../mobile/pages/CreateOrder';
import Vendors from '../mobile/pages/Vendors';
import PaymentMethod from '../mobile/pages/PaymentMethod';
import UserSettings from '../mobile/pages/Settings';
import WhiteLabel from "../pages/WhiteLabel"
import "../mobile/style.css"
import { CircularProgress, styled } from '@mui/material';
import { UserAction } from '../redux/action';
import { $axios } from '../utils/axios.instance';

const manifest = {
  "short_name": "",
  "name": "",
  "icons": [
    {
      "sizes": "72x72",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "96x96",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "128x128",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "144x144",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "152x152",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "192x192",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "384x384",
      "type": "image/png",
      "purpose": "maskable any"
    },
    {
      "sizes": "512x512",
      "type": "image/png",
      "purpose": "maskable any"
    }
  ],
  "start_url": "/user",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff",
  "orientation": "any",
  "features": [
    "Cross Platform",
    "fast",
    "simple"
  ],
  "categories": [
    "utilities"
  ]
}

const LoadingContainer = styled('div')(({ }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'height': '100vh',
}));

//User Wrappers
const WelcomeWrapper = React.lazy(
  async () => import('../mobile/pages/Welcome')
);
const SigninWrapper = React.lazy(
  async () => import('../mobile/pages/Auth/Signin')
);
const SignupWrapper = React.lazy(
  async () => import('../mobile/pages/Auth/Signup')
);
const ForgotPasswordWrapper = React.lazy(
  async () => import('../mobile/pages/Auth/ForgotPassword')
);
const ResetPasswordWrapper = React.lazy(
  async () => import('../mobile/pages/Auth/ResetPassword')
);
const ProfileWrapper = React.lazy(
  async () => import('../mobile/pages/Auth/Profile')
);
const ProfileUpdate = React.lazy(
  async () => import('../mobile/modules/UserProfile')
);
const ChangePass = React.lazy(
  async () => import('../mobile/pages/ChangePassword')
);
const TermsAndConditionsWrapper = React.lazy(
  async () => import('../mobile/pages/TermsAndConditions')
);
const PrivacyPolicyWrapper = React.lazy(
  async () => import('../mobile/pages/PrivacyPolicy')
);
const QuotesWrapper = React.lazy(
  async () => import('../mobile/pages/Quotes')
);
const QuotesDetailWrapper = React.lazy(
  async () => import('../mobile/pages/Quotes/QuotesDetail')
);
const QuotesOrderWrapper = React.lazy(
  async () => import('../mobile/pages/Quotes/QuotesOrder')
);
const SettingsWrapper = React.lazy(
  async () => import('../mobile/pages/Settings')
);
const NotificationWrapper = React.lazy(
  async () => import('../mobile/pages/Notification')
);
const OrderData = React.lazy(
  async () => import('../mobile/pages/OrderData')
)
const Message = React.lazy(
  async () => import('../mobile/pages/Messages')
)
const OrderDetails = React.lazy(
  async () => import('../mobile/pages/OrderDetails')
)
const OrderQuoteDetails = React.lazy(
  async () => import('../mobile/pages/OrderQuoteDetails')
)
const Notifications = React.lazy(
  async () => import('../mobile/pages/Notifications')
)
const DeleteAccount = React.lazy(
  async () => import('../mobile/pages/DeleteAccount')
)
const AddNewCard = React.lazy(
  async () => import('../mobile/pages/AddNewCard')
)
const AddNewBusinessCard = React.lazy(
  async () => import('../mobile/pages/AddNewBusinessCard')
)
const NotesWrapper = React.lazy(
  async () => import('../pages/notes')
);
const NotesDetailWrapper = React.lazy(
  async () => import('../pages/notes-detail')
);
const ChildAccountsWrapper = React.lazy(
  async () => import('../pages/child-accounts')
);
const ChildAccountsDetailWrapper = React.lazy(
  async () => import('../pages/child-accounts-detail')
);
const VerifyChildAccountsWrapper = React.lazy(
  async () => import('../pages/child-accounts/verify-account')
);
const UserNotification = React.lazy(
  async () => import('../mobile/pages/UserNotifications')
)
const SignupWhiteLabelPartnerWrapper = React.lazy(
  async () => import('../pages/sign-up/whiteLabelPartner')
);
const TeamWrapper = React.lazy(
  async () => import('../pages/team')
);
const TeamDetailWrapper = React.lazy(
  async () => import('../pages/team-detail')
);

const LAYOUT_TYPE = {
  dashboard: 1,
  menu: 2,
  none: 3
};

function PrivateRouteUser(props) {
  const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN);
  const roleUser = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ROLE) == 'User';
  return auth && roleUser ? props.children : <Navigate to={Utils.EndPoint.userMobileEndpoints.signin} />
}

const userRouter = [
  {
    path: Utils.EndPoint.userMobileEndpoints.landing,
    element: <WelcomeWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.welcome,
    element: <WelcomeWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.signin,
    element: <SigninWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.signup,
    element: <SignupWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.forgot_password,
    element: <ForgotPasswordWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.reset_password,
    element: <ResetPasswordWrapper />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.changePassword,
    element: <ChangePass />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.notifications,
    element: <Notifications />,
    isUserRoute: true,
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.profile,
    element: <ProfileWrapper />,
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.updateProfile,
    element: <ProfileUpdate />,
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.delete_account,
    element: <DeleteAccount />,
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.terms,
    element: (
      <TermsAndConditionsWrapper />
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.privacy,
    element: (
      <PrivacyPolicyWrapper />
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.quotes,
    element: (
      <PrivateRouteUser>
        <QuotesWrapper />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.quotes_detail,
    element: (
      <PrivateRouteUser>
        <QuotesDetailWrapper />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.createOrder,
    element: (
      <PrivateRouteUser>
        <OrderData />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.addNewOrder,
    element: (
      <PrivateRouteUser>
        <CreateOrder />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.quotes_order,
    element: (
      <PrivateRouteUser>
        <QuotesOrderWrapper />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.vendors,
    element: (
      <PrivateRouteUser>
        <Vendors />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.settings,
    element: (
      <SettingsWrapper />
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.paymentMethod,
    element: (
      <PrivateRouteUser>
        <PaymentMethod />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.notification,
    element: (
      <NotificationWrapper />
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.settings,
    element: (
      <PrivateRouteUser>
        <UserSettings />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.message,
    element: (
      <PrivateRouteUser>
        <Message />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.orderDetails,
    element: (
      <PrivateRouteUser>
        <OrderDetails />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.editOrder,
    element: (
      <PrivateRouteUser>
        <CreateOrder />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.orderQuote,
    element: (
      <PrivateRouteUser>
        <OrderQuoteDetails />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.addNewCard,
    element: (
      <PrivateRouteUser>
        <AddNewCard />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.editCard,
    element: (
      <PrivateRouteUser>
        <AddNewCard />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.addNewBusinessCard,
    element: (
      <PrivateRouteUser>
        <AddNewBusinessCard />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.editBusinessCard,
    element: (
      <PrivateRouteUser>
        <AddNewBusinessCard />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  },
  {
    path: Utils.EndPoint.userMobileEndpoints.userNotification,
    element: (
      <PrivateRouteUser>
        <UserNotification />
      </PrivateRouteUser>
    ),
    isUserRoute: true
  }
];

const router = [
  {
    path: '/',
    component: Login,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.createProfile,
    component: CreateProfile
  },
  {
    path: ROUTER_NAME.notification,
    component: Notification
  },
  {
    path: ROUTER_NAME.privacy,
    component: Privacy,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.privacyPrivate,
    component: Privacy,
    isNotPrivate: false
  },
  {
    path: ROUTER_NAME.term,
    component: Terms,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.termsPrivate,
    component: Terms,
    isNotPrivate: false
  },
  {
    path: ROUTER_NAME.signUp,
    component: SignUp,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.signUpStepTwo,
    component: SignUpStepTwo,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.signUpWhiteLabelPartner,
    component: SignupWhiteLabelPartnerWrapper,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.emailVerified,
    component: EmailVerified,
    isStripped: true
  },
  {
    path: ROUTER_NAME.login,
    component: Login,
    isLogin: true,
    layoutType: LAYOUT_TYPE.none
  },
  {
    path: ROUTER_NAME.forgotPassword,
    isNotPrivate: true,
    component: ForgotPassword
  },
  {
    path: ROUTER_NAME.resetPassword,
    isNotPrivate: true,
    component: ResetPassword
  },
  {
    path: ROUTER_NAME.home,
    component: HomePage
  },
  {
    path: ROUTER_NAME.dashboard,
    component: Dashboard
  },
  {
    path: ROUTER_NAME.settings,
    component: Settings
  },
  {
    path: ROUTER_NAME.subscription,
    component: Subscription,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.connectStripe,
    component: ConnectStripe,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.payment,
    component: Payment,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.addPaymentMethod,
    component: AddPaymentMethod,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.editPaymentMethod,
    component: EditPaymentMethod,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.order,
    component: Order
  },
  {
    path: `${ROUTER_NAME.orderDetail}/:id`,
    component: OrderDetail
  },
  {
    path: ROUTER_NAME.quotes,
    component: Quotes
  },
  {
    path: ROUTER_NAME.analytics,
    component: Analytics
  },
  {
    path: ROUTER_NAME.team,
    component: TeamWrapper,
    isAdminOnly: true
  },
  {
    path: `${ROUTER_NAME.teamProfile}/:id`,
    component: TeamDetailWrapper,
    isAdminOnly: true
  },
  {
    path: ROUTER_NAME.whiteLabel,
    component: WhiteLabel,
    isAdminOnly: true
  },
  {
    path: '*',
    component: NotFound,
    isNotPrivate: true
  },
  {
    path: ROUTER_NAME.notes,
    component: NotesWrapper
  },
  {
    path: `${ROUTER_NAME.notesDetail}/:id`,
    component: NotesDetailWrapper
  },
  {
    path: ROUTER_NAME.childAccounts,
    component: ChildAccountsWrapper
  },
  {
    path: `${ROUTER_NAME.childAccounts}/:id`,
    component: ChildAccountsDetailWrapper
  },
  {
    path: ROUTER_NAME.verifyChildAccounts,
    component: VerifyChildAccountsWrapper,
    isNotPrivate: true
  },
  ...userRouter
];

const PublicRoute = (props) => {
  const Component = props.component;
  const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN);
  if (auth && !props.isNotLayout) {
    return <Navigate to={ROUTER_NAME.dashboard} replace={true} />;
  }
  return Component ? (
    props.isNotLayout ? (
      <Component />
    ) : (
      <LayoutAppPublic isLandingPage={props.isLandingPage}>
        <Component />
      </LayoutAppPublic>
    )
  ) : (
    <Outlet />
  );
};

const PrivateRoute = (props) => {
  const Component = props.component;
  const auth = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN);
  const authData = useSelector((state) => state?.auth?.data);
  const navigate = useNavigate()
  useEffect(() => {
    if (authData?.user && !authData?.user?.profile?.dealer) {
      navigate(Utils.EndPoint.userMobileEndpoints.createOrder)
    }
  }, [])
  if (!auth) {
    return <Navigate to={ROUTER_NAME.login} replace={true} />;
  }
  return Component ? (
    props.isNotLayout ? (
      <Component />
    ) : props.isLayoutPublic ? (
      <LayoutAppPublic>
        <Component />
      </LayoutAppPublic>
    ) : (
      <LayoutAppPrivate>
        <Component />
      </LayoutAppPrivate>
    )
  ) : (
    <Outlet />
  );
};

const StrippedRoute = (props) => {
  const Component = props.component;
  return (
    <LayoutAppStripped>
      <Component />
    </LayoutAppStripped>
  );
};

const resizeImage = (blob, sizes) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(blob);
    img.src = objectUrl;
    img.onload = () => {
      const resizedImagesPromises = sizes.map(size =>
        new Promise((resolve) => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = size;
          canvas.height = size;
          ctx.drawImage(img, 0, 0, size, size);
          canvas.toBlob((resizedBlob) => {
            const resizedUrl = URL.createObjectURL(resizedBlob);
            resolve({ size, url: resizedUrl });
          }, 'image/jpeg', 0.95);
        })
      );

      Promise.all(resizedImagesPromises)
        .then(results => {
          resolve(results);
          URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
          // console.error('Error resizing the image:', error)
        });
    };

    img.onerror = (err) => {
      URL.revokeObjectURL(objectUrl);
    }
  })

};

function imageResize(imageUrl) {
  return new Promise((resolve) => {
    const encoder = new TextEncoder();
    const buffer = encoder.encode(imageUrl);
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    // console.log("binary ", imageUrl, btoa(imageUrl))
    const domain = window.location.hostname != 'localhost' ? window.location.origin : 'https://www.shoredealsnow.com'

    fetch(`${domain}/api/v1/white-label-partner/fetch-image/?url='${btoa(imageUrl)}'`, {
      method: 'GET',
      // headers: {
      //   'Authorization': `Token ${localStorage.getItem('accessToken')}`
      // },
    })
      .then(response => response.blob())
      .then(blob => {
        let urls = resizeImage(blob, [72, 96, 128, 144, 152, 192, 384, 512]);
        resolve(urls)
      })
  });
}

const RouteNavigation = () => {
  const user = useSelector((state) => state.User?.userInfo);
  const brand = useSelector((state) => state?.User?.branding);

  useEffect(() => {
    async function updateBrandingData() {
      if (brand) {
        const isUser = window.location.pathname?.startsWith('/user')
        let favicon = document.querySelector('link[rel="icon"]');
        if (!favicon) {
          favicon = document.createElement('link');
          favicon.setAttribute('rel', 'icon');
          document.querySelector('head').appendChild(favicon);
        }
        let appleIconElement = document.querySelector('link[rel="apple-touch-icon"]');
        if (!appleIconElement) {
          appleIconElement = document.createElement('link')
          appleIconElement.setAttribute('rel', 'apple-touch-icon')
          document.querySelector('head').appendChild(appleIconElement)
        }
        let appleTitleElem = document.querySelector('meta[name="description"]');
        if (!appleTitleElem) {
          appleTitleElem = document.createElement('meta')
          appleTitleElem.setAttribute('name', 'description')
          document.querySelector('head').appendChild(appleTitleElem)
        }
        let applename = document.querySelector('meta[name="apple-mobile-web-app-title"]')
        if (!applename) {
          applename = document.createElement('meta')
          applename.setAttribute('name', 'apple-mobile-web-app-title')
        }
        applename.setAttribute('content', brand?.location_name)

        let androidmeta = document.querySelector('meta[name="theme-color"]')
        if (!androidmeta) {
          androidmeta = document.createElement('meta')
          androidmeta.setAttribute('name', 'theme-color')
          document.querySelector('head').appendChild(androidmeta)
        }
        if (isUser) {
          androidmeta.setAttribute('content', brand?.mobile_top_banner_color)
        } else {
          androidmeta.setAttribute('content', brand?.web_background_color)
        }

        const head = document.querySelector('head')
        if (brand?.location_name) {
          head.appendChild(applename)
        }
        let body = document.querySelector('body')
        if (isUser) {
          favicon.href = brand?.mobile_logo
          let name = brand?.location_name || ''
          document.title = name;
          let iconElement = document.querySelector('link[rel="icon"]');
          iconElement?.setAttribute('href', brand?.mobile_logo);
          appleIconElement?.setAttribute('href', brand?.mobile_logo);
          appleTitleElem?.setAttribute('content', brand?.location_name);
          body.style.backgroundColor = brand?.mobile_top_banner_color
        } else {
          favicon.href = brand?.web_logo
          let name = brand?.location_name || ''
          document.title = name;
          appleIconElement?.setAttribute('href', brand?.web_logo);
          appleTitleElem?.setAttribute('content', brand?.location_name);
          body.style.backgroundColor = brand?.web_background_color
        }

        let resizedUrl = isUser ? await imageResize(brand?.mobile_logo) : await imageResize(brand?.web_logo);

        manifest.name = brand?.location_name;
        manifest.short_name = brand?.location_name;
        manifest.icons[0].src = resizedUrl[0]?.url;
        manifest.icons[1].src = resizedUrl[1]?.url;
        manifest.icons[2].src = resizedUrl[2]?.url;
        manifest.icons[3].src = resizedUrl[3]?.url;
        manifest.icons[4].src = resizedUrl[4]?.url;
        manifest.icons[5].src = resizedUrl[5]?.url;
        manifest.icons[6].src = resizedUrl[6]?.url;
        manifest.icons[7].src = resizedUrl[7]?.url;
        manifest.theme_color = brand?.mobile_top_banner_color;
        manifest.start_url = `${window.location.origin}${isUser ? '/user' : '/'}`
        manifest.scope = `${window.location.origin}${isUser ? '/user' : '/'}`
        const stringManifest = JSON.stringify(manifest);
        let content = encodeURIComponent(stringManifest)
        let url = "data:application/json;charset=utf-8," + content;
        let element = document.querySelector('link[rel="manifest"]');
        if (!element) {
          element = document.createElement('link');
          element.setAttribute('rel', 'manifest');
          element.setAttribute('id', 'manifest-sheet');
          document?.head?.appendChild(element)
        }

        element?.setAttribute('href', url);
      }
    }
    updateBrandingData()
  }, [brand])

  const dealerRouterFiltered = router.filter((route) => {
    return !route?.isUserRoute
  });

  const routerFiltered = dealerRouterFiltered.filter((route) => {
    return !route?.isAdminOnly && user?.profile?.team_member;
  });

  const routes = user?.profile?.team_member ? routerFiltered : router;

  return (
    <Router>
      <Suspense fallback={<LoadingContainer> <CircularProgress /></LoadingContainer>}>
        <Routes>
          {routes.map((route, index) => {
            return (
              <React.Fragment key={index}>
                {
                  route.isUserRoute == true ? (
                    <Route
                      path={route.path}
                      element={route.element}
                    />
                  ) : (
                    <Route
                      path={route.path}
                      element={
                        route.isNotPrivate || route.isLandingPage || route.isLogin ? (
                          <PublicRoute {...route} />
                        ) : route.isStripped ? (
                          <StrippedRoute {...route} />
                        ) : (
                          <PrivateRoute {...route} />
                        )
                      }
                    />
                  )
                }
              </React.Fragment>
            );
          })}
        </Routes>
      </Suspense>
    </Router>
  );
};
export default RouteNavigation;
