import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utils from '../../utils'

import { ReactComponent as Orders } from '../../../src/assets/image/svg/Orders.svg'
import { ReactComponent as Dealers } from '../../../src/assets/image/svg/Dealers.svg'
import { ReactComponent as Quotes } from '../../../src/assets/image/svg/Quotes.svg'
import { ReactComponent as Settings } from '../../../src/assets/image/svg/Settings.svg'
import { translate } from '../../helper';
import LocalStorage from '../../defined/localStorage';

import { ReactComponent as DealersActive } from '../../../src/assets/image/svg/DealersActive.svg'
import { ReactComponent as OrdersActive } from '../../../src/assets/image/svg/OrdersActive.svg'
import { ReactComponent as QuotesActive } from '../../../src/assets/image/svg/QuotesActive.svg'
import { ReactComponent as SettingsActive } from '../../../src/assets/image/svg/settingActive.svg'

const brand = JSON.parse(LocalStorage.getItem('branding'))


const Footerbg = styled('div')(({ }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return ({
    'position': 'fixed',
    'height': '12%',
    'bottom': '0',
    'display': 'flex',
    'alignItems': 'center',
    'zIndex': '999',
    'width': '100%',
    'textAlign': 'center',
    'boxShadow': '0px -0.5px 0px 0px #0000004D',
    'boxShadow': '0px 4px 14px 0px #00000040',
    'background': whiteLabel?.mobile_background_color || brand?.mobile_background_color || '#FFFFFFF0',
    'padding': '15px 1px',
    '& a': {
      'color': '#383938',
      '& .active': {
        'color': '#FBC916',
        'fontWeight': '600',
      },
      '& svg': {
        'width': '24px',
        'height': '24px',
      },
    },

  })
});
export default function Footer() {

  const location = useLocation();
  const { pathname: activeURL } = location;


  return (
    <>
      <Footerbg>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <NavLink to={Utils.EndPoint.userMobileEndpoints.createOrder}>
              <Box>
                {
                  activeURL.includes('/create-order') ? <OrdersActive /> : <Orders />
                }
                <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{translate('orders')}</Typography>
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={3}>
            <NavLink to={Utils.EndPoint.userMobileEndpoints.vendors}>
              <Box>
                {
                  activeURL.includes('/vendors') ? <DealersActive /> : <Dealers />
                }
                <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{translate('locations')}</Typography>
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={3}>
            <NavLink to={Utils.EndPoint.userMobileEndpoints.quotes}>
              <Box>
                {
                  activeURL.includes('/quotes') ? <QuotesActive /> : <Quotes />
                }
                <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{translate('accpetedQuotes')}</Typography>
              </Box>
            </NavLink>
          </Grid>
          <Grid item xs={3}>
            <NavLink to={Utils.EndPoint.userMobileEndpoints.settings}>
              <Box>
                {
                  activeURL.includes('/settings') ? <SettingsActive /> : <Settings />
                }
                <Typography style={{ fontSize: '12px', fontWeight: '500' }}>{translate('settings')}</Typography>
              </Box>
            </NavLink>
          </Grid>
        </Grid>
      </Footerbg>
    </>
  )
}
