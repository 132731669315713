import { ROUTER_KEY } from '../../router/routers';
import { SVGImageComponentReact } from '../../themes/Images';

export const MenuApp = () => {
  return [
    {
      key: "Dashboard",
      title: "dashboard",
      route: ROUTER_KEY.dashboard,
      icon: SVGImageComponentReact.IconDashBoardApp,
      icon_active: SVGImageComponentReact.IconDashBoardActiveApp,
      isAdminOnly: true,
      isParentAccountOnly: true,
      isSuperUser: true
    },
    {
      key: 'Orders',
      title: 'orders',
      route: ROUTER_KEY.order,
      icon: SVGImageComponentReact.IconMenuOrder,
      icon_active: SVGImageComponentReact.IconOrderActive,
      isSuperUser: true
    },
    {
      key: 'Vendor Notes',
      title: 'vendorNotes',
      route: ROUTER_KEY.notes,
      icon: SVGImageComponentReact.IconNotes,
      icon_active: SVGImageComponentReact.IconNotesActive,
      isSuperUser: true
    },
    {
      key: 'Child Accounts',
      title: 'locations',
      route: ROUTER_KEY.childAccounts,
      icon: SVGImageComponentReact.IconChildAccounts,
      icon_active: SVGImageComponentReact.IconChildAccountsActive,
      isAdminOnly: true,
      isParentAccountOnly: true,
      isSuperUser: true
    },
    {
      key: 'Analytics',
      title: 'analytics',
      route: ROUTER_KEY.analytics,
      icon: SVGImageComponentReact.IconChart,
      icon_active: SVGImageComponentReact.IconChartActive,
      isSuperUser: true
    },
    {
      key: 'Quotes',
      title: 'acceptedQuotes',
      // route: ROUTER_KEY.quotes+"?page=0&sortBy=desc",
      route: ROUTER_KEY.quotes,
      icon: SVGImageComponentReact.IconQuotes,
      icon_active: SVGImageComponentReact.IconQuotesActive,
      isSuperUser: true
    },
    {
      key: 'Team',
      title: 'team',
      route: ROUTER_KEY.team,
      icon: SVGImageComponentReact.TeamIcon,
      icon_active: SVGImageComponentReact.TeamIconActive,
      isAdminOnly: true,
      isSuperUser: true
    },
    {
      key: 'Subscription',
      title: 'subscription',
      route: ROUTER_KEY.subscription,
      icon: SVGImageComponentReact.SubscriptionIcon,
      icon_active: SVGImageComponentReact.SubscriptionActiveIcon,
      isAdminOnly: true
    },
    {
      key: 'ConnectStripe',
      title: 'acceptPayments',
      route: ROUTER_KEY.connectStripe,
      icon: SVGImageComponentReact.PaymentIcon,
      icon_active: SVGImageComponentReact.PaymentActiveIcon,
      isAdminOnly: true,
      isSuperUser: true
    },
    {
      key: 'white-label',
      title: 'whiteLabeling',
      route: ROUTER_KEY.whiteLabel,
      icon: SVGImageComponentReact.WhiteLable,
      icon_active: SVGImageComponentReact.WhiteLableActive,
      isAdminOnly: true,
      isParentAccountOnly: true,
      isChildBranding: true
    }
  ];
};
