import LocalStorage from '../../../defined/localStorage';
import {
  deepCopyObject,
  getValueFromObjectByKeys,
  MESSAGE_TYPE,
  messageManager,
  myLog
} from '../../../helper';
import { mapPlanKey, userKey } from '../../../utils/defined';
import { NORMAL_TYPE, REQUEST_SUBTYPE, REQUEST_TYPE } from '../../action/ActionType';

const initState = {
  userInfo: {
    plans: [
      {
        key: mapPlanKey.FREE
      }
    ],
    avatar: 'https://cdn.pixabay.com/photo/2012/08/06/00/53/bridge-53769_960_720.jpg',
    [userKey.name]: LocalStorage.getItem(LocalStorage.DEFINE_KEY.USER_NAME) || '',
    [userKey.firstName]: '',
    [userKey.lastName]: '',
    [userKey.emailAddress]: '',
    [userKey.phone]: '',
    [userKey.streetAddress]: '',
    [userKey.city]: '',
    [userKey.state]: '',
    [userKey.zipCode]: '',
    [userKey.isMarried]: false,
    // [userKey.isHaveChildren]: false,
    [userKey.isRetirement]: ''
  },
  userDetail: {},
  notification: [],
  vendor: null,
  userCache: null,
  whiteLabel: {},
  notifications: [],
  branding: {},
  order_config: {},
  order_details: {}
};

const isDealerActivated = (data) => {
  // console.log('data', data);
  if (data?.account_status !== 'A') {
    messageManager({
      messageShow: 'Please contact admin to approve your account!',
      type: MESSAGE_TYPE.warning
    });
    return false;
  }
  return true;
};

const UserReducer = (state = initState, action) => {
  // console.log("action===> ", action)
  switch (action.key) {
    case REQUEST_TYPE.GET_NOTIFICATIONS:
      const { data: notifications } = action?.data;
      return { ...state, notifications };

    case REQUEST_TYPE.WHITE_LABEL:
      const { data } = action?.data;
      const whiteLabel = data?.results?.[0];
      return { ...state, whiteLabel };

    case REQUEST_TYPE.LOGIN:
      // console.log("state res=> ", action)
      // case REQUEST_TYPE.REGISTER:
      switch (action.subType) {
        case REQUEST_SUBTYPE.REQUEST:
          return state;
        case REQUEST_SUBTYPE.SUCCESS:
          const userInfo = getValueFromObjectByKeys(action, ['data', 'data'], {});
          if (userInfo && userInfo.token) {
            // TODO THIS SHOULD BE USED ON THE LOGIN
            LocalStorage.setItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN, userInfo.token);
            return userInfo && Object.keys(userInfo).length > 0 ? { ...state, userInfo } : state;
          }
          return state;
        case REQUEST_SUBTYPE.ERROR:
          return state;
        default:
          return state;
      }
    case REQUEST_TYPE.GET_PROFILE:
    case REQUEST_TYPE.UPDATE_PROFILE:
      switch (action.subType) {
        case REQUEST_SUBTYPE.REQUEST:
          return state;
        case REQUEST_SUBTYPE.SUCCESS:
          myLog('GET_PROFILE=>', action.data);
          const profile = getValueFromObjectByKeys(action, ['data', 'data'], {});
          return profile && Object.keys(profile).length > 0
            ? {
                ...state,
                userInfo: {
                  ...deepCopyObject(state.userInfo),
                  profile: profile
                }
              }
            : state;
        case REQUEST_SUBTYPE.ERROR:
          return state;
        default:
          return state;
      }
    case REQUEST_TYPE.GET_VENDOR:
      switch (action.subType) {
        case REQUEST_SUBTYPE.REQUEST:
          return state;
        case REQUEST_SUBTYPE.SUCCESS:
          const results = getValueFromObjectByKeys(action, ['data', 'data', 'results'], []);
          return results && results.length
            ? {
                ...state,
                vendor: results[0]
              }
            : state;
        case REQUEST_SUBTYPE.ERROR:
          return state;
        default:
          return state;
      }
    case REQUEST_TYPE.UPDATE_VENDOR:
      switch (action.subType) {
        case REQUEST_SUBTYPE.REQUEST:
          return state;
        case REQUEST_SUBTYPE.SUCCESS:
          const dataUpdateVendor = getValueFromObjectByKeys(action, ['data', 'data'], []);
          return { ...state,
            userInfo: {
              ...deepCopyObject(state.userInfo),
              user: {
                ...deepCopyObject(state.user),
                vendor: dataUpdateVendor
              }
            }, 
            vendor: dataUpdateVendor };
        case REQUEST_SUBTYPE.ERROR:
          return state;
        default:
          return state;
      }
    case REQUEST_TYPE.UPDATE_FINANCIAL:
      switch (action.subType) {
        case REQUEST_SUBTYPE.REQUEST:
          return state;
        case REQUEST_SUBTYPE.SUCCESS:
          myLog('GET_PROFILE=>', action.data);
          const userFinancialInfo = getValueFromObjectByKeys(action, ['data', 'data'], {});
          return userFinancialInfo && Object.keys(userFinancialInfo).length > 0
            ? {
                ...state,
                userInfo: {
                  ...deepCopyObject(state.userInfo),
                  user_financial_info: userFinancialInfo
                }
              }
            : state;
        case REQUEST_SUBTYPE.ERROR:
          return state;
        default:
          return state;
      }
    case NORMAL_TYPE.SAVE_USER_INFO:
      myLog('SAVE_USER_INFO=>', action.data);
      return { ...state, userInfo: action.data };
    case NORMAL_TYPE.SAVE_USER_CACHE:
      myLog('SAVE_USER_CACHE=>', action.data);
      return { ...state, userCache: action.data };
    case NORMAL_TYPE.SAVE_USER_DETAIL_LOCAL:
      myLog('SAVE_USER_DETAIL_LOCAL=>', action.data);
      return { ...state, userDetail: action.data };
    case NORMAL_TYPE.SAVE_NOTIFICATION:
      myLog('SAVE_NOTIFICATION=>', action.data);
      return { ...state, notification: action.data };
    case REQUEST_TYPE.SAVE_BRANDING:
      // const { data: notifications } = action?.data;
      if (action?.data?.data) {
        LocalStorage.setItem('branding', JSON.stringify(action?.data?.data||{}))
      }
      return { ...state, branding: action?.data?.data||{} };
    case REQUEST_TYPE.SAVE_ORDER:
        // const { data: notifications } = action?.data;
        // console.log('action?.data?.data', action, action?.data, action?.type, action?.key)
        return { ...state, order_details: action?.data||{} };
    case REQUEST_TYPE.SAVE_ORDER_BRAND:
      // const { data: notifications } = action?.data;
      // if (action?.data?.data) {
      //   LocalStorage.setItem('order_config', JSON.stringify(action?.data?.data||{}))
      // }
      return { ...state, order_config: action?.data?.data||{} };
    default:
      return state;
  }
};

export default UserReducer;
