import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, Paper, styled } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';


const StyledTable = styled(Table)(({ theme }) => ({
  'borderRadius': '10px',
  'width': '100%',
  'lineHeight': '160%',
  '& th': {
    'textAlign': 'left',
    'fontSize': '14px',
    'color': '#000',
    'fontWeight': 500,
    'padding': '5px 15px',
    'whiteSpace': 'nowrap',
    'borderTop': '1px solid #e4e2e2',
    'borderBottom': '1px solid #e4e2e2',
    'backgroundColor': '#F9FAFB',
  },
  '& td': {
    'fontSize': '14px',
    'color': '#000',
    'padding': '10px 15px',
    'borderColor': '#e4e2e2',
    'fontWeight': 400,
    'whiteSpace': 'break-spaces',
  },
  '& thead': {
    'th': {
      'textTransform': 'uppercase',
      'color': '#6B7280',
      'fontSize': '14px',
      'fontWeight': '500',
      'whiteSpace': 'break-spaces',
    },
    '& .MuiTableSortLabel-root': {
      '&:hover': {
        'color': '#fff'
      },
      '& .MuiTableSortLabel-icon': {
        'opacity': '1'
      }
    }
  }
}));

export default function CustomTable({
  className,
  children,
  config,
  filters,
  setFilters
}) {
  return (
    <TableContainer component={Paper}>
      <StyledTable sx={{ minWidth: '100%' }} className={className} stickyHeader>
        {config && (
          <TableHeading
            config={config}
            setFilters={setFilters}
            filters={filters}
          />
        )}
        {children}
      </StyledTable>
    </TableContainer>
  );
}

function TableHeading({ config, filters, setFilters }) {
  const { columns } = config;

  const handleSortClick = (property) => {
    const isAsc = filters.ordering === '-' + property
    setFilters(
      {
        ...filters,
        ordering: isAsc ? property : '-' + property,
      }
    );
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth, whiteSpace: column.whiteSpace || 'none', fontFamily: 'poppins', height: '50px', ...(column.size?{fontSize: column.size}:{})}}
          >
            {column.label}
            {
              column.sortable && (
                <IconButton color='inherit' onClick={() => handleSortClick(column.id)}><ExpandMoreIcon/></IconButton>
              ) 
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead >
  );
}
