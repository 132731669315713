export const validateOrderInputs = (values, requiredFields, requiredValues) => {
    let errors = {}
    for (const [key, value] of Object.entries(values)) {
      console.log(key, value, value?.length < 1, key in requiredFields, requiredValues.includes(key))
      if (value?.length < 1 && key in requiredFields && requiredValues.includes(key)) {
        errors = {
          ...errors,
          ...{ [key]: `This field is mandatory` }
        }
      }
    }
  
    return errors
}