import React from 'react';
import { StyleSheet } from 'aphrodite';
import { convertPxToRem } from '../../helper';
import { Input } from 'antd';
import TextBase from '../text';
import { Colors } from '../../themes/Colors';
import './index.css';

export const FormInput = ({
  placeholder = 'Placeholder',
  label = '',
  onChange = () => {},
  bg = 'grey',
  inputStyle = {},
  disabled = false,
  value = '',
  error = ''
}) => {
  return (
    <div style={{ position: 'relative', marginBottom: '22px' }}>
      {label?.length ? (
        <TextBase
          className="no-select"
          style={{ textAlign: 'left' }}
          size={convertPxToRem(16)}
          color={Colors.black}>
          {label}
        </TextBase>
      ) : null}

      <Input
        placeholder={placeholder}
        style={{ backgroundColor: '#F8F8F8', height: '50px', marginTop: '8px', ...inputStyle }}
        onChange={onChange}
        value={value}
        disabled={disabled}
        className={bg === 'light' ? 'form-input-white' : 'form-input-grey'}
        status={error?.length ? 'error' : ''}
      />

      {error?.length ? (
        <TextBase fontWeight={400} size={convertPxToRem(14)} color={Colors.red}>
          {error || 'Required'}
        </TextBase>
      ) : null}
    </div>
  );
};
