import { Button, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import LocalStorage from '../../defined/localStorage';
const brand = JSON.parse(LocalStorage.getItem('branding'));


export const PageBox = styled('div')(({ theme }) => ({
  'backgroundColor': '#f9f9f9',
  'height': 'calc(100vh - 100px)',
  'padding': '20px 0',
  'overflowY': 'auto',
  '& .MuiContainer-root': {
    'background': 'white',
    'padding': '10px'
  },
  '& .ordername': {
    'fontSize': '18px',
    'fontWeight': '600',
    'color': '#383938'
  },
  '& .productimg': {
    'backgroundColor': '#E5E7EB',
    'width': '100%',
    'height': '350px',
    'borderRadius': '10px'
  },
  '& .productslideimg': {
    'backgroundColor': '#E5E7EB',
    'width': '100%',
    'height': '90px',
    'borderRadius': '10px'
  },
  '& .addproductimg': {
    'backgroundColor': '#E5E7EB',
    'width': '100%',
    'height': '90px',
    'fontSize': '40px',
    'margin': '10px 0',
    'borderRadius': '10px',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center'
  },
  '& .useravatar': {
    'background': 'transparent',
    'width': '16px',
    '& img': {
      'width': '100%',
      'height': '100%'
    }
  },
  '& .userinfo': {
    'display': 'flex',
    'gap': '20px',
    'alignItems': 'center'
  },
  '& .usercontact': {
    'fontSize': '14px',
    'fontWeight': '400'
  },
  '& .orderdetails': {
    'backgroundColor': '#F3F4F6',
    'width': '100%',
    'padding': '10px',
    'borderRadius': '10px'
  },
  '& .detals': {
    'width': '40%'
  },
  '& .slick-dots': {
    'display': 'flex !important',
    'alignItems': 'center !important',
    'justifyContent': ' space-between !important',
    'width': '100% !important',
    'position': 'initial !important',
    'margin': 'auto'
  },
  '& .slick-slide': {
    '& img': {
      'height': '250px !important'
    }
  },
  '.slick-dots li': {
    'width': '90% !important'
  },
  '& .slick-next': {
    'right': '35px !important'
  },
  '.slick-next:before, .slick-prev:before': {
    'color': 'black'
  },
  '& .slick-prev, .slick-next': {
    'top': '36% !important'
  },
  '& .slick-prev': {
    'left': '35px !important'
  },

  '& .slick-dots li': {
    'height': 'auto'
  },
  '& .slick-dots li:first-child': {
    'textAlign': '-webkit-right !important'
  },
  '& .messages_box': {
    'backgroundColor': '#F3F4F6',
    'borderRadius': '10px',
    'padding': '10px 10px',
    'margin': '10px 0',

    '& .chat_box': {
      'maxHeight': '300px',
      'overflowY': 'auto',
      'margin': '10px 10px',
      '& p': {
        'marginBottom': '10px'
      }
    }
  },
  // '& .details': {
  //     [theme.breakpoints.down('md')]: {
  //         marginTop: '70px'
  //     },
  // },
  '& .details-container': {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }

}));

export const StartButton = styled(Button)(({ theme }) => ({
  'borderRadius': '10px',
  'border': '1px solid #6B7280',
  'padding': '10px 0',
  'width': '100%',
  'textAlign': 'center',
  'fontSize': '16px',
  'fontWeight': '700',
  'color': '#6B7280',
  'textTransform': 'uppercase',
  'cursor': 'pointer'
}));

export const SendButton = styled(Button)(({ theme }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return ({
    'borderRadius': '10px',
    'boxShadow': '0px 4px 10px 0px #B9830940',
    'padding': '10px 0',
    'background': whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218',
    'color': '#fff !important',
    'width': '100%',
    'textAlign': 'center',
    'fontSize': '16px',
    'fontWeight': '700',
    'textTransform': 'uppercase',
    'cursor': 'pointer',
    ':hover': {
      'background': whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218',
      'color': 'white'
    }
  });
});

export const ReplyButton = styled(Button)(({ theme }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return ({
    'width': '170px',
    'borderRadius': '5px',
    'boxShadow': '0px 4px 10px 0px #B9830940',
    'padding': '8px 15px',
    'background': whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218',
    'color': 'white',
    'textAlign': 'center',
    'fontSize': '16px',
    'fontWeight': '700',
    'textTransform': 'uppercase',
    'cursor': 'pointer',
    ':hover': {
      'background': whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218',
      'color': 'white'
    }
  });
});

export const FormActionButton = styled(Button)(({ theme }) => {
    const whiteLabel = useSelector((state) => state.User?.branding);
    return ({
        'background': `linear-gradient(222.6deg, ${whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218'} 0%, ${whiteLabel?.web_button_color || brand?.web_button_color || '#F8B218'} 100%)`,
        'borderRadius': '5px',
        'width': '100%',
        'boxShadow': '0px 4px 4px 0px #B9830917',
        'boxShadow': '0px 4px 10px 0px #B9830940',
        'color': 'white !important',
        'padding': '10px 0',
        'textAlign': 'center',
        'color': '#fff !important'
    });
});
