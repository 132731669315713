function HyperLinkTest(props) {
    const {
        label,
        color,
        style={}
    } = props
    let textStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'underline',
        fontWeight: 600,
        fontSize: '14px',
        color: color?color:'#F1991A',
        ...style
    }
    return (
        <a style={textStyle}>{label}</a>
    )
}

export default HyperLinkTest