import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
}

const ordersDetailSlice = createSlice({
    name: 'ordersDetail',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successOrdersDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedOrdersDetailReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successOrdersDetailReducer, failedOrdersDetailReducer, loaderListener } = ordersDetailSlice.actions;

export default ordersDetailSlice.reducer;