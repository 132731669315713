import {$axios, $axiosForm} from "../../../utils/axios.instance"

function getPaymentMethods(payload) {
    return $axios
      .get("stripe/payment-methods/")
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function addPaymentMethod(payload) {
    return $axios
      .post("stripe/payment-methods/", payload)
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function addBusinessAccount(payload) {
    return $axios
      .post("payment-methods/", payload)
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function getBusinessAccounts(payload) {
    return $axios
      .get("payment-methods/")
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function payQuote(payload) {
    let url =
      payload?.type === "card" ? "stripe/send-payments/quote/" : "payment/"
  
    console.log("payload", payload)
    return $axios
      .post(url, payload)
      .then(res => {
        return res.data?.results
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function getPaymentMethod(payload) {
    return $axios
      .get(`stripe/payment-methods/${payload}/`)
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

  function setDefaultPaymentMethod(payload) {
    return $axios
      .post(`stripe/payment-methods/${payload?.id}/set-default/`, payload)
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }
  
  function deletePaymentMethod(payload) {
    return $axios
      .delete(`stripe/payment-methods/${payload}/`)
      .then(res => {
        return res.data
      })
      .catch(function (error) {
        console.log(error)
        throw error
      })
  }

function getBusinessAccount(payload) {
  return $axios
    .get(`payment-methods/${payload}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
}

function deleteBusinessAccount(payload) {
  return $axios
    .delete(`payment-methods/${payload}/`)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
}

function updateBusinessAccount(payload) {
  return $axios
    .patch(`payment-methods/${payload?.id}/`, payload)
    .then(res => {
      return res.data
    })
    .catch(function (error) {
      console.log(error)
      throw error
    })
}

 export const paymentAPIService = {
  getPaymentMethods,
  addPaymentMethod,
  addBusinessAccount,
  getBusinessAccounts,
  payQuote,
  getPaymentMethod,
  setDefaultPaymentMethod,
  deletePaymentMethod,
  getBusinessAccount,
  deleteBusinessAccount,
  updateBusinessAccount
}