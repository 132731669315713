import React from "react";
import { useDropzone } from "react-dropzone";

function BaseLayoutDropzone(props) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept || {},
    onDrop: (files) => {
      props.onDrop(
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <section>
      <div {...getRootProps({ className: props.classNameRoot || "" })}>
        <input {...getInputProps({ className: props.classNameRoot || "" })} />
        {props.children}
      </div>
    </section>
  );
}
export default BaseLayoutDropzone;
