import { successNotesListReducer, loaderListener, failedNotesListReducer } from '../Slice/notesListSlice';
import Utils from '../../../utils';
import { buildQueryString } from '../../../utils/CommonFunction';

export const fetchNotesListApiCall = (values, dispatch) => {

    const {
        pageNo,
        limit,
        searchKey,
        ordering,
    } = values;

    let dataToSend = {}

    if(pageNo) dataToSend.page = pageNo;
    if(limit) dataToSend.page_size = limit;
    if(searchKey) dataToSend.search_query = searchKey;
    if(ordering) dataToSend.sort_order = ordering;

    // Define an array of keys to check for falsy values
    const keysToDelete = ['search_query', 'sort_order'];

    // Iterate through the keys and delete them if they are falsy in dataToSend
    keysToDelete.forEach(key => {
        if (!dataToSend[key]) {
            delete dataToSend[key];
        }
    });

    dispatch(
        loaderListener({
            loading: true
        })
    );

    const result = buildQueryString(dataToSend);
    Utils.api.getApiCall(
        `${Utils.EndPoint.vendorEndpoints.notesListApi}`,
        Object.keys(dataToSend).length == 0 ? '' : `?${result}`,
        (respData) => {
            const { data } = respData;
            dispatch(
                loaderListener({
                    loading: false
                })
            );

            dispatch(
                successNotesListReducer({
                    data: data.data,
                    total: data?.page?.total_records || 0,
                    loading: false
                })
            );
        },
        (error) => {
            Utils.showAlert(2, error.message);
            dispatch(failedNotesListReducer(error.detail));
        }
    );

}

export const createNotesApiCall = (values, orderId, setSubmitting, refetch) => {
    const dataToSend = values;

    Utils.api.postApiCall(
        `${Utils.EndPoint.vendorEndpoints.createNotesApi}${orderId}/`,
        dataToSend,
        (respData) => {
            const { data } = respData
            Utils.showAlert(1, 'Vendor Notes added');
            setSubmitting(false);
            refetch(data);
        },
        (error) => {
            console.log(error);
            const { data } = error;
            Utils.showAlert(2, data.error);
            setSubmitting(false);
        }
    )
}