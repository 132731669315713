import React, { useEffect } from 'react';
import { FormInput } from '../form-input';
import { formatCreditNumber, formatExpiry, translate } from '../../helper';
import { CreditCard } from '../credit-card';

export const CardForm = ({
  setValues,
  values,
  validation = {},
  setValidation = () => {},
  brand = '',
  disabled = false
}) => {
  const onChange = (key, value) => {
    if (key === 'number') {
      if (formatCreditNumber(value).length <= 19) {
        setValues({
          ...values,
          number: formatCreditNumber(value)
        });
      }
    } else if (key === 'expiration_date') {
      setValues({
        ...values,
        expiration_date: formatExpiry(value)
      });
    } else if (key === 'cvc') {
      if (formatCreditNumber(value).length <= 4) {
        setValues({
          ...values,
          cvc: value
        });
      }
    } else {
      setValues({
        ...values,
        [key]: value
      });
    }
  };

  useEffect(() => {
    if (values?.card_holder?.length > 15 && !disabled) {
      setValidation({
        ...validation,
        card_holder: translate('cardHolderNameValidation')
      });
    }
  }, [values?.card_holder]);

  return (
    <div style={{ width: '100%' }}>
      {/* <CreditCard
        values={{
          ...values,
          month_exp: Number(values.expiration_date.split('/')[0]) || '',
          year_exp: values.expiration_date.split('/')[1] || '',
          brand
        }}
      /> */}
      <FormInput
        disabled={disabled}
        value={values?.card_holder}
        onChange={(e) => onChange('card_holder', e.target.value)}
        label={translate('cardHolderName')}
        placeholder={translate('enterCardHolderName')}
        error={validation?.card_holder}
      />
      <FormInput
        disabled={disabled}
        value={values?.number}
        defaultValue={values?.number}
        onChange={(e) => onChange('number', e.target.value)}
        label={translate('cardNumber')}
        placeholder={translate('enterCardNumber')}
        error={validation?.number}
      />
      <FormInput
        disabled={disabled}
        value={values?.expiration_date}
        onChange={(e) => onChange('expiration_date', e.target.value)}
        label={translate('expirationDate')}
        placeholder={translate('enterExpirationDate')}
        error={validation?.expiration_date}
      />
      <FormInput
        disabled={disabled}
        value={values?.cvc}
        onChange={(e) => onChange('cvc', e.target.value)}
        label={translate('cvv')}
        placeholder={translate('enterCvv')}
        error={validation?.cvc}
      />
    </div>
  );
};
