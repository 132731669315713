import { myLog } from '../helper';

export const DEFINE_KEY = {
  ACCESS_TOKEN: 'accessToken',
  TMP_ACCESS_TOKEN: 'TMP_ACCESS_TOKEN',
  user: 'user',
  USER_NAME: 'username',
  MAIN_APP_ID: 'main-app',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  userEmailCache: 'userEmailCache',
  userPasswordCache: 'userPasswordCache',
  ROLE: 'role'
};

const isExist = (key) => {
  let is = window.localStorage.getItem(key);
  if (!is || is === null || is === '' || is === 'undefined') {
    return false;
  }
  return true;
};

const getItem = (key) => {
  return window.localStorage.getItem(key);
};

const setItem = (key, value) => {
  window.localStorage.setItem(key, value);
};

const clear = () => {
  const userEmailCache = localStorage.getItem(LocalStorage.DEFINE_KEY.userEmailCache);
  const userPasswordCache = localStorage.getItem(LocalStorage.DEFINE_KEY.userPasswordCache);
  myLog('clearLocalStorage=>', userEmailCache, userPasswordCache);
  localStorage.clear();
  Object.keys(window.localStorage).map((key) => {
    if (key !== LocalStorage.DEFINE_KEY.DEVICE_ID) {
      LocalStorage.removeItem(key);
    }
  });
  if (userEmailCache && userPasswordCache) {
    localStorage.setItem(LocalStorage.DEFINE_KEY.userEmailCache, userEmailCache);
    localStorage.setItem(LocalStorage.DEFINE_KEY.userPasswordCache, userPasswordCache);
  }
};

const removeItem = (key) => {
  window.localStorage.removeItem(key);
};

const length = typeof window === 'undefined' ? 0 : window.localStorage.length;

const key = (index) => {
  return window.localStorage.key(index);
};

const LocalStorage = {
  setItem,
  getItem,
  clear,
  removeItem,
  key,
  length,
  DEFINE_KEY,
  isExist
};

export default LocalStorage;
