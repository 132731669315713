const userMobileEndpoints = {
  //routes url
  landing: '/user',
  editor: '/user/editor',
  welcome: '/user/welcome',
  signin: '/user/signin',
  signup: '/user/signup',
  forgot_password: '/user/forgot-password',
  reset_password: '/user/reset-password',
  profile: '/user/profile',
  updateProfile: '/user/update',
  changePassword: '/user/change-password',
  terms: '/user/terms',
  privacy: '/user/privacy',
  quotes: '/user/quotes',
  quotes_detail: '/user/quotes/:productId',
  quotes_order: '/user/quotes/:productId/order',
  settings: '/user/settings',
  notification: '/user/notifition',
  notifications: '/user/notifications',
  delete_account: '/user/delete',
  createOrder: '/user/create-order',
  vendors: '/user/vendors',
  paymentMethod: '/user/payment-method',
  message: '/user/message',
  orderDetails: '/user/order/:id',
  editOrder: '/user/edit-order/:id',
  orderQuote: '/user/order-quote/:id',
  addNewCard: '/user/add-new-card',
  editCard: '/user/edit-card/:id',
  addNewBusinessCard: '/user/add-new-business-card',
  editBusinessCard: '/user/edit-business-card/:id',
  userNotification: '/user/user-notification',
  addNewOrder: '/user/add-new-order',
  // Api url,
  loginApi: 'accounts/login/token/',
  signupApi: 'accounts/signup/',
  ordersApi: 'orders/',
  profileApi: 'accounts/user-profile/',
  quotesApi: 'quotes/',
  quotesPaymentCardModeApi: 'stripe/send-payments/quote/',
  quotesPaymentOtherModeApi: 'payment/',
  quotesChatApi: 'chats/firestore/quote-rooms/create/',
  ordersApi: 'orders/',
  getOTP: 'get-otp/',
  validateOTP: 'validate-otp/',
  resetPassAPI: "password-change/",
  deleteAPI: 'delete-user/',
  sendOTPAPI: 'accounts/phone-numbers/send-otp/',
  verifyOTPAPI: 'accounts/phone-numbers/verify-otp/',
  termsAndConditionsAPI: 'terms-and-conditions/',
  privacyPolicyAPI: 'privacy-policy/',
}

const vendorEndpoints = {
  // API URI
  notesListApi: 'vendor_notes/api/vendor_notes/',
  createNotesApi: 'vendor_notes/create-vendor-notes/',
  commentsListApi: 'vendor_notes/add-comments/',
  addCommentApi: 'vendor_notes/add-comments/',
  orderApi: 'orders/',
  quoteApi: 'quotes/',
  chatRoomApi: 'chats/firestore/order-rooms/create/',
  childAccountsListApi: 'child-account-management/child-accounts/',
  createChildAccountsApi: 'child-account-management/child-accounts/create/',
  deleteChildAccountApi: 'child-account-management/child-accounts/delete/',
  verifyChildAccountsApi: 'child-account-management/child-accounts/verify/',
  sendMobileNumberOtp: 'accounts/phone-numbers/send-otp/',
  verifyMobileNumberOtp: 'accounts/phone-numbers/verify-otp/',
  sendEmailOtp: 'get-otp/',
  verifyEmailOtp: 'validate-otp/',
  createWhiteLabelPartnerApi: 'white-label-partner/create/',
  vendorsList: 'vendors/all/',
  teamListApi: 'team-member/',
  inviteTeamMember: 'users/add-team-member/',
  teamDetailApi: 'accounts/team-member-details/',
  deleteTeamMember: 'accounts/profile-delete/',
  updateWarratyStatus: 'warranty-status/'
}

const EndPoint = {
  userMobileEndpoints: { ...userMobileEndpoints },
  vendorEndpoints: { ...vendorEndpoints }
}

export default EndPoint

