import React from 'react';
import { useTranslation } from "react-i18next"
import { convertPxToRem } from '../../helper';
import { Colors } from '../../themes/Colors';
import TextBase from '../text';
import LoadingBase from '../loading';
import { useSelector } from 'react-redux';
import LocalStorage from '../../defined/localStorage';

const brand = JSON.parse(LocalStorage.getItem('branding'))

export default function ButtonGradient(props) {
  const { t } = useTranslation()
  const whiteLabel = useSelector((state) => state.User?.branding);

  const style = props.style || {};
  const iconSize = props.iconSize || 15;
  const iconStyle = props.iconStyle || {};
  const textStyle = props.textStyle || {};
  const Icon = props.icon;
  const opacity = props?.opacity ? props?.opacity : 1;

  const renderBackground = () => {
    console.log("renderBackground")
    const whiteLabelBackground = `linear-gradient(222.6deg, ${whiteLabel?.web_button_color||brand?.web_button_color||'#FBC916'} 0%, ${whiteLabel?.web_button_color||brand?.web_button_color||'#FBC916'} 100%)`;
    const defaultBackground = `linear-gradient(222.6deg, #FBC916 0%, #FBB516 100%)`;
    // console.log("renderBackground => ", brand?.web_button_color, whiteLabel?.web_button_color )
    if (brand||whiteLabel) {
      return whiteLabelBackground;
    } else {
      return defaultBackground;
    }
  };

  return (
    <div
      {...props}
      style={{
        ...style,
        opacity: props.disabled?"0.5":opacity,
        background: props.backgroundColor ? props.backgroundColor : renderBackground()
      }}
      className="button-gradient"
      onClick={() => {
        if (props.loading||props.disabled) return;
        props.onClick && props.onClick();
      }}>
      {props.loading ? (
        <LoadingBase />
      ) : (
        <>
          {Icon ? (
            <Icon
              width={convertPxToRem(iconSize)}
              height={convertPxToRem(iconSize)}
              style={{ marginRight: convertPxToRem(15), ...iconStyle }}
            />
          ) : null}

          <TextBase
            size={convertPxToRem(16)}
            style={{
              ...(props.textStyles||{})
            }}
            color={!textStyle.color?Colors.white:textStyle.color}>
            {t(props.title) || 'Click'}
          </TextBase>
        </>
      )}
    </div>
  );
}
