import { Modal } from 'antd';
import BusinessIcon from '@mui/icons-material/Business';

import enterprise from "../../assets/enterprise.png";
import mail from "../../assets/mail.png";
import call from "../../assets/call.png";
import loc from "../../assets/location.png";
import Button from '../../common/Button';
import { Colors } from '../../../themes/Colors';

function VendorProfile (props) {
    const {
        open,
        data,
        close,
        className,
        onSubmit
    } = props
    const vendor = data?.[0]||{}
    // console.log(vendor, open)
    return (
        <Modal onCancel={close} className={className} visible={open} footer={null}>
            <div style={{height: "300px"}} className='vendor-popup'>
                <div className='vendor-popup-profile'>
                    {
                        vendor?.logo ? <img src={vendor?.logo} />:  <BusinessIcon style={{fontSize: "70px", color: Colors.primary}} />
                    }
                    <div>{vendor?.business_name}</div>
                </div>
                <div className='vendor-popup-desc'>
                    {vendor?.description}
                </div>
                <div className='vendor-popup-info'>
                    <div className='vendor-info-det'>
                        <img src={enterprise}/>
                        <div>{vendor?.full_name}</div>
                    </div>
                    <div className='vendor-info-det'>
                        <img src={mail}/>
                        <div>{vendor?.business_email}</div>
                    </div>
                    <div className='vendor-info-det'>
                        <img src={call}/>
                        <div>{vendor?.phone_number?.national_number}</div>
                    </div>
                    <div className='vendor-info-det'>
                        <img src={loc}/>
                        <div>{vendor?.address}</div>
                    </div>
                </div>
                {/* <div className='vendor-popup-subscribe'>
                    <Button onClick={()=>onSubmit(vendor?.id)} style={{background: '#FF2020'}}>DELETE</Button>
                </div> */}
            </div>
        </Modal>
    )
}

export default VendorProfile