import { convertPxToRem } from "../helper"

export const heightOfHeader = convertPxToRem(64)
export const sizeOfBaseButton = {
  width: convertPxToRem(345),
  height: convertPxToRem(50)
}
export const headerRequestKey = "Authorization"
export const FORM_TYPE = {
  selectCustom: "selectCustom",
  listRadioVertical: "listRadioVertical",
  listRadioHorizontal: "listRadioHorizontal",
  inputString: "string",
  inputNumber: "number",
  upload: "upload",
  uploadButton: "uploadButton",
  banner: "banner",
  date: "date",
  dateTime: "dateTime",
  detail: "detail",
  render: "render",
  detailForm: "detailForm",
  dropzone: "dropzone",
  optionGroup: "optionGroup",
  listQuestionImage: {
    key: "listQuestionImage",
    typeAnswer: {
      radio: "radio",
      checkbox: "checkbox",
      input: "input",
      select: "select"
    }
  },
  arrayForm: "arrayForm"
}
export const mapYesNo = [
  { title: "Yes", value: true },
  { title: "No", value: false }
]

export const mapPlanKey = {
  FREE: "FREE",
  VENTURE: "VENTURE",
  BUSINESS: "BUSINESS",
  UNLIMITED: "UNLIMITED"
}

export const unitKeyPlan = {
  month: {
    key: "month",
    title: "month"
  }
}

export const userKey = {
  username: "name",
  firstName: "first_name",
  lastName: "last_name",
  email: "email",
  phone: "phone_number",
  address: "address",
  city: "city",
  state: "state",
  zipCode: "zip_code",
  jobTitle: "job_title",
  receiveByEmail: 'receive_email',
  receiveBySMS: 'receive_sms',
  term: "term",
  tax: "tax_id",
  country: "country",
  description: "description",
  isMarried: "married",
  isChildren: "children",
  goalRetirement: "retirement_goal",
  isRetired: "retired",
  isWrittenFinancialGoals: "written_financial_goals",
  isPlanForGoal: "implemented_plan_for_goal",
  isInventory: "current_inventory_of_major_assets",
  isReviewFinancial: "review_of_existing_finance_arrangements",
  isAcceptToTeamFinancial: "access_to_team_of_financial_professionals",
  isLikeFinancialAnalysis: "comprehensive_analysis",
  isAreaFocus: "several_areas_to_focus_on",
  profile_image: "profile_image",
  business_name: "business_name",
  business_category: "business_category"
}

export const formKeyLayout = {
  left: "left",
  right: "right",
  main: "main"
}

export const sizeBase = {
  inputForm: { width: convertPxToRem(345), height: convertPxToRem(50) },
  paddingInput: { left: convertPxToRem(24), right: convertPxToRem(22) },
  borderRadiusInputForm: convertPxToRem(8),
  button: { small: convertPxToRem(187) },
  boxDataUpload: {
    minWidth: convertPxToRem(815)
  }
}

export const listKeyUser = {
  introduction: "introduction",
  assets: {
    indexStep: 1,
    mainKey: "assets",
    investment: "investment",
    insurance: "insurance",
    realState: "realState"
  },
  liabilities: {
    indexStep: 2,
    mainKey: "liabilities",
    realEState: "realEState",
    creditCard: "creditCard",
    personalNote: "personalNote",
    loans: "loans",
    other: "other"
  },
  incomeExpenses: {
    mainKey: "incomeExpenses",
    payStubs: "payStubs",
    taxReturns: "taxReturns",
    expenses: "expenses"
  },
  riskTolerance: "riskTolerance",
  productBias: "productBias",
  scheduleMeeting: "scheduleMeeting"
}
export const notificationType = {
  sendQuote: "sendQuote",
  sendOrder: "sendOrder",
  acceptedQuote: "acceptedQuote",
  declineQuote: "declineQuote",
  quoteMessage: "quoteMessage",
  respondQuote: "respondQuote"
}
export const notificationTypeMapDisplay = {
  sendQuote: "sendQuote",
  sendOrder: "Open Order",
  acceptedQuote: "Accepted Quotes",
  declineQuote: "Decline Quote",
  quoteMessage: "Quote Message",
  respondQuote: "Respond Quote"
}
export const FONT_KEY = {
  regular: "Poppins",
  bold: "PoppinsBold",
  medium: "PoppinsMedium",
  semibold: "PoppinsSemiBold"
}


export const animate = {
  initial: { opacity: 0, scale: 0.5 },
  animate: { opacity: 1, scale: 1 },
  transition: {
    duration: 0.3,
    delay: 0.2,
    ease: [0, 0.71, 0.2, 1.01]
  }
}


export const states = {

}
