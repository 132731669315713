import React from 'react';
import BasePage from '../base';
import { css, StyleSheet } from 'aphrodite';
import { convertPxToRem, getUserNameShow, getValueFromObjectByKeys, reloadApp } from '../../helper';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { heightOfHeader, sizeOfBaseButton } from '../../utils/defined';
import LoginButton from '../../common/login-button';
import { ReactComponent as BgVideoDashboard } from '../../assets/image/svg/bgVideoDashboard.svg';
import { ReactComponent as IconPlay } from '../../assets/image/svg/iconPlay.svg';
import LocalStorage from '../../defined/localStorage';
import SVGIcon from '../../assets/image/svg';
import { connect } from 'react-redux';
import withNavigate from '../../component/WithNavigate';
import { Col, Row } from 'antd';
import { SVGImageComponentReact } from '../../themes/Images';
import { ROUTER_NAME } from '../../router/routers';

const heightOfBottomBlackAlpha = convertPxToRem(284);

const styles = StyleSheet.create({
  container: {
    // paddingLeft: convertPxToRem(100),
    // backgroundColor: Colors.red,
    textAlign: 'left',
    whiteSpace: 'pre-line',
    // paddingTop: convertPxToRem(50),
    position: 'relative',
    // height: `calc(100vh - ${heightOfHeader})`,
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(70),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    backgroundColor: Colors.content_page,
    paddingBottom: convertPxToRem(82)
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: '100%',
    minWidth: '100vw',
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    // position: "fixed",
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    position: 'absolute'
  },
  bgOverlay: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // height: `calc(100vh - ${heightOfHeader} - ${heightOfBottomBlackAlpha})`,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)',
    zIndex: 1,
    overflow: 'hidden'
  },
  wrapText: {
    position: 'relative',
    zIndex: 4,
    maxWidth: '50%',
    height: '100%',
    minHeight: convertPxToRem(400),
    // paddingBottom: convertPxToRem(99),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  }
});
const menuKey = {
  legal: 'legal',
  policy: 'policy',
  agreement: 'agreement',
  note: 'note',
  profile: 'profile',
  privacy: 'privacy',
  terms: 'terms',
  logout: 'logout'
};
const menuSettings = [
  {
    key: menuKey.profile,
    title: 'My Profile',
    icon: SVGImageComponentReact.SettingProfile,
    desc: 'ligula et curabitur iaculis turpis. Sed a rhoncus, ametultrices odio amet venenatis orci.'
  },
  {
    key: menuKey.privacy,
    title: 'Privacy Policy',
    icon: SVGImageComponentReact.SettingPrivacy,
    desc: 'ligula et curabitur iaculis turpis. Sed a rhoncus, ametultrices odio amet venenatis orci.'
  },
  {
    key: menuKey.terms,
    title: 'Terms and Conditions',
    icon: SVGImageComponentReact.SettingTerms,
    desc: 'ligula et curabitur iaculis turpis. Sed a rhoncus, ametultrices odio amet venenatis orci.'
  },
  {
    key: menuKey.logout,
    title: 'Logout',
    icon: SVGImageComponentReact.SettingLogout,
    desc: 'ligula et curabitur iaculis turpis. Sed a rhoncus, ametultrices odio amet venenatis orci.'
  }
];
class Settings extends BasePage {
  constructor(props) {
    super(props);
    this.title = 'Settings';
    this.state = {
      menuSelected: menuKey.policy
    };
  }
  onClickMenu = (menu) => {
    const { navigate } = this.props;
    switch (menu.key) {
      case menuKey.profile:
        navigate(ROUTER_NAME.createProfile);
        break;
      case menuKey.privacy:
        navigate(ROUTER_NAME.privacy);
        break;
      case menuKey.terms:
        navigate(ROUTER_NAME.term);
        break;
      case menuKey.logout:
        console.log('logogogogogogogo');
        localStorage.clear();
        navigate(ROUTER_NAME.login);
        // reloadApp()
        break;
      default:
        break;
    }
  };
  renderContent() {
    const { userInfo } = this.props;
    const { menuSelected } = this.state;
    return (
      <div className={css(styles.container)}>
        <div className="flex-row-center">
          <TextBase size={convertPxToRem(25)} color={Colors.gray2}>
            Settings
          </TextBase>
        </div>
        <Row
          style={{
            marginTop: convertPxToRem(38),
            backgroundColor: Colors.white,
            paddingTop: convertPxToRem(54),
            paddingBottom: convertPxToRem(94),
            paddingInline: convertPxToRem(32),
            borderRadius: convertPxToRem(3),
            boxShadow: `0 1px 2px 1px rgba(0, 0, 0, 0.05), 0 1px 2px 1px rgba(0, 0, 0, 0.05)`
          }}
          gutter={[25]}>
          {menuSettings.map((itemS) => {
            const Icon = itemS.icon;
            return (
              <Col span={8} xs={24} sm={24} md={24} lg={8}>
                <div
                  style={{
                    backgroundColor: Colors.white,
                    paddingLeft: convertPxToRem(17),
                    paddingRight: convertPxToRem(24),
                    paddingTop: convertPxToRem(16),
                    paddingBottom: convertPxToRem(14),
                    marginBottom: convertPxToRem(20)
                  }}
                  className="item-setting"
                  onClick={() => this.onClickMenu(itemS)}>
                  <Icon />
                  <TextBase
                    color={Colors.gray2}
                    size={convertPxToRem(18)}
                    fontWeight={500}
                    style={{ marginTop: convertPxToRem(16) }}>
                    {itemS.title}
                  </TextBase>
                  <TextBase
                    style={{ marginTop: convertPxToRem(16) }}
                    color={Colors.gray3}
                    size={convertPxToRem(14)}>
                    {itemS.desc}
                  </TextBase>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notification'])
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Settings));
