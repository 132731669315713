import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './index.scss';

export default function index(props) {
    const {
        onClose, 
        onSave,
        imageUrl
    } = props
    return <App onClose={onClose} onSave={onSave} imageUrl={imageUrl} />
}
