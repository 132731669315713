import React from "react"
import { StyleSheet, css } from "aphrodite"
import { useTranslation } from "react-i18next"

export default function Link(props) {
    const { t, i18n } = useTranslation()
    const {
        onClick,
        className,
        style={}
    } = props
    
    return (
        <span className={className} style={style} onClick={onClick}>{t(props.children)}</span>
    )
}