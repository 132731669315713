import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: [],
    total: 0,
}

const quotesSlice = createSlice({
    name: 'quotesList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successQuotesReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
        },
        failedQuotesReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successQuotesReducer, failedQuotesReducer, loaderListener } = quotesSlice.actions;

export default quotesSlice.reducer;