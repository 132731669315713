import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
    error: '',
};
const userDetailSlice = createSlice({
    name: 'userDetail',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successUserDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedUserDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successUserDetailReducer, failedUserDetailReducer, loaderListner } = userDetailSlice.actions;

export default userDetailSlice.reducer;
