// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCx-k_FZXZp_GZgHmx3tAFZJxxp0Z40tXQ",
//   authDomain: "move-d12fd.firebaseapp.com",
//   projectId: "move-d12fd",
//   storageBucket: "move-d12fd.appspot.com",
//   messagingSenderId: "688617980933",
//   appId: "1:688617980933:web:885793636651232e78886c",
//   measurementId: "G-W6Z51XLRMD"
// }

export const firebaseConfig = {
  apiKey: 'AIzaSyCnam8vKbPf9SHGEnfl_TfuK5TeTt-Ifwk',
  authDomain: 'move2orderp2.firebaseapp.com',
  projectId: 'move2orderp2',
  storageBucket: 'move2orderp2.appspot.com',
  messagingSenderId: '788831950489',
  appId: '1:788831950489:web:b78483896d8c10fa6a4a08',
  measurementId: 'G-5KK654V8MF',
  vapidKey:
    'BDKO2yo93v2e5jCDKonWLrz942l0GuyHJRSmmit8d0kJ3X5pdD_jXzj-JdfJ8zk7SI9CgsxOGBBGPjiEyvMJPPQ'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// console.log('app', app);
// const analytics = getAnalytics(app)
export const db = getFirestore(app);
export const auth = getAuth(app);
export const collectionKeys = {
  notifications: 'notifications',
  quoteMessage: 'quote-message'
};

export const messaging = getMessaging(app);
// console.log('messaging', messaging);
// console.log("firebaseConfig.vapidKey ", firebaseConfig.vapidKey)
// export const getOrRegisterServiceWorker = () => {
//   if ('serviceWorker' in navigator) {
//     return window.navigator.serviceWorker
//       .getRegistration('/firebase-push-notification-scope')
//       .then((serviceWorker) => {
//         if (serviceWorker) return serviceWorker;
//         return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
//           scope: '/firebase-push-notification-scope'
//         });
//       });
//   }
//   throw new Error('The browser doesn`t support service worker.');
// };

export const getFirebaseToken = () =>
  getToken(messaging, {
    vapidKey: firebaseConfig.vapidKey
  });

// export const onForegroundMessage = onMessage
export const onForegroundMessage = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
