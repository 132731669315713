import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '@mui/material/Modal';
import ImageEditor from "../../common/ImageCropModal";

import { translate } from '../../helper';
import lock from "../../assets/image/svg/lock_person.svg";
import Switch from '../../common/CustomRadioButton'
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import LocalStorage from '../../defined/localStorage';
import "./style.css";

const brand = JSON.parse(LocalStorage.getItem('branding'))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SubHeader(props) {
    const {
        label,
        button,
        hyperlinkText,
        onClick
    } = props
    const whiteLabel = useSelector((state) => state.User?.branding);
    return (
        <div
            style={{
                display: "inline-grid",
                alignItems: "center",
                gridTemplateColumns: "auto 1fr auto",
                width: "100%"
            }}
        >
            {label&&<span
                style={{
                    fontSize: "18px",
                    width: "fit-content",
                    lineHeight: "27px",
                    fontWeight: "600",
                    padding: "10px 20px 10px 0",
                    // minWidth: "170px"
                }}
            >
                {translate(label)}
            </span>}
            <div style={{ height: "1px", backgroundColor: "rgba(229, 231, 235, 1)"}}/>
            {button&&<button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: `${whiteLabel?.web_button_color||brand?.web_button_color||'rgba(248, 178, 24, 1)'}`,
                    boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#fff",
                    // minWwidth: "180px",
                    textTransform: "uppercase",
                    width: "fit-content",
                }}
                onClick={()=>{onClick&&onClick()}}
            >
                {translate(button)}
            </button>}
            {hyperlinkText&&<button
                style={{
                    borderRadius: "8px",
                    padding: "10px 20px",
                    marginLeft: "20px",
                    border: "none",
                    background: "#fff",
                    // boxShadow: "0px 4px 4px 0px rgba(185, 131, 9, 0.09)",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "rgba(248, 178, 24, 1)",
                    // minWwidth: "180px",
                    textTransform: "capitalize",
                    width: "fit-content",
                }}
            >
                {translate(hyperlinkText)}
            </button>}
        </div>
    )
}

const defaultColor = {r: "0", g: "0", b: "0", a: "1"}

function ThemeDesign({ 
    header, 
    topBanner, 
    sideBanner, 
    background, 
    button, 
    onSubmit,
    sideBannerColor=defaultColor,
    topBannerColor,
    backgroundColor,
    buttonColor,
    logo,
    setSideBannerColor,
    setTopBannerColor,
    setBackgroundColor,
    setButtonColor,
    setLogo,
    onCancel,
    active,
    setActive,
    logoError,
    setLogoError,
    loading,
    send,
    onPreview
}) {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const {user={}} = useSelector((state) => state.User?.userInfo);
    // console.log("user ", user)
    const [error, setError] = useState('');
    const [cancelLoading, setCancelLoading] = useState(false);
    const [clearedNotes, setClearedNotes] = useState('')
    const [open, setOpen] = useState(false);
    const [previewImg, setPreviewImg] = useState();
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [logoImage, setLogoImage] = useState(null);
    const fileInputRef = useRef(null);
    let disable = window.location.host==='move2-llc-move-3295-staging.botics.co'
    const handleCancel = () => {
        setCancelLoading(true);
        setTimeout(()=>{
            setCancelLoading(false);
            onCancel()
        }, 500)
    }

    useState(()=>{
        if (!cancelLoading) {
            setClearedNotes('cleared!!!')
            setTimeout(()=>{
                setClearedNotes('')
            }, 500)
        }
    }, [cancelLoading])

    // console.log("sideBannerColor ", sideBannerColor)
    const {r, g, b, a} = sideBannerColor
    const {r: r1, g: g1, b: b1, a: a1} = topBannerColor
    const {r: r2, g: g2, b: b2, a: a2} = backgroundColor
    const {r: r3, g: g3, b: b3, a: a3} = buttonColor
    
    function complement(r, g, b) {
        const m = 255;
        return `${Math.abs(m - r)}, ${Math.abs(m - g)}, ${Math.abs(m - b)}`;
    }

    const onFileUpload = (e) => {
        setError('');
        setLogoError('');
        const file = e.target.files[0];
       
        const re = /(\.jpg|\.jpeg|\.gif|\.png)$/i;
        if (!re.exec(file.name)) {
            setError("File extension not supported!");
            return;
        }
        setLogoImage(file);
        setIsEditorOpen(true)
        // const reader = new FileReader();
        // reader.onload = function(event) {
        //     const img = new Image();
        //     img.src = event.target.result;
        //     img.onload = function() {
        //         if (img.width === img.height) {
                    
        //         } else {
        //             setError("Image dimensions must be equal!");
        //         }
        //     };
        // };
        // reader.readAsDataURL(file);
    }

    const handlePreviewImg = () => {
        if (!logo) {
            return
        }
        setPreviewImg(logo)
        setTimeout(()=> {
            setOpen(true)
        }, 100)
    }

    const handleClose = () => {
        setOpen(false)
        setPreviewImg()
    }

    // console.log(url)
    function imageUrl(url) {
        // console.log(url)
        if (url?.type) {
            return URL.createObjectURL(url)
        }
        return url
    }

    const handleCloseEditor = () => {
        setIsEditorOpen(false)
        let input = document.getElementById('logo-upload');
        if (input) {
            input.value = ''
        }
        fileInputRef.current.value = '';

        setLogoImage(null)
    }

    useEffect(()=>{
        if (logoImage) {
            setIsEditorOpen(true)
        }
    }, [logoImage])
    // console.log("logo ", logoImage)
    const handleCroppedImage = (img) => {
        let arr = img.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        let file = new File([u8arr], logoImage.name, { type: mime });
        setLogo(file)
        handleCloseEditor()
    }

    return (
        <div style={{width: "100%"}}>
            <SubHeader 
                label="logoCustomization"
                button={header&&"sendSetupLink"}
                onClick={send}
            />
            {header&&user?.role==='White Label Partner Admin'&&<div
                style={{
                    width: "100%",
                    margin: "20px 0",
                    borderRadius: "6px",
                    backgroundColor: "#FBF4E6",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <div>
                    <img 
                        src={lock}
                        style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "6px",
                            marginLeft: "6px"
                        }}
                    />
                    <span
                        style={{
                            fontWeight: "400",
                            color: "#4F4F4F",
                            fontSize: "14px",
                            lineHeight: "21px"
                        }}
                    >
                        {translate('lockBranding')}
                    </span>
                </div>
                <FormControlLabel
                    onChange={({target: {checked}})=>setActive(checked)}
                    control={<Switch checked={active}  sx={{ m: 1 }} />}
                    // label="iOS style"
                />
            </div>}
            <div
                style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <span
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        color: "#3A3C4A",
                        margin: "10px 0"
                    }}
                >
                    {translate('logoSize')}
                </span>
                <div
                    style={{
                        width: "300px",
                        height: "120px",
                        borderRadius: "4px",
                        border: "1px solid #DADADA",
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    {logo&&<img 
                        src={imageUrl(logo)}
                        alt="logo"
                        style={{width: "120px"}}
                        accept="image/png, image/jpg, image/gif"
                    />}
                </div>
                <span
                    style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#6B7280",
                        margin: "10px 0"
                    }}
                >
                    {translate('imageFileSupport')}
                </span>
                {/* <span
                    style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#FF0000",
                        margin: "10px 0"
                    }}
                >
                    {translate('uploadImageSizeWarning')}
                </span> */}
                {(error.length>0||logoError.length>0)&&<p
                    style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#f00",
                        margin: "10px 0"
                    }}
                >
                    {error.length>0?error:logoError}
                </p>}
                <div
                    style={{
                        width: "300px",
                        height: "26px",
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            borderRadius: "4px",
                            background: `${whiteLabel?.web_button_color||brand?.web_button_color||'rgba(248, 178, 24, 1)'}`,
                            border: "1px solid rgba(255, 255, 255, 0.52)",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#fff",
                            textTransform: "capitalize",
                            textAlign: "center",
                            width: "142px",
                            cursor: "pointer",
                            position: "relative"
                        }}
                    >
                        {translate('upload')}
                        <input id="logo-upload" ref={fileInputRef} onChange={onFileUpload} style={{position: "absolute", left: 0, width: "100%", opacity: 0, padding: 0, cursor: "pointer"}} type="file" id="myFile" name="filename"></input>
                    </div>
                    <button
                        style={{
                            borderRadius: "4px",
                            // padding: "10px 20px",
                            background: "#fff",
                            border: "1px solid #6B7280",
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "#404040",
                            textTransform: "capitalize",
                            textAlign: "center",
                            width: "142px",
                            cursor: "pointer"
                        }}
                        onClick={handlePreviewImg}
                    >
                        {translate('preview')}
                    </button>
                </div>
            </div>
            {sideBanner&&<div
                style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <SubHeader 
                    label="colorScheme"
                />
                <span
                    style={{
                        fontWeight: "600",
                        color: "#4F4F4F",
                        fontSize: "14px",
                        lineHeight: "21px"
                    }}
                >
                    {translate('choosePrimaryColorForSideBanner')}
                </span>
                <div
                    style={{
                        margin: "30px 0 10px 0",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            height: "310px",
                            width: "220px"
                        }}
                    >
                        <SketchPicker
                            onChange={(color) => {
                                setSideBannerColor(color.rgb);
                            }}
                            color={sideBannerColor}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%"
                        }}
                    >
                        <div
                            style={{
                                height: "310px",
                                width: "45%",
                                maxWidth: "220px",
                                backgroundColor: `rgba(${r}, ${g}, ${b}, ${a})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "21px",
                                border: `1px solid rgba(${complement(r, g, b)}, 1)`,
                                color:`rgba(${complement(r, g, b)}, 1)`
                            }}
                        >
                            {translate('example')}
                        </div>
                    </div>
                </div>
            </div>}
            {topBanner&&<div
                style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    borderTop: "1px solid rgba(229, 231, 235, 1)",
                    paddingTop: "30px"
                }}
            >
                {/* <SubHeader /> */}
                <span
                    style={{
                        fontWeight: "600",
                        color: "#4F4F4F",
                        fontSize: "14px",
                        lineHeight: "21px"
                    }}
                >
                    {translate('choosePrimaryColorForTopBanner')}
                </span>
                <div
                    style={{
                        margin: "30px 0 10px 0",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            height: "310px",
                            width: "220px"
                        }}
                    >
                        <SketchPicker
                            onChange={(color) => {
                                setTopBannerColor(color.rgb);
                            }}
                            color={topBannerColor}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                height: "60px",
                                maxWidth: "450px",
                                width: "80%",
                                backgroundColor: `rgba(${r1}, ${g1}, ${b1}, ${a1})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "21px",
                                border: `1px solid rgba(${complement(r1, g1, b1)}, 1)`,
                                color:`rgba(${complement(r1, g1, b1)}, 1)`
                            }}
                        >
                            {translate('example')}
                        </div>
                    </div>
                </div>
            </div>}
            {background&&<div
                style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    borderTop: "1px solid rgba(229, 231, 235, 1)",
                    paddingTop: "30px"
                }}
            >
                {/* <SubHeader /> */}
                <span
                    style={{
                        fontWeight: "600",
                        color: "#4F4F4F",
                        fontSize: "14px",
                        lineHeight: "21px"
                    }}
                >
                    {translate('chooseBackgroundColor')}
                </span>
                <div
                    style={{
                        margin: "30px 0 10px 0",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            height: "310px",
                            width: "220px"
                        }}
                    >
                        <SketchPicker
                            onChange={(color) => {
                                setBackgroundColor(color.rgb);
                            }}
                            color={backgroundColor}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                height: "300px",
                                width: "80%",
                                maxWidth: "450px",
                                backgroundColor: `rgba(${r2}, ${g2}, ${b2}, ${a2})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "21px",
                                border: `1px solid rgba(${complement(r2, g2, b2)}, 1)`,
                                color:`rgba(${complement(r2, g2, b2)}, 1)`
                            }}
                        >
                            {translate('example')}
                        </div>
                    </div>
                </div>
            </div>}
            {button&&<div
                style={{
                    margin: "20px 0",
                    display: "flex",
                    flexDirection: "column",
                    borderTop: "1px solid rgba(229, 231, 235, 1)",
                    paddingTop: "30px"
                }}
            >
                {/* <SubHeader /> */}
                <span
                    style={{
                        fontWeight: "600",
                        color: "#4F4F4F",
                        fontSize: "14px",
                        lineHeight: "21px"
                    }}
                >
                    {translate('chooseButtonColor')}
                </span>
                <div
                    style={{
                        margin: "30px 0 10px 0",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            height: "310px",
                            width: "220px"
                        }}
                    >
                        <SketchPicker
                            onChange={(color) => {
                                setButtonColor(color.rgb);
                            }}
                            color={buttonColor}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                height: "60px",
                                maxWidth: "240px",
                                width: "50%",
                                backgroundColor: `rgba(${r3}, ${g3}, ${b3}, ${a3})`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "16px",
                                lineHeight: "21px",
                                border: `1px solid rgba(${complement(r3, g3, b3)}, 1)`,
                                color:`rgba(${complement(r3, g3, b3)}, 1)`,
                                borderRadius: "4px"
                            }}
                        >
                            {translate('example')}
                        </div>
                    </div>
                </div>
            </div>}
            <div
                style={{
                    margin: "100px 0 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                
                <button
                    style={{
                        borderRadius: "8px",
                        background: "#fff",
                        border: "1px solid #6B7280",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#404040",
                        textTransform: "uppercase",
                        textAlign: "center",
                        width: "45%",
                        cursor: "pointer",
                        padding: "15px"
                    }}
                    onClick={handleCancel}
                >
                    {
                        cancelLoading&&<CircularProgress style={{width: '20px', height: '20px'}} />
                    }
                    {!cancelLoading&&clearedNotes.length>0?clearedNotes:translate('reset')}
                </button>
                <button
                    style={{
                        borderRadius: "8px",
                        background: `${whiteLabel?.web_button_color||brand?.web_button_color||'rgba(248, 178, 24, 1)'}`,
                        border: "1px solid rgba(255, 255, 255, 0.52)",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "#fff",
                        textTransform: "uppercase",
                        textAlign: "center",
                        width: "45%",
                        cursor: "pointer",
                        padding: "15px"
                    }}
                    onClick={()=>{
                        onPreview()
                        // onSubmit({
                        //     sideBannerColor,
                        //     topBannerColor,
                        //     backgroundColor,
                        //     buttonColor,
                        //     logo
                        // })
                    }}
                    disabled={disable}
                >
                    {
                        loading&&<CircularProgress style={{width: "20px", height: "20px"}} />
                    }
                    {translate('preview')}
                </button>
            </div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar style={{backgroundColor: "#fff", boxShadow: "none"}} sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={(handleClose)}
                            aria-label="close"
                        >
                            <CloseIcon style={{color: "#000"}} />
                        </IconButton>
                    </Toolbar>
                    {/* <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Sound
                        </Typography> */}
                </AppBar>
                <div
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                <img 
                    src={previewImg&&imageUrl(previewImg)}
                    alt="logo"
                    style={{
                        width: "100%",
                        height: "100%", 
                        objectFit: "contain"
                    }}
                    // style={{height: "120px"}}
                    // accept="image/png, image/jpg, image/gif"
                />
                </div>
            </Dialog>
            <Modal open={isEditorOpen} onClose={handleCloseEditor}>
                <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{width: "60%", minHeight: "400px", position: "relative", background: "#000", padding: "10px"}}>
                        <ImageEditor img={logoImage} setCroppedImage={handleCroppedImage} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ThemeDesign