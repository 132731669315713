import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import reducers, { MainListReducers } from "../reducers";

const middleware = [];

middleware.push(thunk);
const persistConfig = {
  key: "root",
  storage,
  whiteList: ["User"],
  blackList: ["Package", "DataUpload"],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
});

const ConfigStore = () => {
  const persistor = persistStore(store);
  return { store, persistor };
};
export default ConfigStore;
