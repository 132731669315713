import { Button, styled } from '@mui/material';
import LocalStorage from '../../../defined/localStorage';
import { useSelector } from 'react-redux';

const brand = JSON.parse(LocalStorage.getItem('branding'))

export const ContainedButton = styled(Button)(({ theme }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return ({
  'background': `linear-gradient(222.6deg, ${whiteLabel?.mobile_button_color||brand?.mobile_button_color||'#FBC916'} 0%, ${whiteLabel?.mobile_button_color||brand?.mobile_button_color||'#FBB516'} 100%)`,
  'color': '#FFFFFF',
  'textTransform': 'capitalize',
  'padding': '10px 25px',
  'fontSize': '14px',
  'fontWeight': '700',
  'borderRadius': '7px',
  'textTransform': 'uppercase',
  '&:hover': {
    'color': '#FFFFFF',
  },
  '&:disabled': {
    'color': '#FFFFFF',
  }
})});

export const OutlinedButton = styled(Button)(({ theme }) => ({
  'border': '1px solid #F8B218',
  'backgroundColor': '#FFFFFF',
  'textTransform': 'capitalize',
  'padding': '10px 25px',
  'fontSize': '14px',
  'fontWeight': '700',
  'color': '#F8B218',
  'borderRadius': '7px',
  '&:hover': {
    'backgroundColor': '#FFFFFF',
    'color': '#F8B218',
  }
}));

export const WarningButton = styled(ContainedButton)(({ }) => ({
  'backgroundColor': '#F3DC67',
  'color': '#000000',
  '&:hover': {
    'backgroundColor': '#F3DC67',
    'color': '#000000',
  }
}));

export const SuccessButton = styled(ContainedButton)(({ }) => ({
  'backgroundColor': '#02B23E',
  'color': '#FFFFFF',
  '&:hover': {
    'backgroundColor': '#02B23E',
    'color': '#FFFFFF',
  }
}));

export const DangerButton = styled(ContainedButton)(({ }) => ({
  'backgroundColor': '#C50A0A',
  'color': '#FFFFFF',
  '&:hover': {
    'backgroundColor': '#C50A0A',
    'color': '#FFFFFF',
  }
}));