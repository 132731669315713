import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';

import Button from '../../common/Button';
import { translate } from '../../../helper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    p: 4,
    borderRadius: "8px"
};

export default function AddDealerModal(props) {
    const {
        open,
        onClose,
        mode,
        data,
        type,
        context,
        handleSubmit
    } = props

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div
                    style={{
                        width: "100%",
                        textAlign: "center"
                    }}
                >
                    {mode === 'success' ? <CheckCircleIcon
                        style={{
                            fontSize: "60px",
                            color: "#FBB516",
                            textAlign: "center"
                        }}
                    /> : <WarningIcon
                        style={{
                            fontSize: mode === "Warning" ? "100px" : "60px",
                            color: "#FF0000",
                            textAlign: "center"
                        }}
                    />}

                </div>
                <h2
                    style={{
                        textAlign: 'center',
                        fontSize: mode === 'success' ? '24px' : '20px',
                        fontWeight: 600,
                        lineHeight: '30px',
                        color: mode === 'success' ? "#FBB516" : "#FF2020",
                    }}
                >
                    {mode === "Warning" ? translate('deleteOrderConfirmation') : mode === 'success' ? translate('congratulations') : type === "card" ? translate('paymentFailed') : translate('dealerUnavailable')}
                </h2>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '21px',
                    }}
                >
                    {mode === "Warning" ? `${translate('confirmation')}?` : mode === 'success' ? translate('orderPlacedConfirmation') : type === "card" ? context?.length > 0 ? context : translate('unableToProcessPayment') : `${translate('selectContactDealer')}.`}
                </div>
                {mode === 'success' && <table
                    style={{
                        width: "100%",
                        marginTop: "20px"
                    }}
                >
                    <tr>
                        <td style={{ padding: "20px 0" }}>{translate('customerPo')}</td>
                        <td style={{ padding: "20px 0" }}>{data.po}</td>
                    </tr>
                    <tr>
                        <td style={{ padding: "20px 0" }}>{translate('orderName')}</td>
                        <td style={{ padding: "20px 0" }}>{data.order}</td>
                    </tr>
                    {
                        data.created && <tr>
                            <td style={{ padding: "20px 0" }}>{translate('dateCreated')}</td>
                            <td style={{ padding: "20px 0" }}>{data.created}</td>
                        </tr>
                    }
                    {
                        data.dealer && <tr>
                            <td style={{ padding: "20px 0" }}>{translate('dealerName')}</td>
                            <td style={{ padding: "20px 0" }}>{data.dealer}</td>
                        </tr>
                    }
                    {
                        data.amount && <tr>
                            <td style={{ padding: "20px 0" }}>{translate('amount')}</td>
                            <td style={{ padding: "20px 0" }}>USD {data.amount}</td>
                        </tr>
                    }
                    {
                        data.paid && <tr>
                            <td style={{ padding: "20px 0" }}>{translate('paidOn')}</td>
                            <td style={{ padding: "20px 0" }}>{data.paid}</td>
                        </tr>
                    }
                </table>}
                {
                    mode === "Warning" ? <div
                        style={{
                            marginLeft: "-32px",
                            height: "fit-content",
                            borderTop: "1px solid #E5E7EB",
                            width: "calc(100% + 64px)",
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "-32px",

                        }}
                    >
                        <div
                            style={{
                                width: "50%",
                                fontWeight: "600",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRight: "1px solid #E5E7EB",
                                padding: "20px 0",
                            }}
                            onClick={onClose}
                        >
                            {translate('no')}
                        </div>
                        <div
                            style={{
                                width: "50%",
                                color: "#ff0000",
                                fontWeight: "600",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "20px 0",
                            }}
                            onClick={handleSubmit}
                        >
                            {translate('yes')}
                        </div>
                    </div> :
                        <Button
                            onClick={() => {
                                mode !== 'success' ? onClose('err') : onClose()
                            }}
                            style={{ marginTop: "2rem" }}>{type === 'card' && mode !== 'success' ? translate('tryAgain') : translate('ok')}</Button>

                }
            </Box>
        </Modal>
    )
}