import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
}

const quotesDetailSlice = createSlice({
    name: 'quotesDetail',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successQuotesDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedQuotesDetailReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { succesQuotesDetailReducer, failedQuotesDetailReducer, loaderListener } = quotesDetailSlice.actions;

export default quotesDetailSlice.reducer;