import React, {Component} from "react"
import "./index.css"
import withNavigate from "../../component/WithNavigate";

class StrippedLayout extends Component {
    render() {
        const {children} = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
}

export default (withNavigate(StrippedLayout))

