import React, { useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { EmailIcon } from '../../assets/image/svg/email.js';
import phone from '../../../src/assets/image/svg/phone.svg';
import addressLoc from '../../../src/assets/image/svg/location.svg';
import { connect, useSelector } from 'react-redux';
import { updateWarrantyApiCall } from './Action';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../common/Loader';
import moment from 'moment';
import { orderStatusMap, quotesStatus } from '../../defined';
import LocalStorage from '../../defined/localStorage';
import {
  filterQuoteStatus,
  filterQuoteStatusColor,
  getValueFromObjectByKeys,
  handleStatus,
  translate
} from '../../helper';
import ImageSliderComponent from '../../common/image-slider/ImageSlider.component';
import APIManager from '../../connection/ApiManager';
import { signInWithCustomToken } from 'firebase/auth';
import { auth, firebaseConfig } from '../../firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ImageZoom from '../../common/image-zoom';
import './style.css';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StartThreadModal from './StartThreadModal';
import ReplyChatModal from './ReplyChatModal';
import QuotesModal from './QuotesModal';
import { PageBox, ReplyButton, SendButton, StartButton } from './components';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_SERVER, SHOW_MONTH_NAME_FORMAT } from './helper';

firebase.initializeApp(firebaseConfig);

const brand = JSON.parse(LocalStorage.getItem('branding'));

const OrderDetailPage = (props) => {
  const { userInfo: { profile: { user, vendor } = {} } = {} } = props;
  const whiteLabel = useSelector((state) => state.User?.branding);
  const { id } = useParams();
  const navigate = useNavigate();

  const [openStartThread, setOpenStartThread] = React.useState(false);
  const [openSendQuote, setOpenSendQuote] = React.useState(false);
  const [openReply, setOpenReply] = React.useState(false);
  const messagesEnd = React.useRef();
  const location = useLocation();
  const [orderDetail, setOrderDetails] = React.useState({});
  const [quotesData, setQuotesData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [messages, setMessages] = React.useState([]);
  const [messagesLoading, setMessagesLoading] = React.useState(true);
  const [changingWarrantyStatus, setChangingWarrantyStatus] = React.useState(false);
  const [warrantyStatus, setWarrantyStatus] = React.useState({ status: false, name: '' });
  const deliverLabelMap = {
    'instore': translate('acceptedInStorePickup'),
    'delivery': translate('acceptedDelivery')
  };
  const status = filterQuoteStatus(handleStatus(orderDetail));
  const statusColor = filterQuoteStatusColor(handleStatus(orderDetail));


  const scrollToBottom = () => {
    messagesEnd.current?.scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    document.title = 'Order';
  }, []);

  React.useEffect(() => {
    if (location.state?.scroll) {
      scrollToBottom();
    }
  }, [orderDetail]);

  const getData = async () => {
    if (!id) return;
    let unsub = () => null;
    try {
      const resDetail = await APIManager.getInstance().getOrderDetail(id);
      const orderDetail = resDetail?.data;

      setOrderDetails(orderDetail);
      setWarrantyStatus({
        status: orderDetail.warranty || false,
        name: orderDetail?.warranty_user_name
      });

      const vendors = orderDetail?.vendors;
      const vendorId = vendors ? (vendors[0]?.id || vendors[0]) : null;
      const quotes = orderDetail?.quotes || [];
      const quoteItem = quotes.find((x) => x?.vendor?.id === vendorId);

      if (orderDetail?.vendors && orderDetail?.vendors?.length > 0) {
        const res = await APIManager.getInstance().createChatRoom({
          order: orderDetail.id,
          vendor: vendorId
        });
        unsub = await getChats(res?.data);
      }

      setQuotesData({
        id: quoteItem?.id,
        quote: quoteItem?.quote || undefined,
        date: quoteItem?.order_fullfill_date
          ? moment(quoteItem?.order_fullfill_date, DATE_TIME_FORMAT_SERVER).format(DATE_TIME_FORMAT)
          : '',
        desc: quoteItem?.description || '',
        name: quoteItem?.name || '',
        updatedAt: quoteItem?.updated_at
          ? moment(quoteItem?.updated_at, DATE_TIME_FORMAT_SERVER).format(SHOW_MONTH_NAME_FORMAT)
          : ''
      });
      setLoading(false);
      return unsub
    } catch (error) {
      setLoading(false);
    }
  };

  const getChats = async (room) => {
    try {
      setMessagesLoading(true);
      const res1 = await APIManager.getInstance().getFirebaseAuthToken();
      const resAuth = await signInWithCustomToken(auth, res1?.data?.token);

      return firebase
        .firestore()
        .collection(process.env.REACT_APP_CHATS || 'order_vendor_chats')
        .doc(room?.id)
        .collection('messages')
        .onSnapshot((documentSnapshot) => {
          let mess = [];
          documentSnapshot.forEach((doc) => {
            mess.push(doc.data());
          });
          setMessagesLoading(false);
          setMessages(
            mess
              ?.sort(function(a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
              })
              ?.reverse()
          );
          setMessagesLoading(false);
        });
    } catch (error) {
      setMessagesLoading(false);
    }
  };

  React.useEffect(() => {
    let unSub = getData();

    return unSub;
  }, [id]);

  const vendors = orderDetail?.vendors;
  const vendorId = vendors ? (vendors[0]?.id || vendors[0]) : null;
  const quotes = orderDetail?.quotes || [];
  const quoteItem = quotes.find((x) => x?.vendor?.id === vendorId);
  const isSentQuote = quoteItem ? true : false;
  const isVendor = (orderDetail?.vendors || []).find(x => x === vendorId || x === vendor?.id);

  const isOrderFulfillment =
    orderDetail?.status === orderStatusMap.PROCESSING ||
    orderDetail?.status === orderStatusMap.DELIVERED;
  const isQuoteDecline = quoteItem?.status === quotesStatus.DECLINED;

  const handleChangeWarranty = (evt) => {
    updateWarrantyApiCall(
      orderDetail.id, { 'warranty': evt.target.checked }, setChangingWarrantyStatus, (data) => setWarrantyStatus({
        status: data.warranty,
        name: data.warranty_user_name
      })
    );
  };

  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      updateRemoveInprogress(orderDetail?.id);
    }, true);
    return () => {
      if (orderDetail?.id) {
        updateRemoveInprogress(orderDetail?.id);
      }
    };
  }, [location, orderDetail]);

  const updateRemoveInprogress = (id) => {
    console.log('running navigate in progress');
    APIManager.getInstance().updateInprogress({ details_open: 'false' }, id);
  };

  const vinNames = ['vin', 'vin number', 'identification number', 'vin #', 'vin#', 'serial number', 's/n', 'vin no:', 'identification no:', 'serial no:'];

  const copyToClipboard = (inputValue) => {
    const textarea = document.createElement('textarea');
    textarea.value = inputValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };


  return (
    <>
      <PageBox
        style={{ backgroundColor: whiteLabel?.web_background_color || brand?.web_background_color || '#fff' }}>
        <Box marginX={'20px'} marginY={'5px'} fontSize={'1.5rem'} fontWeight={'600'}>
          <IconButton onClick={() => {
            console.log('running go back');
            navigate(-1);
            updateRemoveInprogress(orderDetail?.id);
          }}>
            <ArrowBackIcon style={{ marginRight: '10px', fontSize: '28px' }} />
          </IconButton>
          {translate('orderDetails')}
        </Box>
        {
          !loading && orderDetail ? (
            <Container
              style={{ backgroundColor: whiteLabel?.web_background_color || brand?.web_background_color || '#fff' }}>
              <Grid container spacing={2} className="details-container">
                <Grid item md={5} xs={10}>
                  {
                    orderDetail &&
                    <ImageSliderComponent images={orderDetail.images} showThumbnails />
                  }
                </Grid>
                <Grid item md={7} xs={10} className="details">
                  <Box marginY={'10px'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography di variant="h6" fontSize={'24px'}
                                  fontWeight={'600'}>{translate('orderInformation')}</Typography>
                      <Divider style={{ width: '60%', margin: 'auto' }} />
                    </Box>
                    <Typography className="ordername"
                                marginY={'10px'}>{translate('orderName')} : {orderDetail.name}</Typography>
                    <Box>
                      <Typography className="ordername"
                                  marginBottom={'2px'}>{translate('productDescription')}</Typography>
                      <Typography fontSize={'16px'} fontWeight={'400'}
                                  variant="p">{orderDetail.description}</Typography>
                    </Box>
                  </Box>
                  <Typography className="ordername">{translate('warranty')}</Typography>
                  <Typography fontSize={'16px'} fontWeight={'400'}>
                    {
                      changingWarrantyStatus ? <CircularProgress /> :
                        (
                          <>
                            <Checkbox checked={warrantyStatus.status}
                                      disabled={changingWarrantyStatus} style={{
                              color: warrantyStatus.status ? '#4CE865' : '#000',
                              padding: '5px 15px 5px 0px'
                            }} onChange={handleChangeWarranty} />
                            {warrantyStatus.name ? `${translate('markedBy')} ${warrantyStatus.name}` : translate('notMarked')}
                          </>
                        )
                    }

                  </Typography>
                  <Box marginY={'10px'}>
                    <Typography fontSize={'18px'}
                                fontWeight={'600'}>{translate('userInfo')}</Typography>
                    <Box className="userinfo" marginY={'15px'}>
                      <ImageZoom
                        imageSrc={orderDetail?.user?.profile?.profile_image}
                        altSrc={orderDetail?.user?.profile?.profile_image}
                      >
                        <Avatar
                          sx={{
                            background: 'gray',
                            height: 50,
                            width: 50,
                            borderRadius: '50%'
                          }}
                          variant="rounded"
                          src={orderDetail?.user?.profile?.profile_image}
                        />
                      </ImageZoom>
                      <Typography
                        className="usercontact">{orderDetail?.user?.profile?.full_name}</Typography>
                    </Box>
                    <Box className="userinfo">
                      <Avatar className="useravatar" variant="rounded">
                        <EmailIcon />
                      </Avatar>
                      <Typography
                        className="usercontact">{orderDetail?.user?.profile?.email}</Typography>
                    </Box>
                    <Box className="userinfo">
                      <Avatar className="useravatar" variant="rounded">
                        <img src={phone} />
                      </Avatar>
                      <Typography className="usercontact">
                        {
                          orderDetail?.user?.profile?.phone_number?.country_code &&
                          orderDetail?.user?.profile?.phone_number?.national_number ?
                            `+${orderDetail?.user?.profile?.phone_number?.country_code}${orderDetail?.user?.profile?.phone_number?.national_number}` : 'N/A'
                        }
                      </Typography>
                    </Box>
                    <Box className="userinfo">
                      <Avatar className="useravatar" variant="rounded">
                        <img src={addressLoc} />
                      </Avatar>
                      <Typography className="usercontact">
                        {orderDetail?.user?.profile?.address}, {orderDetail?.user?.profile?.city},{' '}
                        {orderDetail?.user?.profile?.state} {orderDetail?.user?.profile?.zip_code},{' '}
                        {orderDetail?.user?.profile?.country}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="orderdetails">
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals">{translate('customerPo')}:</Typography>
                      <Typography>{orderDetail?.id}</Typography>
                    </Box>
                    <Box marginY={'10px'} className="" display={'flex'}>
                      <Typography className="detals">{translate('quantity')}:</Typography>
                      <Typography>{orderDetail?.quantity || 'N/A'}</Typography>
                    </Box>
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals">{translate('orderType')}:</Typography>
                      <Typography>{'N/A'}</Typography>
                    </Box>
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals"> {translate('address')}:</Typography>
                      <Typography>{orderDetail?.shipping_address || 'N/A'}</Typography>
                    </Box>
                    {
                      orderDetail?.dynamic_attributes && Object.keys(orderDetail?.dynamic_attributes)?.length > 0 && Object.keys(orderDetail?.dynamic_attributes)?.map(e => (
                        <Box marginY={'10px'} display={'flex'}>
                          <Typography className="detals">{e}:</Typography>
                          <Typography>{orderDetail?.dynamic_attributes?.[e] || 'N/A'}</Typography>
                          {vinNames.includes(e.toLocaleLowerCase()) ?
                            <Button
                              onClick={() => copyToClipboard(orderDetail?.dynamic_attributes?.[e])}><FileCopyIcon
                              style={{ color: 'black' }} sx={{ fontSize: 14 }} /></Button>
                            : null
                          }
                        </Box>
                      ))
                    }
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals"
                                  display={'inline'}> {translate('status')}:</Typography>
                      <Typography>
                        <Typography color={statusColor} className="detals"
                                    display={'inline'}> {status}</Typography>
                        {
                          status === 'Paid' && <Typography
                            display={'inline'}>
                            {` [${deliverLabelMap[orderDetail.delivery_method]}]`}
                          </Typography>
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="orderdetails" sx={{ margin: '10px 0' }}>
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals">{translate('lastQuote')}:</Typography>
                      <Typography>{quotesData.quote ? `USD ${quotesData.quote}` : 'N/A'}</Typography>
                    </Box>
                    <Box marginY={'10px'} display={'flex'}>
                      <Typography className="detals">{translate('lastUpdate')}:</Typography>
                      <Typography>{quotesData?.updatedAt || 'N/A'}</Typography>
                    </Box>
                  </Box>
                  <Box className="messages_box" ref={messagesEnd}>
                    <Box display={'flex'} alignItems={'center'} marginTop={'10px'}>
                      <Typography variant="body1"
                                  fontWeight={'600'}>{translate('messages')}</Typography>
                    </Box>
                    {
                      <>
                        {
                          messagesLoading ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50px',
                                margin: 0
                              }}
                            >
                              <div class="msg-loader-container">
                                <div class="msg-loader bouncing-dots"></div>
                              </div>
                            </div>
                          ) : messages?.length > 0 ? (
                            <>
                              <Box className="chat_box">
                                {
                                  messages.map((message, i) => (
                                    <Box key={i} sx={{ display: 'flex' }}>
                                      <Typography variant="body1"
                                                  fontSize={'16px'}
                                                  fontWeight="600">
                                        {
                                          user?.id === message?.message_sent_by_user_id
                                            ? 'Me'
                                            : message?.message_sent_by_user_first_name || 'Dealer'
                                        }
                                      </Typography>
                                      <Typography variant="body1"
                                                  fontSize={'16px'}>
                                        : {message.body}
                                      </Typography>
                                    </Box>
                                  ))
                                }
                              </Box>
                              <Box textAlign={'end'}>
                                <ReplyButton
                                  onClick={() => setOpenReply(true)}>{translate('reply')}</ReplyButton>
                              </Box>
                            </>
                          ) : (
                            <Typography variant="body2"
                                        mt={2}>{translate('noMessage')}</Typography>
                          )
                        }
                      </>
                    }
                  </Box>
                  <Grid container spacing={2} marginY={'20px'}>
                    <Grid item xs={6}>
                      {
                        !messagesLoading && (messages?.length == 0) && (
                          <StartButton onClick={() => setOpenStartThread(true)}>
                            {translate('startThread')}
                          </StartButton>
                        )
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <SendButton disabled={!isVendor} onClick={() => setOpenSendQuote(true)}>
                        {
                          isQuoteDecline
                            ? translate('declinedStatus')
                            : isOrderFulfillment
                              ? translate('orderFulfillmentStatus')
                              : isSentQuote
                                ? translate('editQuoteStatus')
                                : translate('sendQuoteStatus')
                        }
                      </SendButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          ) : (
            loading ? (
              <Loader />
            ) : (
              <div>{translate('noDetailFound')}</div>
            )
          )
        }
      </PageBox>
      {
        openStartThread && (
          <StartThreadModal
            isOpen={openStartThread}
            onClose={() => {
              setOpenStartThread(false);
            }}
            orderDetail={orderDetail}
            user={user}
            vendorId={vendorId}
          />
        )
      }
      {
        openSendQuote && (
          <QuotesModal
            isOpen={openSendQuote}
            onClose={() => {
              setOpenSendQuote(false);
            }}
            onClickClose={() => {
              setOpenSendQuote(false);
            }}
            orderDetail={orderDetail}
            refetch={
              () => {
                setOpenSendQuote(false);
                getData();
              }
            }
            user={user}
            vendorId={vendorId}
          />
        )
      }
      {
        openReply && (
          <ReplyChatModal
            isOpen={openReply}
            onClose={() => setOpenReply(false)}
            orderDetail={orderDetail}
            user={user}
            vendorId={vendorId}
          />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor'])
  };
};
export default connect(mapStateToProps)(OrderDetailPage);