import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import { makeStyles } from '@mui/styles';
// import PdfReader from '../PdfReader';
import { saveAs } from 'file-saver';
import getCursor from './cursors';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import brush from "../../../../../../assets/image/svg/paint-brush-svgrepo-com.svg";
// import eraser from "../../../../../../assets/image/svg/eraser-svgrepo-com.svg";
import deleteIcon from "../../../../../../assets/image/svg/delete-2-svgrepo-com.svg";
import cursor from "../../../../../../assets/image/svg/cursor-default-svgrepo-com.svg";
import textIcon from "../../../../../../assets/image/svg/text-size-svgrepo-com.svg";

import './eraserBrush';

import styles from './index.module.scss';



let drawInstance = null;
let origX;
let origY;
let mouseDown = false;

const options = {
  currentMode: '',
  currentColor: '#000000',
  currentWidth: 5,
  fill: false,
  group: {},
};

const modes = {
  RECTANGLE: 'RECTANGLE',
  TRIANGLE: 'TRIANGLE',
  ELLIPSE: 'ELLIPSE',
  LINE: 'LINE',
  PENCIL: 'PENCIL',
  ERASER: 'ERASER',
};


const initCanvas = (width, height) => {
  // console.log(width, height)
  const canvas = new fabric.Canvas('canvas', { height, width });
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerStyle = 'circle';
  fabric.Object.prototype.borderColor = '#4447A9';
  fabric.Object.prototype.cornerColor = '#4447A9';
  fabric.Object.prototype.cornerSize = 6;
  fabric.Object.prototype.padding = 10;
  fabric.Object.prototype.borderDashArray = [5, 5];

  canvas.on('object:added', (e) => {
    // console.log('object:added ', e.target)
    e.target.on('mousedown', removeObject(canvas));
  });
  canvas.on('path:created', (e) => {
    // console.log('path:created ', e.path)
    e.path.on('mousedown', removeObject(canvas));
  });

  return canvas;
};

function removeObject(canvas) {
  // console.log("removeObject ", options.currentMode)
  return (e) => {
    if (options.currentMode === modes.ERASER) {
      canvas.remove(e.target);
    }
  };
}

function stopDrawing() {
  mouseDown = false;
}

function removeCanvasListener(canvas) {
  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
}

/*  ==== line  ==== */
function createLine(canvas) {
  if (modes.currentMode !== modes.LINE) {
    options.currentMode = modes.LINE;

    removeCanvasListener(canvas);
    canvas.on('mouse:down', startAddLine(canvas));
    canvas.on('mouse:move', startDrawingLine(canvas));
    canvas.on('mouse:up', stopDrawing);

    canvas.selection = false;
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }
}

function startAddLine(canvas) {
  return ({ e }) => {
    mouseDown = true;

    let pointer = canvas.getPointer(e);
    drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: options.currentWidth,
      stroke: options.currentColor,
      selectable: false,
    });

    canvas.add(drawInstance);
    canvas.requestRenderAll();
  };
}

function startDrawingLine(canvas) {
  return ({ e }) => {
    if (mouseDown) {
      const pointer = canvas.getPointer(e);
      drawInstance.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      drawInstance.setCoords();
      canvas.requestRenderAll();
    }
  };
}

/* ==== rectangle ==== */
function createRect(canvas) {
  if (options.currentMode !== modes.RECTANGLE) {
    options.currentMode = modes.RECTANGLE;

    removeCanvasListener(canvas);

    canvas.on('mouse:down', startAddRect(canvas));
    canvas.on('mouse:move', startDrawingRect(canvas));
    canvas.on('mouse:up', stopDrawing);

    canvas.selection = false;
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }
}

function startAddRect(canvas) {
  return ({ e }) => {
    mouseDown = true;

    const pointer = canvas.getPointer(e);
    origX = pointer.x;
    origY = pointer.y;

    drawInstance = new fabric.Rect({
      stroke: options.currentColor,
      strokeWidth: options.currentWidth,
      fill: options.fill ? options.currentColor : 'transparent',
      left: origX,
      top: origY,
      width: 0,
      height: 0,
      selectable: false,
    });

    canvas.add(drawInstance);

    drawInstance.on('mousedown', (e) => {
      if (options.currentMode === modes.ERASER) {
        canvas.remove(e.target);
      }
    });
  };
}

function startDrawingRect(canvas) {
  return ({ e }) => {
    if (mouseDown) {
      const pointer = canvas.getPointer(e);

      if (pointer.x < origX) {
        drawInstance.set('left', pointer.x);
      }
      if (pointer.y < origY) {
        drawInstance.set('top', pointer.y);
      }
      drawInstance.set({
        width: Math.abs(pointer.x - origX),
        height: Math.abs(pointer.y - origY),
      });
      drawInstance.setCoords();
      canvas.renderAll();
    }
  };
}

/* ==== Ellipse ==== */
function createEllipse(canvas) {
  if (options.currentMode !== modes.ELLIPSE) {
    options.currentMode = modes.ELLIPSE;

    removeCanvasListener(canvas);

    canvas.on('mouse:down', startAddEllipse(canvas));
    canvas.on('mouse:move', startDrawingEllipse(canvas));
    canvas.on('mouse:up', stopDrawing);

    canvas.selection = false;
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }
}

function startAddEllipse(canvas) {
  return ({ e }) => {
    mouseDown = true;

    const pointer = canvas.getPointer(e);
    origX = pointer.x;
    origY = pointer.y;
    drawInstance = new fabric.Ellipse({
      stroke: options.currentColor,
      strokeWidth: options.currentWidth,
      fill: options.fill ? options.currentColor : 'transparent',
      left: origX,
      top: origY,
      cornerSize: 7,
      objectCaching: false,
      selectable: false,
    });

    canvas.add(drawInstance);
  };
}

function startDrawingEllipse(canvas) {
  return ({ e }) => {
    if (mouseDown) {
      const pointer = canvas.getPointer(e);
      if (pointer.x < origX) {
        drawInstance.set('left', pointer.x);
      }
      if (pointer.y < origY) {
        drawInstance.set('top', pointer.y);
      }
      drawInstance.set({
        rx: Math.abs(pointer.x - origX) / 2,
        ry: Math.abs(pointer.y - origY) / 2,
      });
      drawInstance.setCoords();
      canvas.renderAll();
    }
  };
}

/* === triangle === */
function createTriangle(canvas) {
  removeCanvasListener(canvas);

  canvas.on('mouse:down', startAddTriangle(canvas));
  canvas.on('mouse:move', startDrawingTriangle(canvas));
  canvas.on('mouse:up', stopDrawing);

  canvas.selection = false;
  canvas.hoverCursor = 'auto';
  canvas.isDrawingMode = false;
  canvas.getObjects().map((item) => item.set({ selectable: false }));
  canvas.discardActiveObject().requestRenderAll();
}

function startAddTriangle(canvas) {
  return ({ e }) => {
    mouseDown = true;
    options.currentMode = modes.TRIANGLE;

    const pointer = canvas.getPointer(e);
    origX = pointer.x;
    origY = pointer.y;
    drawInstance = new fabric.Triangle({
      stroke: options.currentColor,
      strokeWidth: options.currentWidth,
      fill: options.fill ? options.currentColor : 'transparent',
      left: origX,
      top: origY,
      width: 0,
      height: 0,
      selectable: false,
    });

    canvas.add(drawInstance);
  };
}

function startDrawingTriangle(canvas) {
  return ({ e }) => {
    if (mouseDown) {
      const pointer = canvas.getPointer(e);
      if (pointer.x < origX) {
        drawInstance.set('left', pointer.x);
      }
      if (pointer.y < origY) {
        drawInstance.set('top', pointer.y);
      }
      drawInstance.set({
        width: Math.abs(pointer.x - origX),
        height: Math.abs(pointer.y - origY),
      });

      drawInstance.setCoords();
      canvas.renderAll();
    }
  };
}



function changeToErasingMode(canvas) {
  if (options.currentMode !== modes.ERASER) {
    removeCanvasListener(canvas);

    canvas.isDrawingMode = false;

    options.currentMode = modes.ERASER;
    // e.target.on('mousedown', removeObject(canvas));
    canvas.hoverCursor = `url(${getCursor({ type: 'eraser' })}), default`;
  }
}

function onSelectMode(canvas) {
  options.currentMode = '';
  canvas.isDrawingMode = false;

  removeCanvasListener(canvas);
  options.currentMode = ''
  canvas.getObjects().map((item) => item.set({ selectable: true }));
  canvas.hoverCursor = 'all-scroll';
}

function clearCanvas(canvas) {
  canvas.getObjects().forEach((item) => {
    if (item !== canvas.backgroundImage) {
      canvas.remove(item);
    }
  });
}
function canvasToJson(canvas) {
  alert(JSON.stringify(canvas.toJSON()));
}

function draw(canvas) {
  if (options.currentMode !== modes.PENCIL) {
    removeCanvasListener(canvas);

    options.currentMode = modes.PENCIL;
    // canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.width = parseInt(options.currentWidth, 10) || 1;
    canvas.isDrawingMode = true;
  }
}

function handleResize(callback) {
  const resize_ob = new ResizeObserver(callback);

  return resize_ob;
}

function resizeCanvas(canvas, whiteboard) {
  return () => {
    const ratio = canvas.getWidth() / canvas.getHeight();
    const whiteboardWidth = whiteboard.clientWidth;
    // console.log("ratio ", ratio, whiteboardWidth)
    const scale = whiteboardWidth / canvas.getWidth();
    const zoom = canvas.getZoom() * scale;
    // canvas.setDimensions({ width: whiteboardWidth, height: whiteboardWidth / ratio });
    canvas.setDimensions({ width: whiteboardWidth, height: whiteboardWidth * 1.4 });
    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  };
}

function Tool({url, label, onClick, active}) {
  return (
      <div
          style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "12px",
              alignItems: "center",
          }}
      >
          <div
              style={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  backgroundColor: active?"#dcfff2":"#fff",
                  padding: "8px",
                  borderRadius: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "5px"
              }}
              onClick={onClick}
          >
              <img 
                  src={url}
                  style={{
                      width: '25px' 
                  }}
              />
          </div>
          {/* <span
              style={{
                  color: "#fff",
                  fontSize: "12px"
              }}
          >
              {label}
          </span> */}
      </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    width: "100vw",
  //   display: "grid",
    backgroundColor: "#fff",
    overflow: "hidden"
  },

  content: {
    height: "75vh",
    gridArea: "Content",
    backgroundColor: "beige",
    transition: "height 0.1s ease-out 0.1s"
  },

  contentExpand: {
    height: "100vh"
  },

  contentToHide: {
    height: "25vh",
    gridArea: "ContentToHide",
    backgroundColor: "gray"
  },

  contentToHideHidden: {
    overflow: "hidden"
  }
}));

const Whiteboard = React.forwardRef(({ aspectRatio = 4 / 3, onClose, onSave, imageUrl }, ref) => {
  const [canvas, setCanvas] = useState(null);
  const [brushWidth, setBrushWidth] = useState(5);
  const [isFill, setIsFill] = useState(false);
  const [selectedTool, setSelectedTool] = useState('')
  const [fileReaderInfo, setFileReaderInfo] = useState({
    file: '',
    totalPages: null,
    currentPageNumber: 1,
    currentPage: '',
  });
  const [toggled, setToggled] = useState(false);
  const [textContent, setTextContent] = useState('')
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isCropping, setIsCropping] = useState(false);
  const [color, setColor] = useState('#000') 
  const classes = useStyles();
  const canvasRef = useRef(null);
  const whiteboardRef = useRef(null);
  const uploadImageRef = useRef(null);
  const containerRef = useRef(null); 

  const tools = [
    {
        name: 'shape',
        label: 'Shape',
        url: brush
    },
    // {
    //     name: 'eraser',
    //     label: 'Eraser',
    //     url: eraser
    // },
    {
      name: 'selection',
      label: 'select',
      url: cursor
    },
    // {
    //     name: 'filter',
    //     label: 'Filter',
    //     url: filter
    // },
    // {
    //     name: 'sticker',
    //     label: 'Sticker',
    //     url: sticker
    // },
    {
        name: 'text',
        label: 'Text',
        url: textIcon
    },
    {
        name: 'delete',
        label: 'Clear',
        url: deleteIcon
    }
  ]

  useImperativeHandle(ref, () => ({
    saveImage() {
      saveImage()
      options.currentMode = ''
      options.currentColor = '#000000'
      options.currentWidth = 5
      options.fill = false
      options.group = {}
    }
  }))

  function createText(canvas) {
    // console.log("createText ")
    removeCanvasListener(canvas);
    onSelectMode(canvas)
    canvas.isDrawingMode = false;
  
    const text = new fabric.IText(textContent, {
      left: 100,
      top: 100,
      fill: options.currentColor,
      editable: true,
    });
  //   console.log("text ", text)
  //   canvas.on('after:render', function(e) {
  //     console.log('text:changed', e.target, e);
  // });
    canvas.add(text);
    canvas.renderAll();
    setTextContent('')
  }

  const selectTool = (tool) => {
    setSelectedTool(tool)
    if (tool.name === 'shape') {
      draw(canvas)
    }
    if (tool?.name!=='eraser') {
      setToggled(true)
    }
    if (tool?.name=='eraser') {
      changeToErasingMode(canvas)
    }
    if (tool?.name==='text') {
      // createText(canvas)
      // const text = new fabric.Textbox('text', {
      //   left: 100,
      //   top: 100,
      //   fill: options.currentColor,
      //   editable: true,
      // });
      // canvas.add(text);
    }
    if (tool?.name==='selection') {
      onSelectMode(canvas)
    }
    if (tool?.name==='delete') {
      clearCanvas(canvas)
    }
  }

  useEffect(() => {
    if (!canvas && canvasRef.current) {
      const canvas = initCanvas(
        whiteboardRef.current.clientWidth,
        whiteboardRef.current.clientWidth,
      );
      setCanvas(() => canvas);

      // canvas.on('after:render', () => {
      //   setZoomLevel(canvas.getZoom());
      //   updateContainerSize(canvas);
      // });

      handleResize(resizeCanvas(canvas, whiteboardRef.current)).observe(whiteboardRef.current);
    }
  }, [canvas, canvasRef]);

  const updateContainerSize = (canvas) => {
    // const container = containerRef.current;
    // const canvasWidth = canvas.getWidth() * zoomLevel;
    // const canvasHeight = canvas.getHeight() * zoomLevel;

    // container.style.width = `${canvasWidth}px`;
    // container.style.height = `${canvasHeight}px`;
    // container.style.overflow = 'auto';
  };

  useEffect(() => {
    if (canvas) {
      const center = canvas.getCenter();
      fabric.Image.fromURL(fileReaderInfo.currentPage, (img) => {
        img.scaleToHeight(canvas.height);
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          top: center.top,
          left: center.left,
          originX: 'center',
          originY: 'center',
        });

        canvas.renderAll();
      });
    }
  }, [fileReaderInfo.currentPage]);

  function uploadImage(imgFile) {
    const reader = new FileReader();
    // console.log("imgFile ", imgFile, canvas.height)
    // const file = e.target.files[0];
    const center = canvas.getCenter();
    reader.addEventListener('load', () => {
      fabric.Image.fromURL(reader.result, (img) => {
        img.scaleToHeight(canvas.height);
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          top: center.top,
          left: center.left,
          originX: 'center',
          originY: 'center',
        });
        canvas.renderAll();
        // canvas.add(img);
      });
    });

    reader.readAsDataURL(imgFile);
  }

  useEffect(()=>{
    if (imageUrl&&canvas) {
      uploadImage(imageUrl)
    }
  }, [imageUrl, canvas])

  function changeCurrentWidth(e) {
    const intValue = parseInt(e.target.value);
    options.currentWidth = intValue;
    canvas.freeDrawingBrush.width = intValue;
    setBrushWidth(() => intValue);
  }

  function changeCurrentColor(e) {
    options.currentColor = e.target.value;
    canvas.freeDrawingBrush.color = e.target.value;
  }

  function changeColor(color) {
    setColor(color)
    options.currentColor = color;
    canvas.freeDrawingBrush.color = color;
  }

  function changeFill(e) {
    options.fill = e.target.checked;
    setIsFill(() => e.target.checked);
  }

  function onSaveCanvasAsImage() {
    canvasRef.current.toBlob(function (blob) {
      saveAs(blob, 'image.png');
    });
  }

  const saveImage = () => {
    const dataUrl = canvasRef.current.toDataURL({
        format: 'png',
        quality: 1,
    });

    onSave(dataUrl);
    // setSavedImages([...savedImages, dataUrl]);
    onClose()
  };

  function onFileChange(event) {
    updateFileReaderInfo({ file: event.target.files[0], currentPageNumber: 1 });
  }

  function updateFileReaderInfo(data) {
    setFileReaderInfo({ ...fileReaderInfo, ...data });
  }

  function zoomIn() {
    const center = canvas.getCenter();
    const point = { x: center.left, y: center.top };
    canvas.zoomToPoint(point, canvas.getZoom() * 1.2);
    // canvas.setZoom(canvas.getZoom() * 1.1); // Increase zoom level by 10%
    canvas.requestRenderAll();
  }

// Function to handle zoom out
  function zoomOut() {
    const center = canvas.getCenter();
    const point = { x: center.left, y: center.top };
    canvas.zoomToPoint(point, canvas.getZoom() / 1.2);
    // canvas.setZoom(canvas.getZoom() / 1.1); // Decrease zoom level by 10%
    canvas.requestRenderAll();
  }

  function adjustImageSize(scaleFactor) {
    // canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
    //     scaleX: scaleFactor,
    //     scaleY: scaleFactor,
    //     // top: center.top,
    //     // left: center.left,
    //     originX: 'center',
    //     originY: 'center',
    // });
  }

  const startCrop = () => {
    setIsCropping(true);
    let isCropping = true
    const cropRect = new fabric.Rect({
      fill: 'transparent',
      stroke: 'red',
      strokeWidth: 2,
      selectable: false,
      evented: false,
    });
    canvas.add(cropRect);
    const mouseDownListener = (event) => {
      const pointer = canvas.getPointer(event.e);
      cropRect.set({ left: pointer.x, top: pointer.y });
      canvas.renderAll();
    };
    const mouseMoveListener = (event) => {
      if (!isCropping) return;
      const pointer = canvas.getPointer(event.e);
      cropRect.set({ width: pointer.x - cropRect.left, height: pointer.y - cropRect.top });
      canvas.renderAll();
    };
    const mouseUpListener = () => {
      setIsCropping(false);
      isCropping = false
      canvas.off('mouse:down', mouseDownListener);
      canvas.off('mouse:move', mouseMoveListener);
      canvas.off('mouse:up', mouseUpListener);
      const croppedImage = cropImage(canvas, cropRect);
      console.log("croppedImage ", cropRect)
      canvas.clear();
      canvas.setBackgroundImage(croppedImage, canvas.renderAll.bind(canvas));
    };
    canvas.on('mouse:down', mouseDownListener);
    canvas.on('mouse:move', mouseMoveListener);
    canvas.on('mouse:up', mouseUpListener);
  };

  const cropImage = (canvas, cropRect) => {
    const croppedImage = new fabric.Image(canvas.toDataURL({
      left: cropRect.left,
      top: cropRect.top,
      width: cropRect.width,
      height: cropRect.height,
      format: 'png',
    }));
    return croppedImage;
  };

  const adjustView = (direction) => {
    const step = 20; // Adjust this value as needed
    const delta = step * zoomLevel;
    const viewportTransform = canvas.viewportTransform.slice();

    switch (direction) {
      case 'up':
        viewportTransform[5] += delta;
        break;
      case 'down':
        viewportTransform[5] -= delta;
        break;
      case 'left':
        viewportTransform[4] += delta;
        break;
      case 'right':
        viewportTransform[4] -= delta;
        break;
      default:
        break;
    }

    canvas.setViewportTransform(viewportTransform);
  };

  return (
    <div ref={whiteboardRef} className={styles.whiteboard}>
      <div style={{height: 'auto'}} ref={containerRef}>
        <canvas style={{border: "none"}} ref={canvasRef} id="canvas" />
      </div>
      <div 
        style={{
          padding: "10px 0 10px 10px",
          width: "100%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            marginBottom: "15px",
            alignItems: "center"
          }}
        >
          { selectedTool.name==="shape"?
            ['#FFF', '#000', '#FF0000', '#FFA500', '#008000', '#87CEEB', '#7F00FF'].map(clr=>(
              <div 
                style={{
                  display: "flex",
                  width: "30px",
                  height: color===clr? "35px":"30px",
                  minWidth: color===clr? "35px":"30px",
                  backgroundColor: clr,
                  borderRadius: "50%",
                  border: "1px solid #fff"
                }}
                onClick={()=>changeColor(clr)}
              />
            )) : <div style={{height: "35px"}} />
          }
        </div>
        <div style={{display: "flex"}}>
          <div style={{width: "75%"}}>
            <div style={{display: "flex"}}>
              <button 
                style={{
                  color: "#000", 
                  backgroundColor: "#fff",
                  padding: "5px",
                  width: "40px",
                  height: "25px",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  display: "flex",
                  justifyContent: "center"
                }} 
                onClick={zoomOut}
              >
                <ZoomOutIcon style={{fontSize: "20px"}} />
              </button>
              <button 
                style={{
                  color: "#000", 
                  backgroundColor: "#fff",
                  padding: "5px",
                  width: "40px",
                  height: "25px",
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                  display: "flex",
                  justifyContent: "center"
                }} 
                onClick={zoomIn}
              >
                <ZoomInIcon style={{fontSize: "20px"}} />
              </button>
            </div>
            <div
              style={{
                marginTop: "10px",
                width: "100%",
                display: "flex"
              }}
            >
              {
                tools.map(tool=>(
                  <Tool 
                    onClick={()=>selectTool(tool)}
                    url={tool.url}
                    label={tool.label}
                    active={tool.name===selectedTool.name}
                  />
                ))
              }
            </div>
          </div>
          <div style={{display: "flex", flexDirection: "column", transform: "rotate(45deg)", marginTop: "15px"}}>
            <div style={{display: "flex"}}>
              <button onClick={() => adjustView('up')} style={{backgroundColor: "#fff", color: "#000", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px", borderTopLeftRadius: "10px"}}>
                <ArrowUpwardIcon style={{fontSize: "20px", transform: "rotate(-45deg)"}} />
              </button>
              <button onClick={() => adjustView('right')} style={{backgroundColor: "#fff", color: "#000", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px", borderTopRightRadius: "10px"}}>
                <ArrowUpwardIcon style={{fontSize: "20px", transform: "rotate(45deg)"}} />
              </button>
            </div>
            <div style={{display: "flex"}}>
              <button onClick={() => adjustView('left')} style={{backgroundColor: "#fff", color: "#000", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px", borderBottomLeftRadius: "10px"}}>
                <ArrowUpwardIcon style={{fontSize: "20px", transform: "rotate(-135deg)"}} />
              </button>
              <button onClick={() => adjustView('down')} style={{backgroundColor: "#fff", color: "#000", display: "flex", justifyContent: "center", alignItems: "center", width: "40px", height: "40px", borderBottomRightRadius: "10px"}}>
                <ArrowUpwardIcon style={{fontSize: "20px", transform: "rotate(135deg)"}} />
              </button>
            </div>
          </div>
        </div>
        
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%"
          }}
        >
          <button  
            onClick={saveImage}
            style={{
              backgroundColor: "orande",
              border: "none",
              borderRadius: "3px",
              fontWeight: 600,
              padding: "3px 10px"
            }}
          >
              Done
          </button>
        </div> */}
      </div>
      <div 
          style={{
              position: "absolute",
              bottom: toggled?0:"-0vh",
              height: toggled? 
                  // selectedTool?.name === 'shape' ? "35vh": 
                  selectedTool?.name === "text" ? "100vh":
                  0:0,
              width: "95vw",
              transition: "0.5s",
              padding: "20px",
              backgroundColor: selectedTool?.name === "text" ? "rgba(0, 0, 0, 0.8)": "#fff",
              display: toggled&&(selectedTool?.name === "text")?"block":"none"
          }}
          className={classes.root}
          // onClick={()=>setToggled(false)}
      >
          <div
              style={{
                  display: "flex",
                  marginBottom: "10px",
                  justifyContent: "flex-end",
                  color: selectedTool?.name === "text" ? "#fff": "#000"
              }}
              onClick={()=>{
                setToggled(false)
                if (selectedTool?.name === "text") {
                  createText(canvas)
                }
              }}
          >
              {selectedTool?.name === "text"?'Done':'close'}
          </div>
          {/* {
              selectedTool?.name === "shape" && <div
                  style={{
                      display: "flex",
                      flexDirection: "column",
                      
                  }}
              >
                  <div
                      style={{
                          display: "flex",
                          flexDirection: "column",
                      }}
                  >
                      <span>Shape</span>
                      <div
                          style={{
                              display: "flex"
                          }}
                      >
                        <button style={{ marginRight: "5px"}} type="button" onClick={() => draw(canvas)}>
                          <img style={{width: "30px"}} src={PencilIcon} alt="Pencil" />
                        </button>
                        <button style={{ marginRight: "5px"}} type="button" onClick={() => createLine(canvas)}>
                          <img style={{width: "30px"}} src={LineIcon} alt="line" />
                        </button>
                        <button style={{ marginRight: "5px"}} type="button" onClick={() => createRect(canvas)}>
                          <img style={{width: "30px"}} src={RectangleIcon} alt="Rectangle" />
                        </button>
                        <button style={{ marginRight: "5px"}} type="button" onClick={() => createEllipse(canvas)}>
                          <img style={{width: "30px"}} src={EllipseIcon} alt="Ellipse" />
                        </button>
                        <button style={{ marginRight: "5px"}} type="button" onClick={() => createTriangle(canvas, options)}>
                          <img style={{width: "30px"}} src={TriangleIcon} alt="Triangle" />
                        </button>
                      </div>
                  </div>
                  <div style={{display: "flex", flexDirection: "column", marginTop: "10px", marginBottom: "10px"}}>
                      <span>Size</span>
                      <input
                        type="range"
                        min={1}
                        max={50}
                        step={1}
                        value={brushWidth}
                        onChange={changeCurrentWidth}
                      />

                  </div>
                  <div
                      style={{
                          display: "flex",
                          flexDirection: "column",
                          overflowX: "auto"
                      }}
                  >
                      <span>color</span>
                      <div
                          style={{
                              display: "flex",
                              overflowX: "auto"
                          }}
                      >
                          {
                              ['#0000CD', '#8B4513', '#008000', '#FFA500', '#FF0000', '#000000', '#DC143C', '#00BFFF', '#800080', '#FFFFFF', '#F0E68C', '#90EE90'].map(color=>(
                                  <div 
                                      style={{
                                          display: "flex",
                                          width: "30px",
                                          height: "40px",
                                          minWidth: "30px",
                                          backgroundColor: color
                                      }}
                                      onClick={()=>changeColor(color)}
                                  />
                              ))
                          }
                      </div>
                  </div>
              </div>
          } */}
          {
            selectedTool?.name === 'text' && <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <input 
                  value={textContent}
                  autoFocus
                  // onFocus={true}
                  onChange={(e)=>setTextContent(e.target.value)}
                  style={{
                    width: "50%",
                    height: "60px",
                    background: "transparent",
                    border: "none",
                    color: "#fff",
                    caretColor: "#fff",
                    fontSize: "30px !important"
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                {/* {
                  ['#0000CD', '#8B4513', '#008000', '#FFA500', '#FF0000', '#000000', '#DC143C', '#00BFFF', '#800080', '#FFFFFF', '#F0E68C', '#90EE90'].map(color=>(
                    <div 
                      style={{
                        display: "flex",
                        width: "30px",
                        height: "40px",
                        minWidth: "30px",
                        backgroundColor: color
                      }}
                      onClick={()=>changeColor(color)}
                    />
                  ))
                } */}
                {
                  ['#FFF', '#000', '#FF0000', '#FFA500', '#008000', '#87CEEB', '#7F00FF'].map(clr=>(
                    <div 
                      style={{
                        display: "flex",
                        width: "30px",
                        height: color===clr? "35px":"30px",
                        minWidth: color===clr? "35px":"30px",
                        backgroundColor: clr,
                        borderRadius: "50%",
                        border: "1px solid #fff"
                      }}
                      onClick={()=>changeColor(clr)}
                    />
                  ))
                }
              </div>
            </div>
          }
          {/* {
              selectedTool?.name === "filter" && <div
                  style={{
                      display: "flex",
                      overflowX: "auto"
                  }}
              >
                  <Filter 
                      url={imageUrl}
                      label={'None'}
                      onClick={()=>applyFilter('None')}
                  />
                  <Filter 
                      url={blacknwhite}
                      label={'Black & White'}
                      onClick={()=>applyFilter('BlackWhite')}
                  />
                  <Filter 
                      url={blur}
                      label={'Blur'}
                      onClick={()=>applyFilter('Blur')}
                  />
                  <Filter 
                      url={brightness}
                      label={'Brightness'}
                      onClick={()=>applyFilter('Brightness')}
                  />
                  <Filter 
                      url={brownie}
                      label={'Brownie'}
                      onClick={()=>applyFilter('Brownie')}
                  />
                  <Filter 
                      url={contrast}
                      label={'Contrast'}
                      onClick={()=>applyFilter('Contrast')}
                  />
                  <Filter 
                      url={grey}
                      label={'Grey'}
                      onClick={()=>applyFilter('Grayscale')}
                  />
                  <Filter 
                      url={invert}
                      label={'Invert'}
                      onClick={()=>applyFilter('Invert')}
                  />
                  <Filter 
                      url={kodachrome}
                      label={'Kodachrome'}
                      onClick={()=>applyFilter('Kodachrome')}
                  />
                  <Filter 
                      url={pixelate}
                      label={'Pixelate'}
                      onClick={()=>applyFilter('Pixelate')}
                  />
                  <Filter 
                      url={polaroid}
                      label={'Polaroid'}
                      onClick={()=>applyFilter('Polaroid')}
                  />
                  <Filter 
                      url={sepia}
                      label={'Sepia'}
                      onClick={()=>applyFilter('Sepia')}
                  />
                  <Filter 
                      url={technicolor}
                      label={'Technicolor'}
                      onClick={()=>applyFilter('Technicolor')}
                  /> 
              </div>
          } */}
          {/* {
              selectedTool?.name === 'sticker' && <div
                  style={{
                      display: "grid",
                      gridTemplateColumns: "15% 15% 15% 15% 15%",
                      columnGap: "5%",
                      rowGap: "20px"
                  }}
              >
                  {
                      // stickers.map(data=>(<img onClick={() => {addSticker(data); setToggled(false)}} src={data} />))
                  }
              </div>
          } */}
          {/* {
              selectedTool?.name === 'text' && <div
                  style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column"
                  }}
              >
                  <div
                      style={{
                          display: "flex",
                          width: "100%"
                      }}
                  >
                      <input 
                          type="text" 
                          // value={text}
                          // onChange={(e) => setText(e.target.value)} 
                          placeholder="Enter text"
                          style={{
                              width: "80%",
                              marginBottom: "10px",
                              height: "50px",
                              border: "1px solid #ddd",
                          }}

                      />
                      <input 
                          type="color" 
                          // value={textColor} 
                          // onChange={(e) => setTextColor(e.target.value)} 
                          style={{
                              width: "20%",
                              marginBottom: "10px",
                              height: "50px",
                              border: "none",
                              backgroundColor: "#fff"
                          }}
                      />
                  </div>
                  <button 
                      // onClick={addText}
                      style={{
                          width: "100%",
                          marginBottom: "10px",
                          height: "50px",
                          border: "1px solid #ddd",
                          backgroundColor: "#fff",
                          borderRadius: "10px"
                      }}
                  >Add Text</button>
              </div>
          } */}
      </div>
      {/* <div>
        <PdfReader fileReaderInfo={fileReaderInfo} updateFileReaderInfo={updateFileReaderInfo} />
      </div> */}
    </div>
  );
});

Whiteboard.propTypes = {
  aspectRatio: PropTypes.number,
};

export default Whiteboard;
