import { Box, Pagination, Typography, styled } from '@mui/material';
import React from 'react';
import { translate } from '../../helper';

const PaginationWrapper = styled(Pagination)(({ theme }) => ({
    'display': 'flex',
    'justifyContent': 'end',
    'margin': '20px 0',

    '& .Mui-selected': {
        'color': '#1890ff !important',
        'border': '1px solid #1890ff !important',
        'background': 'white !important'
    }
}));

const usePagination = (total, filters, setFilters) => {

    const handleChangePage = (evt, newPage) => {
        setFilters({
            ...filters,
            pageNo: newPage
        });
    };

    return (
        [
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    total > 0 && (
                        <>
                            <Typography variant='body1' sx={{ margin: '20px 0', fontSize: '16px' }}>{translate('showing')} <b>{(filters.pageNo - 1) * filters.limit + 1}</b> {translate('to')} <b>{Math.min(filters.pageNo * filters.limit, total)}</b> {translate('of')} <b>{total}</b> {translate('results')}</Typography>
                            <PaginationWrapper
                                variant="outlined"
                                shape="rounded"
                                component="div"
                                count={Math.ceil(total / filters.limit)}
                                onChange={handleChangePage}
                                page={filters.pageNo}
                            />
                        </>
                    )
                }

            </Box>
        ]
    );

}

export default usePagination;