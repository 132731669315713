import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './spinner.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './I18';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const root = ReactDOM.createRoot(document.getElementById('root'));

// navigator.serviceWorker
//   .register('./firebase-messaging-sw.js')
//   .then((registration) => {
//     console.log('Service worker registered:', registration);
//   })
//   .catch((error) => {
//     console.error('Error registering service worker:', error);
//   });

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// console.log('serviceWorkerRegistration', serviceWorkerRegistration);
serviceWorkerRegistration.register({ type: 'text/javascript' });
