import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Modal, Row } from 'antd';

import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import {
  MESSAGE_TYPE,
  convertPxToRem,
  getCreditCardTypeString,
  messageManager
} from '../../helper';
import { SVGImageComponentReact } from '../../themes/Images';
import { CardForm } from '../../common/card-form';
import { ConfirmModal } from '../../common/confirm-modal';
import { useDeletePaymentMethod, useGetPaymentMethod } from '../../hooks/usePayment';

import { styles } from './styles';

const { confirm } = Modal;

const VALUES = {
  card_holder: '',
  number: '',
  expiration_date: '',
  cvc: ''
};

function PaymentMethodEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { paymentMethod, isLoading, isError, error } = useGetPaymentMethod({
    id: state?.djstripe_id
  });

  const { deletePaymentMethod, isDeletingPaymentMethod } = useDeletePaymentMethod(onSuccess);

  const [values, setValues] = useState(VALUES);
  const [validation, setValidation] = useState({});
  const [expError, setExpError] = useState('');
  const [brand, setBrand] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    handleSetDetails();
  }, [paymentMethod]);

  const showCancelConfirm = () => {
    setShowDelete(true);
  };

  const closeCancelConfirm = () => {
    setShowDelete(false);
  };

  const handleSetDetails = () => {
    if (!paymentMethod?.djstripe_id) return;

    const values = {
      expiration_date: paymentMethod?.card?.exp_month + '/ ' + paymentMethod?.card?.exp_year,
      brand: getCreditCardTypeString(paymentMethod?.card?.brand),
      number: '**** **** **** ' + paymentMethod?.card?.last4,
      card_holder: paymentMethod?.billing_details?.name || '',
      cvc: '***'
    };
    setValues(values);
  };

  const handleDelete = () => {
    deletePaymentMethod({ djstripe_id: paymentMethod?.djstripe_id });
  };

  const showDeleteConfirm = () => {
    showCancelConfirm();
  };

  function onSuccess(data) {
    closeCancelConfirm();
    messageManager({
      messageShow: 'Card deleted',
      type: MESSAGE_TYPE.success
    });
    navigate(-1);
  }

  return (
    <div className={css(styles.container)}>
      <Row justify="space-between">
        <Col>
          <Row>
            <div
              style={{ marginRight: convertPxToRem(33) }}
              className="flex-row-center-center cursor-pointer"
              onClick={() => navigate(-1)}>
              <SVGImageComponentReact.IconArrowBack />
            </div>
            <TextBase fontWeight={600} size={convertPxToRem(24)} color={Colors.titlePage}>
              Edit Card
            </TextBase>
          </Row>
        </Col>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
        <div
          style={{
            width: '390px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '32px'
          }}>
          <CardForm
            values={values}
            setValues={setValues}
            validation={validation}
            setValidation={setValidation}
            brand={values?.brand}
            disabled
          />

          {isError && (
            <TextBase fontWeight={400} size={convertPxToRem(14)} color={Colors.red}>
              {error?.response?.data?.detail || 'Error while adding card'}
            </TextBase>
          )}
          <ButtonGradient
            onClick={() => {}}
            opacity={0.5}
            textStyle={{ fontWeight: 700, fontSize: 16 }}
            title="SAVE"
            style={{ marginTop: '18px', width: '100%' }}
          />

          <ButtonGradient
            onClick={showDeleteConfirm}
            loading={isDeletingPaymentMethod}
            backgroundColor="#fff"
            textStyle={{ fontWeight: 700, fontSize: 16, color: '#6B7280' }}
            title="DELETE"
            style={{ marginTop: '18px', width: '100%', border: '1px solid #6B7280' }}
          />
        </div>
      </div>

      <ConfirmModal
        visible={showDelete}
        handleOk={handleDelete}
        status="danger"
        message="You are deleting your card"
        okBtnText="DELETE"
        cancelBtnText="CANCEL"
        isLoading={isDeletingPaymentMethod}
        width="400px"
        handleCancel={closeCancelConfirm}
      />
    </div>
  );
}

export default PaymentMethodEdit;
