import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../helper';

const BootstrapDialog = styled(Dialog)(({ theme, color }) => ({
    '& .MuiDialogTitle-root': {
        'fontWeight': 'bold',
        'backgroundColor': color||'white',
        'color': 'black',
        'textAlign': 'center',
        'minHeight': '50px'
    },
    '& .MuiDialogContent-root':{
        'padding': '20px 35px',
        'borderTop': '0',
        'backgroundColor': color||'white',
    },
    '& .MuiDialogActions-root': {
        'padding': '10px 0',
    },
    '& .MuiButtonBase-root': {
        'padding': '10px 0',
        'color': color?'white':"#000",
    },
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
        'borderRadius': '10px'
    }
}));

function Modal({ title, isOpen, onClose, children, style={}, fullScreen, showHeader=true }) {

    const theme = useTheme();

    return (
        <div>
            <BootstrapDialog
                onClose={onClose}
                // maxWidth={'sm'}
                open={isOpen}
                color={style.backgroundColor}
                fullScreen={fullScreen}
            >
                {showHeader&&<>
                    <DialogTitle>
                        {translate(title)}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </>}
                <DialogContent dividers>
                    {children}
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default Modal;