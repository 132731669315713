import React from "react"
import {connect} from "react-redux"
import {ROUTER_NAME} from "../../router/routers"
import LoginInput from "../../common/login-input"
import BasePage from "../base"
import withNavigate from "../../component/WithNavigate"
import {convertPxToRem, getValueFromObjectByKeys, myLog} from "../../helper"
import {UserAction} from "../../redux/action"
import TextBase from "../../common/text"
import Link from "../../common/link"
import {Colors} from "../../themes/Colors"
import ButtonGradient from "../../common/button-gradient"
import './signup.styles.css'
import {Col, Row} from "antd";
import {motion} from "framer-motion";
import {animate} from "../../utils/defined";
import {SVGImageComponentReact} from "../../themes/Images";
import SignUpStepTwo from "./signUpStepTwo/SignUpStepTwo.page";
import InputError from "../../common/input-error/InputError.component";

class SignUp extends BasePage {
    constructor(props) {
        super(props)
        this.state = {
            businessEmail: '',
            businessName: '',
            password: "",
            confirmPassword: "",
            showPassword: false,
            showConfirmPassword: false,
            phoneNumber: '',
            taxID: '',
            country: 'US',
            phoneCode: '1',
            state: '',
            city: '',
            zip: '',
            address: '',
            showNextStep: false,
            errors: {}
        }
    }

    onChangeBusinessEmail = value => {
        this.setState({businessEmail: value})
    }
    onChangeBusinessName = value => {
        this.setState({businessName: value})
    }
    onChangePassword = value => {
        this.setState({password: value})
    }
    onConfirmPassword = value => {
        this.setState({confirmPassword: value})
    }

    handleState = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    keyUp = () => {
        // if (this.state.password !== this.state.confirmPassword) {
        //     alert('asdasdasd')
        // }
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

    };

    containsNumbers = (str) => {
        return Boolean(str.match(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/));
    }

    validatePassword = (password) => {
        return password.length >= 8 && this.containsNumbers(password);
    }

    checkIfError = (error) => {
        return error.length === 0
    }

    handleNext = () => {
        const errors = this.state.errors

        // Email validation
        if (!this.state.businessEmail) {
            errors.email = ['emailError']
            this.setState({
                errors
            })
        } else if (!this.validateEmail(this.state.businessEmail)) {
            errors.email = ['emailValidationError']
            this.setState({
                errors
            })
        } else {
            errors.email = []
            this.setState({errors})
        }

        // Business name validation
        if (!this.state.businessName) {
            errors.business_name = ['businessNameError']
            this.setState({
                errors
            })
        } else {
            errors.business_name = []
            this.setState({errors})
        }

        // Password validation
        if (!this.state.password) {
            errors.password1 = ['passwordError']
            this.setState({
                errors
            })
        } else if (!this.validatePassword(this.state.password)) {
            errors.password1 = ['passwordValidationError']
        } else {
            errors.password1 = []
            this.setState({errors})
        }

        // Confirm password validation
        if (!this.state.confirmPassword) {
            errors.password2 = ['confirmPasswordError']
            this.setState({
                errors
            })
        } else if (!this.validatePassword(this.state.password)) {
            errors.password2 = ['confirmPasswordValidationError']
        } else if (this.state.password !== this.state.confirmPassword) {
            errors.password2 = ['passwordEqualityError']
        } else {
            errors.password2 = []
            this.setState({errors})
        }

        if (
            this.checkIfError(this.state.errors?.email) &&
            this.checkIfError(this.state.errors?.business_name) &&
            this.checkIfError(this.state.errors?.password1) &&
            this.checkIfError(this.state.errors?.password2)
        ) {
            this.setState({showNextStep: true})
        }

    }


    toggleSteps = () => {
        this.setState({
            showNextStep: false
        })
    }

    handleErrors = (errors) => {
        this.setState({errors})
    }

    handleStepTwo = (data) => {
        const errors = this.state.errors

        if (!this.state.phoneNumber) {
            errors.phone_number = ['Phone is required']
            this.setState({
                errors
            })
        } else {
            errors.phone_number = []
            this.setState({errors})
        }
    }

    renderContent() {
        myLog("renderContent=>", this.props)
        const {
            businessEmail,
            businessName,
            password,
            confirmPassword,
            phoneNumber,
            taxID,
            country,
            phoneCode,
            state,
            city,
            zip,
            address,
            showPassword,
            showConfirmPassword,
            registering,
            errors,
            showNextStep
        } = this.state

        const IconEyePassword = showPassword
            ? SVGImageComponentReact.IconEyeOffPassword
            : SVGImageComponentReact.IconEyePassword

        const IconEyePasswordConfirm = showConfirmPassword
            ? SVGImageComponentReact.IconEyeOffPassword
            : SVGImageComponentReact.IconEyePassword

        const {navigate} = this.props

        return (
            <div>

                {!showNextStep && (
                    <motion.div
                        className='no-select'
                        initial={animate.initial}
                        animate={animate.animate}
                        transition={animate.transition}>
                        <div
                            className="max-width-100"
                            style={{
                                paddingTop: convertPxToRem(100),
                                paddingInline: convertPxToRem(20),
                                paddingBottom: convertPxToRem(20)
                            }}>
                            <TextBase
                                style={{marginBottom: convertPxToRem(11)}}
                                size={convertPxToRem(32)}
                                fontWeight={600}
                                color={Colors.black}
                            >
                                createAnAccount
                            </TextBase>
                            <TextBase size={convertPxToRem(18)} color={Colors.black} style={{marginBottom: 50}}>
                                fillTheInformation
                            </TextBase>
                        </div>

                        <div className="first-step-wrapper">
                            <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                                {/* Business email */}
                                <Col span={24}>
                                    <div>
                                        <TextBase
                                            style={{textAlign: "left"}}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}
                                        >
                                            businessEmail
                                        </TextBase>
                                        <LoginInput
                                            value={businessEmail}
                                            onChange={this.onChangeBusinessEmail}
                                            placeholder="enterEmail"
                                        />
                                        <InputError error={errors.email}/>
                                    </div>
                                </Col>

                                {/* Business name */}
                                <Col span={24} className="row-margin">
                                    <div className="max-width-100">
                                        <TextBase
                                            style={{textAlign: "left"}}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}
                                        >
                                            businessName
                                        </TextBase>
                                        <LoginInput
                                            value={businessName}
                                            onChange={this.onChangeBusinessName}
                                            placeholder="enterBusinessName"
                                        />
                                        <InputError error={errors.business_name}/>
                                    </div>
                                </Col>

                                {/* Password */}
                                <Col span={24} className="row-margin">
                                    <div>
                                        <TextBase
                                            style={{textAlign: "left"}}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}>
                                            password
                                        </TextBase>
                                        <LoginInput
                                            value={password}
                                            onChange={this.onChangePassword}
                                            placeholder="enterPassword"
                                            type={showPassword ? "text" : "password"}
                                            rightElement={
                                                <IconEyePassword
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        this.setState({showPassword: !showPassword})
                                                    }}
                                                />
                                            }
                                        />
                                        <InputError error={errors.password1}/>
                                    </div>
                                </Col>


                                {/* Confirm password */}
                                <Col span={24} className="row-margin">
                                    <div className="max-width-100">
                                        <TextBase
                                            className='no-select'
                                            style={{textAlign: "left"}}
                                            size={convertPxToRem(16)}
                                            color={Colors.black}
                                        >
                                            confirmPassword
                                        </TextBase>
                                        <LoginInput
                                            value={confirmPassword}
                                            onChange={this.onConfirmPassword}
                                            onKeyUp={this.keyUp}
                                            placeholder="enterPasswordAgain"
                                            type={showConfirmPassword ? "text" : "password"}
                                            rightElement={
                                                <IconEyePasswordConfirm
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        this.setState({showConfirmPassword: !showConfirmPassword})
                                                    }}
                                                />
                                            }
                                        />
                                        <InputError error={errors.password2}/>
                                        <TextBase size={convertPxToRem(14)} color={Colors.grey}
                                                  className='password-requirements no-select'>
                                            passwordConstraint
                                        </TextBase>
                                    </div>
                                </Col>
                            </Row>


                            {/* Terms */}
                            <div className="max-widths-100">
                            
                                <TextBase size={convertPxToRem(14)} color={Colors.grey} className='terms'>
                                {/* <Trans key={signup_confirmation}> */}

                                signup_part1 
                                <br/>
                                signup_part2
                                <Link className='link' onClick={() => navigate('/terms')}>termsAndCondition</Link>
                                     signup_part3
                                     <Link className='link' onClick={() => navigate('/privacy')}>privacyPolicy</Link>
                                    {/* By signing up, you confirm that yo've read <br/>
                                    and accepted our
                                    <span className='link'
                                          onClick={() => navigate('/terms')}> Terms and Conditions </span>
                                    and
                                    <span className='link' onClick={() => navigate('/privacy')}> Privacy Policy.</span> */}
                                </TextBase>
                            </div>

                            <div
                                style={{marginBottom: 19, marginTop: 50}}
                                className="flex-center-all max-width-100"
                            >
                                <ButtonGradient
                                    onClick={this.handleNext}
                                    textStyle={{fontWeight: 600}}
                                    title="next"
                                    loading={registering}
                                />
                            </div>

                        </div>


                        <div className="sign-in-wrapper">
                            <div className='sign-in'>
                                <TextBase size={convertPxToRem(16)} color={Colors.black}>
                                alreadyHaveAnAccount
                                </TextBase>
                                <TextBase
                                    pointer
                                    underline
                                    fontWeight={700}
                                    style={{marginLeft: convertPxToRem(9)}}
                                    size={convertPxToRem(16)}
                                    color={Colors.mainColor}
                                    onClick={() => navigate(ROUTER_NAME.login)}
                                >
                                    signIn
                                </TextBase>
                            </div>
                        </div>
                    </motion.div>
                )}

                {
                    showNextStep && (
                        <SignUpStepTwo
                            toggleSteps={this.toggleSteps}
                            handleErrors={this.handleErrors}
                            handleState={this.handleState}
                            data={{
                                businessEmail,
                                businessName,
                                password,
                                confirmPassword,
                                phoneNumber,
                                taxID,
                                country,
                                phoneCode,
                                state,
                                city,
                                zip,
                                address,
                            }}/>
                    )
                }

            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        userInfo: getValueFromObjectByKeys(state, ["User", "userInfo"])
    }
}
const mapDispatchToProps = dispatch => {
    return {
        login: params => {
            return dispatch(UserAction.login(params))
        },
        saveUserInfo: data => {
            return dispatch(UserAction.saveUserInfo(data))
        },
        register: data => {
            return dispatch(UserAction.register(data))
        },
        createVendor: data => {
            return dispatch(UserAction.createVendor(data))
        }
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigate(SignUp))
