import { API_ENDPOINT, API_URL } from './ApiDefine';
import Connector, { TYPE_METHOD } from './Connector';

export default class APIManager {
  static getInstance() {
    if (!this._instance) {
      this._instance = new APIManager();
    }
    return this._instance;
  }

  static clear() {
    if (this._instance) {
      delete this._instance;
    }
  }

  constructor() {
    // this.name = 'APIManager';
  }

  // 0. GetConnector
  getConnector = (url, baseUrl = API_URL.baseUrl) => {
    return new Connector().setUrl(baseUrl + url);
  };

  whiteLabel = (params = {}) => {
    return this.getConnector('', API_URL.whiteLabel)
      .setMethod(TYPE_METHOD.GET)
      .setParams({ ...params })
      .setUseToken(false)
      .getPromise();
  };

  login = (params = {}) => {
    console.log("api-url:",API_URL.login);
    return this.getConnector('', API_URL.login)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .setUseToken(false)
      .getPromise();
  };
  register = (params) => {
    return this.getConnector('', API_URL.vendorSignup)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .setUseToken(false)
      .getPromise();
  };
  createVendor = (params) => {
    return (
      this.getConnector('', API_URL.vendors)
        .setMethod(TYPE_METHOD.POST)
        .setParams(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  updateVendor = (params, id) => {
    return (
      this.getConnector(``, API_URL.vendorProfile)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  updateProfile = (params, id) => {
    console.log('params', params);
    return (
      this.getConnector(``, API_URL.profile)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  updateProfileImage = (params, id) => {
    return (
      this.getConnector(``, API_URL.profile)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        .setUseMultipartFormData(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  updateUserModel = (params) => {
    return (
      this.getConnector(API_ENDPOINT.restAuth.user, API_URL.restAuth)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  forgotPassword = (params) => {
    return this.getConnector(API_ENDPOINT.resetPassword, API_URL.resetPassword)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .setUseToken(false)
      .getPromise();
  };

  changePassword = (params) => {
    return this.getConnector(API_ENDPOINT.restAuth.changePassword, API_URL.restAuth)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .getPromise();
  };

  setNewPassword = (params, token) => {
    console.log('token iz set', token);
    return this.getConnector(API_ENDPOINT.setNewPassword, API_URL.setNewPassword)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .setUseTmpToken(true)
      .getPromise();
  };

  resetPassword = (params) => {
    return this.getConnector(API_ENDPOINT.changePassword, API_URL.changePassword)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .getPromise();
  };
  getListPayment = (params) => {
    return this.getConnector(API_ENDPOINT.modulesPayments.methods, API_URL.modulesPayment)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  logout = () => {
    localStorage.clear();
    return (
      this.getConnector(API_ENDPOINT.restAuth.logout, API_URL.restAuth)
        .setMethod(TYPE_METHOD.POST)
        .setShowMessage(false)
        // .setUseToken(false)
        .getPromise()
    );
  };
  updateFinancialInfo = (params, id) => {
    return (
      this.getConnector(`${id}/`, API_URL.userInfo)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        // .setUseToken(false)
        .getPromise()
    );
  };
  getUserProfile = (id) => {
    return (
      this.getConnector(``, API_URL.profile)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  };
  getVendorProfile = (id) => {
    return (
      this.getConnector(``, API_URL.vendorProfile)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  };
  getVendor = (query) => {
    return this.getConnector('', API_URL.vendors)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getOrderDetail = (id) => {
    return this.getConnector(`${id}/`, API_URL.orders)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  updateOrder = (id, data) => {
    return this.getConnector(`${id}/`, API_URL.orders)
      .setMethod(TYPE_METHOD.PATCH)
      .setParams(data)
      .getPromise();
  };
  createQuotes = (data) => {
    return this.getConnector(``, API_URL.quotes)
      .setMethod(TYPE_METHOD.POST)
      .setParams(data)
      .getPromise();
  };
  ocrScanner = (data) => {
    return this.getConnector(``, API_URL.scanner)
      .setMethod(TYPE_METHOD.POST)
      .setParams(data)
      .getPromise();
  };
  updateQuotes = (data, id) => {
    return this.getConnector(`${id}/`, API_URL.quotes)
      .setMethod(TYPE_METHOD.PATCH)
      .setParams(data)
      .getPromise();
  };
  createChatRoom = (data, id) => {
    return this.getConnector(``, API_URL.createChatRoom)
      .setMethod(TYPE_METHOD.POST)
      .setParams(data)
      .getPromise();
  };
  sendMessage = (data, id) => {
    return this.getConnector(
      ``,
      API_URL.baseUrl +
        `api/v1/chats/firestore/order-rooms/detail/${data?.order_id}/send-message/${data?.vendor_id}/`
    )
      .setMethod(TYPE_METHOD.POST)
      .setParams(data)
      .getPromise();
  };
  getFirebaseAuthToken = (query = {}) => {
    return this.getConnector('', API_URL.getFirebaseAuthToken)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getOrders = (query = {}) => {
    return this.getConnector('', API_URL.orders)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getQuotes = (query = {}) => {
    return this.getConnector('', API_URL.quotes)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getQuoteDetails = (id, query = {}) => {
    return this.getConnector(`${id}/`, API_URL.quotes)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getUserQuotes = (query = {}) => {
    return this.getConnector('', API_URL.userQuotes)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getSignatureZoom = ({ meetingNumber, role }) => {
    return this.getConnector('', 'http://localhost:4000')
      .setMethod(TYPE_METHOD.POST)
      .setParams({
        meetingNumber,
        role: role !== undefined ? role : 0
      })
      .getPromise();
  };
  //assets
  //investment
  getAssetInvestment = (params) => {
    return this.getConnector(API_ENDPOINT.assets.investment, API_URL.assets)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createAssetInvestment = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.assets.investment, API_URL.assets)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchAssetInvestment = (id, params, isFormData) => {
    return this.getConnector(`${API_ENDPOINT.assets.investment}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putAssetInvestment = (id, params) => {
    return this.getConnector(`${API_ENDPOINT.assets.investment}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteAssetInvestment = (id) => {
    return this.getConnector(`${API_ENDPOINT.assets.investment}${id}/`, `${API_URL.assets}`)
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };

  //insurance
  getAssetInsurance = (params) => {
    return this.getConnector(API_ENDPOINT.assets.insurance, API_URL.assets)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createAssetInsurance = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.assets.insurance, API_URL.assets)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchAssetInsurance = (id, params, isFormData) => {
    return this.getConnector(`${API_ENDPOINT.assets.insurance}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putAssetInsurance = (id, params) => {
    return this.getConnector(`${API_ENDPOINT.assets.insurance}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteAssetInsurance = (id) => {
    return this.getConnector(`${API_ENDPOINT.assets.insurance}${id}/`, `${API_URL.assets}`)
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };

  //real-state
  getAssetRealEState = (params) => {
    return this.getConnector(API_ENDPOINT.assets.realEState, API_URL.assets)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createAssetRealEState = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.assets.realEState, API_URL.assets)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchAssetRealEState = (id, params, isFormData) => {
    return this.getConnector(`${API_ENDPOINT.assets.realEState}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putAssetRealEState = (id, params) => {
    return this.getConnector(`${API_ENDPOINT.assets.realEState}${id}/`, `${API_URL.assets}`)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteAssetRealEState = (id) => {
    return this.getConnector(`${API_ENDPOINT.assets.realEState}${id}/`, `${API_URL.assets}`)
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //liabilities
  //real-estate
  getLiabilitiesRealEState = (params) => {
    return this.getConnector(API_ENDPOINT.liabilities.realEState, API_URL.liabilities)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createLiabilitiesRealEState = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.liabilities.realEState, API_URL.liabilities)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchLiabilitiesRealEState = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.realEState}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putLiabilitiesRealEState = (id, params) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.realEState}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteLiabilitiesRealEState = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.realEState}${id}/`,
      `${API_URL.liabilities}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //credit-card
  getLiabilitiesCreditCard = (params) => {
    return this.getConnector(API_ENDPOINT.liabilities.creditCard, API_URL.liabilities)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createLiabilitiesCreditCard = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.liabilities.creditCard, API_URL.liabilities)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchLiabilitiesCreditCard = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.creditCard}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putLiabilitiesCreditCard = (id, params) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.creditCard}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteLiabilitiesCreditCard = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.creditCard}${id}/`,
      `${API_URL.liabilities}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //personal-note
  getLiabilitiesPersonalNote = (params) => {
    return this.getConnector(API_ENDPOINT.liabilities.personalNote, API_URL.liabilities)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createLiabilitiesPersonalNote = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.liabilities.personalNote, API_URL.liabilities)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchLiabilitiesPersonalNote = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.personalNote}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putLiabilitiesPersonalNote = (id, params) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.personalNote}${id}/`,
      `${API_URL.liabilities}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteLiabilitiesPersonalNote = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.liabilities.personalNote}${id}/`,
      `${API_URL.liabilities}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //loans
  getLiabilitiesLoans = (params) => {
    return this.getConnector(API_ENDPOINT.liabilities.loans, API_URL.liabilities)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createLiabilitiesLoans = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.liabilities.loans, API_URL.liabilities)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchLiabilitiesLoans = (id, params, isFormData) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.loans}${id}/`, `${API_URL.liabilities}`)
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putLiabilitiesLoans = (id, params) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.loans}${id}/`, `${API_URL.liabilities}`)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteLiabilitiesLoans = (id) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.loans}${id}/`, `${API_URL.liabilities}`)
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //others
  getLiabilitiesOthers = (params) => {
    return this.getConnector(API_ENDPOINT.liabilities.others, API_URL.liabilities)
      .setQuery(params)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  createLiabilitiesOthers = (params, isFormData) => {
    return this.getConnector(API_ENDPOINT.liabilities.others, API_URL.liabilities)
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .setUseMultipartFormData(isFormData)
      .getPromise();
  };
  patchLiabilitiesOthers = (id, params, isFormData) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.others}${id}/`, `${API_URL.liabilities}`)
      .setParams(params)
      .setUseMultipartFormData(isFormData)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putLiabilitiesOthers = (id, params) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.others}${id}/`, `${API_URL.liabilities}`)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  deleteLiabilitiesOthers = (id) => {
    return this.getConnector(`${API_ENDPOINT.liabilities.others}${id}/`, `${API_URL.liabilities}`)
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  //income & expenses
  //pay-stubs
  getPayStubs = (query) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.payStubs}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setQuery(query)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  deletePayStubs = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.payStubs}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  createPayStubs = (params) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.payStubs}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .getPromise();
  };
  patchPayStubs = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.payStubs}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putPayStubs = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.payStubs}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  //tax-returns
  getTaxReturn = (query) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.taxReturn}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setQuery(query)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  deleteTaxReturn = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.taxReturn}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  createTaxReturn = (params) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.taxReturn}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .getPromise();
  };
  patchTaxReturn = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.taxReturn}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putTaxReturn = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.taxReturn}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };
  //expenses
  getExpenses = (query) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.expenses}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setQuery(query)
      .setMethod(TYPE_METHOD.GET)
      .getPromise();
  };
  deleteExpenses = (id) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.expenses}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setMethod(TYPE_METHOD.DELETE)
      .getPromise();
  };
  createExpenses = (params) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.expenses}`,
      `${API_URL.incomeAndExpenses}`
    )
      .setParams(params)
      .setMethod(TYPE_METHOD.POST)
      .getPromise();
  };
  patchExpenses = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.expenses}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PATCH)
      .getPromise();
  };
  putExpenses = (id, params, isFormData) => {
    return this.getConnector(
      `${API_ENDPOINT.incomeAndExpenses.expenses}${id}/`,
      `${API_URL.incomeAndExpenses}`
    )
      .setUseMultipartFormData(isFormData)
      .setParams(params)
      .setMethod(TYPE_METHOD.PUT)
      .getPromise();
  };

  //TERMS AND PRIVACY
  getTerms = () => {
    return this.getConnector(API_ENDPOINT.terms, API_URL.terms)
      .setMethod(TYPE_METHOD.GET)
      .setUseToken(false)
      .getPromise();
  };
  getPrivacy = () => {
    return this.getConnector(API_ENDPOINT.privacy, API_URL.privacy)
      .setMethod(TYPE_METHOD.GET)
      .setUseToken(false)
      .getPromise();
  };

  // VERIFY PHONE
  sendSMS = (params) => {
    return this.getConnector('', API_URL.sendSMS)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };
  sendSMSUser = (params) => {
    return this.getConnector('', API_URL.sendSMSUser)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };

  verifyPhoneNumber = (params) => {
    return this.getConnector('', API_URL.verifyPhoneNumber)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };
  verifyPhoneNumberUser = (params) => {
    return this.getConnector('', API_URL.verifyPhoneNumberUser)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };
  getNotifications = (params) => {
    return this.getConnector('', API_URL.getNotifications)
      .setMethod(TYPE_METHOD.GET)
      .setParams({ ...params })
      .getPromise();
  };
  registerDevice = (params) => {
    return this.getConnector('', API_URL.registerDevice)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };
  markAsRead = (id) => {
    return this.getConnector(`${id}/`, API_URL.markAsRead)
      .setMethod(TYPE_METHOD.PATCH)
      .setParams({ is_read: true })
      .getPromise();
  };

  markAsReadAll = (params) => {
    return this.getConnector(``, API_URL.markAsReadAll)
      .setMethod(TYPE_METHOD.POST)
      .setParams(params)
      .getPromise();
  };

  getDashboardTotals = (query) => {
    return this.getConnector('', API_URL.dashboardTotals)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getAcceptedOrders = (query) => {
    return this.getConnector('', API_URL.acceptedOrders)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };
  getNumberOfQuotes = (query) => {
    return this.getConnector('', API_URL.numberOfQuotes)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };

  inviteTeamMember = (params = {}) => {
    return this.getConnector('', API_URL.inviteTeamMember)
      .setMethod(TYPE_METHOD.POST)
      .setParams({ ...params })
      .getPromise();
  };
  getTeamMembers = (query) => {
    return this.getConnector('', API_URL.teamMembers)
      .setMethod(TYPE_METHOD.GET)
      .setQuery(query)
      .getPromise();
  };

  getTeamProfile = (id) => {
    return (
      this.getConnector(`${id}/`, API_URL.teamMembers)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  };
  //whitelabel
  updateTheme = (params, id) => {
    return (
      this.getConnector(``, API_URL.themeUpdate)
        .setMethod(TYPE_METHOD.POST)
        .setParams(params)
        .setUseToken(true)
        .getPromise()
    );
  };
  updateExistingTheme = (params, id) => {
    return (
      this.getConnector(``, API_URL.themeUpdate)
        .setMethod(TYPE_METHOD.PATCH)
        .setParams(params)
        .setUseToken(true)
        .getPromise()
    );
  };
  updateEmailTemplate = (params, id) => {
    return (
      this.getConnector(``, API_URL.emailTemplateUpdate)
        .setMethod(TYPE_METHOD.POST)
        .setParams(params)
        .setUseToken(true)
        .getPromise()
    );
  };
  getChildAccounts = (query) => {
    return (
      this.getConnector(``, API_URL.getChildAccounts)
        .setMethod(TYPE_METHOD.GET)
        .setQuery(query)
        // .setUseToken(false)
        .getPromise()
    );
  }
  getParentOrders = (query) => {
    return (
      this.getConnector(``, API_URL.getParentOrders)
        .setMethod(TYPE_METHOD.GET)
        .setQuery(query)
        // .setUseToken(false)
        .getPromise()
    );
  }
  getWhitelabel = () => {
    // console.log(domain)
    return (
      this.getConnector(``, API_URL.getWhitelabelData)
        .setMethod(TYPE_METHOD.GET)
        .setUseToken(false)
        .getPromise()
    );
  }
  getVendorSubscitption = () => {
    return (
      this.getConnector(``, API_URL.getVendorSubscitption)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  }
  getSubscitptionProducts = () => {
    return (
      this.getConnector(``, API_URL.getSubscitptionProducts)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  }
  updateOrderForm = (params) => {
    console.log("params ", params)
    return (
      this.getConnector(``, API_URL.orderFormUpdate)
        .setMethod(TYPE_METHOD.POST)
        .setParams(params)
        .setUseToken(true)
        .setUseMultipartFormData(false)
        .getPromise()
    );
  };
  getDynamicAttributes = () => {
    return (
      this.getConnector(``, API_URL.getDynamicAttributes)
        .setMethod(TYPE_METHOD.GET)
        // .setUseToken(false)
        .getPromise()
    );
  }
  gateEmailTemplate = () => {
    return (
      this.getConnector(``, API_URL.emailTemplateUpdate)
        .setMethod(TYPE_METHOD.GET)
        .setUseToken(true)
        .getPromise()
    );
  };
  sendSetUpLink = () => {
    return (
      this.getConnector(``, API_URL.sendSetUpLink)
        .setMethod(TYPE_METHOD.POST)
        .setUseToken(true)
        .getPromise()
    );
  };
  updateInprogress = (params, id) => {
    return (
      this.getConnector(``, API_URL.updateInprogress+id+"/")
        .setMethod(TYPE_METHOD.POST)
        .setParams(params)
        .getPromise()
    );
  };
} 
export { APIManager };
