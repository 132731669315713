import { useMutation, useQuery } from '@tanstack/react-query';
import { subscriptionAPIService } from '../services/subscriptionAPIService';

export const useGetVendorSubscriptionProducts = (
  onError = () => {},
  queryClient,
  enabled = true
) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetVendorSubscriptionProducts'],
    () => subscriptionAPIService.getVendorSubscriptionProducts(),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: enabled,
      keepPreviousData: false,
      retry: 1,
      onError: (e) => {
        onError();
        queryClient.setQueryData(['useGetVendorSubscriptionProducts'], null);
      }
    }
  );

  return {
    vendorSubscriptionProducts: data,
    isFetched,
    isLoadingVendorSubscriptionProducts: isLoading,
    refetch,
    error
  };
};

export const useGetVendorSubscriptionDetails = (onError = () => {}, enabled = true) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetVendorSubscriptionDetails'],
    () => subscriptionAPIService.getVendorSubscriptionDetails(),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: true,
      keepPreviousData: false,
      retry: 1,
      onError: (e) => {
        onError();
      }
    }
  );

  return {
    vendorSubscriptionDetails: data,
    isFetchedVendorSubscriptionDetails: isFetched,
    isLoadingVendorSubscriptionDetails: isLoading,
    refetchVendorSubscriptionDetails: refetch,
    error
  };
};

export const useCreateVendorSubscription = (onSuccess, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.createVendorSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);

        onError(e?.response?.data);
      }
    }
  );

  return {
    createVendorSubscription: mutateAsync,
    isCreatingSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useReactivateVendorSubscription = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.reactivateVendorSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    reactivateVendorSubscription: mutateAsync,
    isReactivatingSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};


export const useReactivateChildSubscription = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.reactivateChildSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    reactivateChildSubscription: mutateAsync,
    isReactivatingChildSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useCancelVendorSubscription = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.cancelVendorSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    cancelVendorSubscription: mutateAsync,
    isCancellingSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useCancelChildSubscription = (onSuccess) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.cancelChildSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);
      }
    }
  );

  return {
    cancelChildSubscription: mutateAsync,
    isCancellingChildSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};

export const useGetTrialSubscriptionSettings = (onError = () => {}, enabled = true) => {
  const { data, isLoading, error, refetch, isFetched } = useQuery(
    ['useGetTrialSubscriptionSettings'],
    () => subscriptionAPIService.getTrialSubscriptionSettings(),
    {
      staleTime: 0,
      cacheTime: 0,
      enabled: true,
      keepPreviousData: false,
      retry: 1,
      onError: (e) => {
        onError();
      }
    }
  );

  return {
    trialSubscriptionSettings: data,
    isFetchedTrialSubscriptionSettings: isFetched,
    isLoadingTrialSubscriptionSettings: isLoading,
    refetchTrialSubscriptionSettings: refetch,
    error
  };
};

export const useCreateVendorChildSubscription = (onSuccess, onError) => {
  const { mutateAsync, isLoading, isSuccess, data, isError, error } = useMutation(
    (payload) => subscriptionAPIService.createVendorChildSubscription(payload),
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (e) => {
        console.error(e);

        onError(e?.response?.data);
      }
    }
  );

  return {
    createVendorChildSubscription: mutateAsync,
    isCreatingChildSubscription: isLoading,
    isSuccess,
    data,
    isError,
    error
  };
};