import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import {
  MESSAGE_TYPE,
  convertPxToRem,
  getCreditCardTypeString,
  messageManager
} from '../../helper';
import { SVGImageComponentReact } from '../../themes/Images';
import { NewCardButton } from '../../common/new-card-button';
import { useGetPaymentMethods } from '../../hooks/usePayment';
import { CreditCard } from '../../common/credit-card';
import LoadingBase from '../../common/loading';
import {
  useCreateVendorSubscription,
  useGetTrialSubscriptionSettings
} from '../../hooks/useSubscriptions';
import { ConfirmModal } from '../../common/confirm-modal';

import { styles } from './styles';

dayjs.extend(LocalizedFormat);

function Payment() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { paymentMethods, isLoading } = useGetPaymentMethods();
  const { createVendorSubscription, isCreatingSubscription } = useCreateVendorSubscription(
    onSuccess,
    onError
  );
  const { trialSubscriptionSettings } = useGetTrialSubscriptionSettings();

  const [selected, setSelected] = useState();
  const [showPaymentError, setShowPaymentError] = useState({ isVisible: false, message: '' });

  const trialObj = trialSubscriptionSettings?.find(
    (item) => item?.subscription_price === state?.id
  );

  useEffect(() => {
    if (paymentMethods?.length) {
      setSelected(paymentMethods[0]);
    }
  }, [paymentMethods]);

  const closeError = () => {
    setShowPaymentError(false);
  };

  const handlePay = () => {
    createVendorSubscription({
      payment_method: selected?.djstripe_id,
      price: state?.id,
      is_trial: trialObj?.is_trial_active || false
    });
  };

  function onSuccess(data) {
    messageManager({
      type: MESSAGE_TYPE.success,
      messageShow: 'Successfully subscribed'
    });
    navigate(-1);
  }

  function onError(error) {
    setShowPaymentError({ isVisible: true, message: error?.error });
  }

  return (
    <div className={css(styles.container)}>
      <Row justify="space-between">
        <Col>
          <Row>
            <div
              style={{ marginRight: convertPxToRem(33) }}
              className="flex-row-center-center cursor-pointer"
              onClick={() => navigate(-1)}>
              <SVGImageComponentReact.IconArrowBack />
            </div>
            <TextBase fontWeight={600} size={convertPxToRem(24)} color={Colors.titlePage}>
              Payment
            </TextBase>
          </Row>
        </Col>
      </Row>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
        <div
          style={{
            width: '390px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: '32px'
          }}>
          {trialObj?.is_trial_active && paymentMethods?.length === 0 ? (
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <TextBase
                style={{ opacity: 1 }}
                fontWeight={600}
                size={convertPxToRem(24)}
                color={Colors.black}>
                Get a Free trial
              </TextBase>
              <TextBase
                style={{ opacity: 1, textAlign: 'center' }}
                fontWeight={400}
                size={convertPxToRem(16)}
                color={Colors.black}>
                Add card to get free subscription until{'\n '}
                {dayjs()
                  .add(trialObj?.trial_period_days || 0, 'day')
                  .format('MMM D, YYYY')}
              </TextBase>
            </div>
          ) : null}

          {isLoading ? (
            <div style={{ height: '100px' }}>
              <LoadingBase />
            </div>
          ) : (
            <>
              <NewCardButton onClick={() => navigate('./add-card')} />

              {paymentMethods?.map((card) => {
                const cardDetails = card?.card;

                return (
                  <div
                    style={{ marginTop: '20px', width: '100%' }}
                    onClick={() =>
                      state?.price ? setSelected(card) : navigate('./edit-card', { state: card })
                    }>
                    <CreditCard
                      selected={selected?.djstripe_id === card?.djstripe_id && state?.price}
                      values={{
                        ...card?.card,
                        number: '**** **** **** ' + cardDetails?.last4,
                        month_exp: cardDetails?.exp_month,
                        year_exp: cardDetails?.exp_year,
                        brand: getCreditCardTypeString(cardDetails?.brand),
                        card_holder: card?.billing_details?.name
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}

          {paymentMethods?.length && state?.price ? (
            <>
              {trialObj?.is_trial_active ? (
                <TextBase
                  style={{ opacity: 1, textAlign: 'center' }}
                  fontWeight={400}
                  size={convertPxToRem(16)}
                  color={Colors.black}>
                  You won't be charged ${state?.price} until{'\n '}
                  {dayjs()
                    .add(trialObj?.trial_period_days || 0, 'day')
                    .format('MMM D, YYYY')}
                </TextBase>
              ) : null}
              <ButtonGradient
                onClick={handlePay}
                textStyle={{ fontWeight: 700, fontSize: 16 }}
                title={trialObj?.is_trial_active ? 'Start your trial' : `PAY $${state?.price}`}
                style={{ marginTop: '36px', width: '100%' }}
                loading={isCreatingSubscription}
              />
            </>
          ) : null}
        </div>
      </div>

      <ConfirmModal
        visible={showPaymentError.isVisible}
        status="danger"
        okBtnText="PAYMENTERROR"
        cancelBtnText="CANCEL"
        width="400px"
        showButtons={false}
        handleCancel={closeError}>
        <TextBase
          style={{
            color: Colors.red,
            textAlign: 'center',
            marginBottom: '15px'
          }}
          fontWeight={700}
          size={convertPxToRem(24)}
          color={Colors.titlePage}>
          Payment Failed
        </TextBase>
        <TextBase
          style={{
            color: Colors.black,
            textAlign: 'center',
            marginBottom: '15px'
          }}
          fontWeight={400}
          size={convertPxToRem(16)}
          color={Colors.titlePage}>
          {showPaymentError.message || 'we couldnt process the payment'}
        </TextBase>
        <ButtonGradient
          onClick={closeError}
          textStyle={{ fontWeight: 700, fontSize: 16 }}
          title={`TRY AGAIN`}
          style={{ marginTop: '36px', width: '100%' }}
          loading={isCreatingSubscription}
        />
      </ConfirmModal>
    </div>
  );
}

export default Payment;
