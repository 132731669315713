import moment from "moment";

export const TimeManager = {
  formatTime: (
    date = moment(),
    inputFormat = "",
    outputFormat = "DD-MM-YYYY HH:mm:ss.sss"
  ) => {
    return moment(date, inputFormat).format(outputFormat);
  },
  getCurrentTimeStamp: () => {
    return Date.now();
  },
  getCurrentTimeOffset: () => {
    return moment().utcOffset();
  },
};
