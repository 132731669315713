import React from 'react';
import BasePage from '../base';
import { css, StyleSheet } from 'aphrodite';
import {
  convertPxToRem,
  deepCopyObject,
  getUserId,
  getUserNameShow,
  getValueFromObjectByKeys,
  MESSAGE_TYPE,
  messageManager,
  myLog,
  renderCountries,
  renderStates
} from '../../helper';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { heightOfHeader, sizeOfBaseButton, userKey } from '../../utils/defined';
import { connect } from 'react-redux';
import withNavigate from '../../component/WithNavigate';
import { Col, Row, Switch } from 'antd';
import { SVGImageComponentReact } from '../../themes/Images';
import LoginInput from '../../common/login-input';
import ButtonGradient from '../../common/button-gradient';
import { UserAction } from '../../redux/action';
import BaseLayoutDropzone from '../../common/dropzone-base-layout';
import SelectCustom from '../../common/select-custom';
import DefaultAvatar from '../../assets/image/default-avatar.png';
import FormArea from '../../common/form-area';
import country from '../../utils/countries.json';
import OTPModal from '../../component/OTPModal';
import { styles } from './styles';
import { useParams } from 'react-router-dom';
import WithRouter from '../../component/withRouter';

// const countries = country;

const menuKey = {
  legal: 'legal',
  policy: 'policy',
  agreement: 'agreement',
  note: 'note'
};

class TeamProfile extends BasePage {
  constructor(props) {
    super(props);
    this.title = 'Settings';
    this.state = {
      inputsDisabled: true,
      showOTP: false,
      menuSelected: menuKey.policy,
      firstName: '',
      lastName: '',
      [userKey.firstName]: '',
      [userKey.lastName]: '',
      [userKey.email]: '',

      name: '',
      avatar: '',
      loadingUpdateData: false,
      profileImageFile: null,
      previewImage: '',
      description: '',
      businessEmail: '',
      phoneNumber: '',
      vendorPhone: '',
      vendorPhoneCode: '',
      businessName: '',
      taxID: '',
      country: '',
      state: '',
      city: '',
      zip: '',
      address: '',
      notificationEmail: false,
      notificationSms: false,
      dealerImageFile: null,
      previewDealerImage: '',
      dealerAvatar: '',
      phoneVerified: false,
      phoneFormatValid: false,
      userPhoneFormatValid: false,
      email: '',
      role: '',
      userPhone: '',
      userPhoneVerified: false
    };
    this.cacheUserInfo = {};
  }

  _componentDidMount = async () => {
    this.fetchData();
  };

  fetchData = async () => {
    const { id } = this.props;

    try {
      this.setState({
        loadingUpdateData: true
      });
      const { getTeamProfile } = this.props;
      const resProfile = await getTeamProfile(id);
      const userData = getValueFromObjectByKeys(resProfile, ['data']);
      const vendorData = userData?.vendor;
      console.log('userData', userData);
      this.saveDataUser(userData);
      this.setUserData(userData);
      this.setVendorData(vendorData);

      if (userData?.phone_number?.startsWith('+')) {
        this.setState({
          userPhoneFormatValid: true
        });
      } else {
        this.setState({
          userPhoneFormatValid: false
        });
      }

      this.setState({
        loadingUpdateData: false
      });
    } catch (error) {
      this.setState({
        loadingUpdateData: false
      });
      console.log('error u fetch', error);
      myLog('_componentDidMount Profile', error);
    }
  };

  onCountrySelect = (value) => {
    if (value !== 'US' || value !== 'CA') {
      this.setState({ state: undefined });
    }
    this.setState({
      country: value
    });
  };
  handleState = (key, value) => {
    // value.startsWith('+')
    if (key === 'userPhone') {
      if (value.startsWith('+')) {
        this.setState({
          userPhoneFormatValid: true
        });
      } else {
        this.setState({
          userPhoneFormatValid: false
        });
      }
    }
    console.log(key);
    this.setState({
      [key]: value
    });
  };

  onStateSelect = (value) => {
    this.setState({
      state: value
    });
  };

  setVendorData = (dealerData) => {
    console.log('set vendor', dealerData);
    this.setState({
      description: dealerData.description,
      businessEmail: dealerData.business_email,
      phoneNumber: dealerData.phone_number,
      vendorPhone: dealerData?.phone_number?.national_number,
      vendorPhoneCode: dealerData?.phone_number?.country_code || '1',
      businessName: dealerData.business_name,
      taxID: dealerData.tax_id,
      country: dealerData.country,
      state: dealerData.state,
      city: dealerData.city,
      zip: dealerData.zip,
      address: dealerData.address,
      notificationEmail: dealerData.notification_email,
      notificationSms: dealerData.notification_sms,
      phoneVerified: dealerData.phone_verified
    });
  };

  setUserData = (userData) => {
    this.setState({
      firstName: userData?.user?.first_name,
      lastName: userData?.user?.last_name,
      role: userData?.role,
      email: userData?.email,
      userPhone: userData?.phone_number?.national_number,
      userPhoneVerified: userData?.phone_verified
      // description: userData.description,
    });
  };

  saveDataUser = (userData = {}) => {
    const { userInfo } = this.props;
    this.cacheUserInfo = {
      avatar: userData[userKey.profile_image],
      name: getUserNameShow(userInfo)
    };
    this.setState({
      userInfo: deepCopyObject(this.cacheUserInfo),
      avatar: userData[userKey.profile_image],
      name: getUserNameShow(userInfo),
      profileImageFile: null,
      previewImage: ''
    });
  };

  updateProfile = async () => {
    const {
      firstName,
      lastName,
      email,
      userPhone,
      role,
      previewImage,
      profileImageFile,
      description,
      businessEmail,
      phoneNumber,
      businessName,
      taxID,
      country,
      state,
      city,
      zip,
      address,
      notificationEmail,
      notificationSms,
      avatar,
      dealerImageFile,
      previewDealerImage,
      dealerAvatar
    } = this.state;

    const { updateProfile, userInfo, vendor, updateVendor, id } = this.props;

    if (previewImage && profileImageFile) {
      console.log('ima profile image', previewImage);
      let formData = new FormData();
      formData.append([userKey.profile_image], profileImageFile);
      const resNewData = await updateProfile(formData, id);
    }

    // if (previewDealerImage && dealerImageFile) {
    //   let formData = new FormData();
    //   formData.append('logo', dealerImageFile);
    //   const resUpdateVendor = await updateVendor(formData, vendor.id);
    // }

    if (true) {
      this.setState({
        loadingUpdateData: true
      });
      try {
        const formData = {
          phone_number: userPhone,
          user: {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            role,
            email
          }
        };

        // const vendorFormData = {
        //   full_name: `${firstName} ${lastName}`,
        //   description,
        //   business_email: businessEmail,
        //   phone_number: phoneNumber,
        //   business_name: businessName,
        //   tax_id: taxID,
        //   country,
        //   city,
        //   zip,
        //   address,
        //   notification_email: notificationEmail,
        //   notification_sms: notificationSms
        // };
        // if (state) {
        //   vendorFormData.state = state;
        // }

        const resNewData = await updateProfile(formData, id, false);
        // const resUpdateVendor = await updateVendor(vendorFormData, vendor.id);

        const userData = getValueFromObjectByKeys(resNewData, ['data']);
        this.saveDataUser(userData);
        this.setState({
          loadingUpdateData: false
        });
        messageManager({
          type: MESSAGE_TYPE.success,
          messageShow: 'Update Success.'
        });
      } catch (error) {
        messageManager({
          type: MESSAGE_TYPE.error,
          messageShow: error.message || 'Update Failed.'
        });
        this.setState({
          loadingUpdateData: false
        });
      }
    }
  };

  onDrop = (files) => {
    myLog('onDropFile=>', files);
    this.setState({
      profileImageFile: files[0],
      previewImage: files[0].preview
    });
  };

  onDealerImageDrop = (files) => {
    this.setState({
      dealerImageFile: files[0],
      previewDealerImage: files[0].preview
    });
  };

  onReceiveByEmail = () => {
    this.setState({ notificationEmail: !this.state.notificationEmail });
  };

  onReceiveBySMS = () => {
    console.log(this.state.phoneVerified);
    if (this.state.phoneVerified) {
      this.setState({ notificationSms: !this.state.notificationSms });
    } else {
      this.setState({ notificationSms: false });
      messageManager({
        messageShow: 'Please verify your phone number',
        type: MESSAGE_TYPE.warning
      });
    }
  };

  getSMSCodeForUser = async () => {
    if (this.state.userPhoneFormatValid) {
      const { sendSMS } = this.props;

      try {
        await sendSMS();
        this.setState({
          showOTP: true
        });
      } catch (error) {
        console.log(error);
        this.setState({
          showOTP: false
        });
      }
    }
  };

  closeModal = () => {
    this.setState({
      showOTP: false
    });
  };

  renderContent() {
    const { navigate, userInfo } = this.props;
    const {
      firstName,
      lastName,
      description,
      businessEmail,
      phoneNumber,
      vendorPhone,
      businessName,
      taxID,
      country,
      state,
      city,
      zip,
      address,
      notificationEmail,
      notificationSms,
      name,
      avatar,
      loadingUpdateData,
      previewImage,
      profileImageFile,
      previewDealerImage,
      dealerImageFile,
      dealerAvatar,
      phoneFormatValid,
      userPhoneFormatValid,
      role,
      email,
      userPhone
    } = this.state;

    return (
      <div className={css(styles.container)}>
        <div className="flex-row-center">
          <TextBase fontWeight={600} size={convertPxToRem(24)} color={Colors.grey2}>
            My profile
          </TextBase>
        </div>
        <Row
          align="center"
          justify="center"
          style={{ marginTop: convertPxToRem(38) }}
          gutter={[25]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={18}
            style={{ paddingRight: convertPxToRem(25) }}
            span={16}>
            <div
              style={{
                padding: convertPxToRem(32),
                backgroundColor: Colors.white,
                borderRadius: convertPxToRem(10),
                boxShadow: `0px 1px 2px 1px rgba(0, 0, 0, 0.05)`
              }}>
              <div className={css(styles.header)}>
                <TextBase
                  fontWeight={600}
                  style={{ marginBottom: convertPxToRem(22) }}
                  size={convertPxToRem(18)}
                  color={Colors.gray2}>
                  Personal Information
                </TextBase>
                <div className={css(styles.divider)} />
              </div>

              <Row gutter={[30]}>
                {/* First Name */}
                <Col span={24}>
                  <div
                    style={{
                      width: convertPxToRem(120),
                      height: convertPxToRem(120),
                      borderRadius: convertPxToRem(139),
                      backgroundColor: Colors.white,
                      marginBottom: convertPxToRem(21),
                      border: `solid 2px ${Colors.white}`,
                      position: 'relative'
                    }}>
                    {previewImage || avatar ? (
                      <img
                        src={previewImage && profileImageFile ? previewImage : avatar}
                        // alt="avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: convertPxToRem(139)
                        }}
                      />
                    ) : (
                      <img
                        src={DefaultAvatar}
                        // alt="avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: convertPxToRem(139)
                        }}
                      />
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <TextBase size={16} fontWeight={400} color={Colors.black}>
                      First Name
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={firstName}
                      placeholder="Enter first name"
                      className="width-100"
                      style={{
                        width: '100%',
                        marginTop: convertPxToRem(6),
                        fontSize: convertPxToRem(16),
                        paddingLeft: convertPxToRem(16)
                      }}
                      onChange={(val) => this.handleState('firstName', val)}
                    />
                  </div>
                </Col>

                {/* Last Name */}
                <Col span={12}>
                  <div>
                    <TextBase size={16} fontWeight={400} color={Colors.black}>
                      Last Name
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={lastName}
                      placeholder="Enter last name"
                      className="width-100"
                      style={{
                        width: '100%',
                        marginTop: convertPxToRem(6),
                        fontSize: convertPxToRem(16),
                        paddingLeft: convertPxToRem(16),
                        color: Colors.black,
                        backgroundColor: Colors.white
                      }}
                      onChange={(val) => this.handleState('lastName', val)}
                    />
                  </div>
                </Col>
              </Row>

              {/*  TEAM MEMBER */}
              <Row gutter={30} style={{ marginTop: 20 }}>
                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      className="ellipsis"
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Email
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={email}
                      onChange={(val) => this.handleState('businessEmail', val)}
                      placeholder="Enter email"
                      noPaddingRight={true}
                      rightElement={
                        <ButtonGradient
                          textStyle={{ fontWeight: 600, fontSize: 17 }}
                          title="Verified"
                          backgroundColor="#10B981"
                          disabled
                          style={{
                            padding: 20,
                            height: '50px',
                            width: '100%',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: '-2px 0px 2px 0px #4040400D'
                          }}
                          // loading={loadingUpdateData}
                        />
                      }
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      className="ellipsis"
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Phone Number (SMS Notification for orders)
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={userPhone}
                      onChange={(val) => this.handleState('userPhone', val)}
                      placeholder="Enter phone number"
                      noPaddingRight={true}
                      rightElement={
                        <ButtonGradient
                          disabled={this.state.inputsDisabled}
                          className="verified-button"
                          // onClick={() => !this.state.userPhoneVerified && this.getSMSCodeForUser()}
                          textStyle={{ fontWeight: 600, fontSize: 17 }}
                          title={this.state.userPhoneVerified ? 'Verified' : 'Verify'}
                          backgroundColor={this.state.userPhoneVerified ? '#10B981' : null}
                          style={{
                            padding: 20,
                            height: '50px',
                            width: '100%',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            boxShadow: '-2px 0px 2px 0px #4040400D'
                          }}
                          // loading={loadingUpdateData}
                        />
                      }
                    />
                    {/*{!userPhoneFormatValid && (*/}
                    {/*  <TextBase*/}
                    {/*    style={{ textAlign: 'left' }}*/}
                    {/*    className="ellipsis"*/}
                    {/*    size={convertPxToRem(16)}*/}
                    {/*    color={Colors.red}>*/}
                    {/*    Please use number format with + as an country code*/}
                    {/*  </TextBase>*/}
                    {/*)}*/}
                  </div>
                </Col>
              </Row>

              <Row gutter={30} style={{ marginTop: 20 }}>
                {/* Role */}
                <Col span={12}>
                  <div>
                    <TextBase size={16} fontWeight={400} color={Colors.black}>
                      Role
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={role}
                      placeholder="Enter role"
                      className="width-100"
                      style={{
                        width: '100%',
                        marginTop: convertPxToRem(6),
                        fontSize: convertPxToRem(16),
                        paddingLeft: convertPxToRem(16),
                        color: Colors.black,
                        backgroundColor: Colors.white
                      }}
                      onChange={(val) => this.handleState('lastName', val)}
                    />
                  </div>
                </Col>
              </Row>

              {/* Notification settings */}
              <div className={css(styles.header, styles.spacingMd)}>
                <TextBase
                  fontWeight={600}
                  style={{ marginBottom: convertPxToRem(22) }}
                  size={convertPxToRem(18)}
                  color={Colors.gray2}>
                  Notification Settings
                </TextBase>
                <div className={css(styles.divider)} />
              </div>

              <Row>
                <Col span={12}>
                  <Row justify="space-between">
                    <Row>
                      <TextBase
                        fontWeight={400}
                        size={convertPxToRem(16)}
                        style={{ marginRight: convertPxToRem(16) }}
                        color={Colors.black}>
                        Receive by Email
                      </TextBase>

                      {!loadingUpdateData && (
                        <Switch
                          disabled={this.state.inputsDisabled}
                          defaultChecked={this.state.notificationEmail}
                          className={
                            this.state.notificationEmail
                              ? css(styles.switchEnabled)
                              : css(styles.switchDisabled)
                          }
                          onChange={this.onReceiveByEmail}
                        />
                      )}
                    </Row>

                    <Row>
                      <TextBase
                        fontWeight={400}
                        size={convertPxToRem(16)}
                        style={{ marginRight: convertPxToRem(16) }}
                        color={Colors.black}>
                        Receive by SMS
                      </TextBase>
                      {!loadingUpdateData && (
                        <Switch
                          disabled={this.state.inputsDisabled}
                          checked={this.state.notificationSms}
                          className={
                            notificationSms ? css(styles.switchEnabled) : css(styles.switchDisabled)
                          }
                          onChange={this.onReceiveBySMS}
                        />
                      )}
                    </Row>
                  </Row>
                </Col>
              </Row>

              {/* Business Info */}
              <div className={css(styles.header, styles.spacingMd)}>
                <TextBase
                  fontWeight={600}
                  style={{ marginBottom: convertPxToRem(22) }}
                  size={convertPxToRem(18)}
                  color={Colors.gray2}>
                  Business Information
                </TextBase>
                <div className={css(styles.divider)} />
              </div>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                {/* Dealer image */}
                <Col span={24}>
                  <div
                    style={{
                      width: convertPxToRem(120),
                      height: convertPxToRem(120),
                      borderRadius: convertPxToRem(139),
                      backgroundColor: Colors.white,
                      marginBottom: convertPxToRem(21),
                      border: `solid 2px ${Colors.white}`,
                      position: 'relative'
                    }}>
                    {previewDealerImage || dealerAvatar ? (
                      <img
                        src={
                          previewDealerImage && dealerImageFile ? previewDealerImage : dealerAvatar
                        }
                        // alt="avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: convertPxToRem(139)
                        }}
                      />
                    ) : (
                      <img
                        src={DefaultAvatar}
                        // alt="avatar"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: convertPxToRem(139)
                        }}
                      />
                    )}
                  </div>
                </Col>

                {/* Description*/}
                <Col span={24} style={{ marginBottom: 20 }}>
                  <div>
                    <TextBase size={16} fontWeight={400} color={Colors.black}>
                      Dealer Description
                    </TextBase>
                    <FormArea
                      disabled={this.state.inputsDisabled}
                      value={description}
                      placeholder="Enter description"
                      className="width-100"
                      style={{
                        width: '100%',
                        marginTop: convertPxToRem(6),
                        fontSize: convertPxToRem(16),
                        paddingLeft: convertPxToRem(16),
                        color: Colors.black,
                        backgroundColor: Colors.grey4
                      }}
                      onChange={(val) => this.handleState('description', val)}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      className="ellipsis"
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Business Email
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={businessEmail}
                      onChange={(val) => this.handleState('businessEmail', val)}
                      placeholder="Enter email"
                      noPaddingRight={true}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      className="ellipsis"
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Phone Number (SMS Notification for orders)
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={vendorPhone}
                      onChange={(val) => this.handleState('vendorPhone', val)}
                      placeholder="Enter phone number"
                      noPaddingRight={true}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      className="ellipsis"
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Business Name
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={businessName}
                      onChange={(val) => this.handleState('businessName', val)}
                      placeholder="Enter business name"
                      noPaddingRight={true}
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Tax ID:
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={taxID}
                      onChange={(val) => this.handleState('taxID', val)}
                      placeholder="Enter Tax ID"
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Headquarter Country
                    </TextBase>
                    <SelectCustom
                      disabled={this.state.inputsDisabled}
                      className="full-width"
                      value={country}
                      items={renderCountries()}
                      onSelect={this.onCountrySelect}
                    />
                  </div>
                </Col>

                {(country === 'US' || country === 'CA') && (
                  <Col span={12}>
                    <div>
                      <TextBase
                        style={{ textAlign: 'left' }}
                        size={convertPxToRem(16)}
                        color={Colors.black}>
                        State
                      </TextBase>
                      <SelectCustom
                        disabled={this.state.inputsDisabled}
                        value={state}
                        items={renderStates(country)}
                        onSelect={this.onStateSelect}
                      />
                    </div>
                  </Col>
                )}
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      City
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={city}
                      onChange={(val) => this.handleState('city', val)}
                      placeholder="Enter city"
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Zip
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={zip}
                      onChange={(val) => this.handleState('zip', val)}
                      placeholder="Enter  zip"
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
                <Col span={24}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      Address
                    </TextBase>
                    <LoginInput
                      disabled={this.state.inputsDisabled}
                      value={address}
                      onChange={(val) => this.handleState('address', val)}
                      placeholder="Enter address"
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={30}>
                <Col span={12}>
                  <div
                    className="flex-row-center-center cursor-pointer cancel-button"
                    style={{ marginTop: convertPxToRem(60), height: '3.5rem' }}>
                    <TextBase
                      size={convertPxToRem(16)}
                      fontWeight={700}
                      style={{ textAlign: 'center' }}
                      color={Colors.grey}>
                      CANCEL
                    </TextBase>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="flex-row-center-all" style={{ marginTop: convertPxToRem(60) }}>
                    <ButtonGradient
                      onClick={this.updateProfile}
                      textStyle={{ fontWeight: 700, fontSize: 16 }}
                      title="UPDATE"
                      style={{ width: '100%' }}
                      loading={loadingUpdateData}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <OTPModal
          fetchData={this.fetchData}
          getSMSCode={this.getSMSCodeForUser}
          showOTP={this.state.showOTP}
          phone={this.state.phoneNumber || ''}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notification'])
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (id) => {
      return dispatch(UserAction.getUserProfile(id));
    },
    getTeamProfile: (id) => {
      return dispatch(UserAction.getTeamProfile(id));
    },
    getVendor: (query) => {
      return dispatch(UserAction.getVendor(query));
    },
    updateProfile: (params, id, isFormData) => {
      return dispatch(UserAction.updateProfile(params, id, isFormData));
    },
    updateVendor: (params, id) => {
      return dispatch(UserAction.updateVendor(params, id));
    },
    sendSMS: (params, id) => {
      return dispatch(UserAction.sendSMS());
    },
    verifyPhoneNumber: (params, id) => {
      return dispatch(UserAction.updateVendor(params, id));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(TeamProfile));
