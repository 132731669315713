import './notifications.style.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserAction } from '../../redux/action';
import { SVGImageComponentReact } from '../../themes/Images';
import TextBase from '../../common/text';
import { convertPxToRem, myLog, translate } from '../../helper';
import { Colors } from '../../themes/Colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Avatar } from '@mui/material';
import ImageZoom from '../../common/image-zoom';
import { fetchNotesListApiCall } from '../../modules/notes/Action';
import Loader from '../../common/Loader';
import { formatTime } from '../../utils/CommonFunction';

const NotificationCard = ({ notification, markAsRead }) => {
  const navigate = useNavigate();
  const { data: notes } = useSelector(state => state.notesList_vendor);

  return <div
    className="card">
    {
      <span
        className={
          notification?.is_read
            ? 'notification-status notification-bubble-white'
            : 'notification-status notification-bubble-yellow'
        }></span>
    }
    <ImageZoom
      imageSrc={notification?.profile?.profile_image}
      altSrc={notification?.profile?.profile_image}
    >
      <Avatar
        src={notification?.profile?.profile_image}
        sx={{
          borderRadius: '5px'
        }}
      />
    </ImageZoom>
    <div className="notification-body"
         onClick={() => {
           if (notification.vendor_notes) {
             const noteDetails = notes.find(note => note.id === notification.vendor_note_id);
             navigate(`/notes-detail/${noteDetails?.id}`, { state: { scroll: true, note: noteDetails } });
             markAsRead(notification.id);
           } else {
             navigate(`/order-detail/${notification.order_id}`, { state: { scroll: true } });
             markAsRead(notification.id);
           }
         }}
    >
    <span className="notification-title">
      {notification.content}
    </span>
      <span className="notification-time">{formatTime(notification?.created)} </span>
    </div>
  </div>;
};

const Notifications = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { notifications } = useSelector(state => state.User);
  const { data: notes, loading } = useSelector(state => state.notesList_vendor);
  const showVendorNotes = location?.state?.vendor_notes;
  const label = translate(showVendorNotes ? 'Vendor Notes Notifications' : 'Notifications') ;


  useEffect(() => {
    dispatch(UserAction.getNotifications());
    fetchNotesListApiCall({}, dispatch);
  }, []);



  const markAsRead = async (id) => {
    try {
      await dispatch(UserAction.markAsRead(id));
    } catch (error) {
    }
    setTimeout(() => {
      dispatch(UserAction.getNotifications());
    }, 500);
  };

  const markAsReadAll = () => {
    dispatch(UserAction.markAsReadAll({
      vendor_notes: showVendorNotes,
    }));

    setTimeout(() => {
      dispatch(UserAction.getNotifications());
    }, 1000);
  };

  return (
    <div className="notifications-wrapper">
      {!loading && notifications?.length > 0 ? (
        <div className="scrollable-div">
          <h3 className="main-title">{label}</h3>

          <div className="mark-all-wrapper">
            <div className="mark-all" onClick={markAsReadAll}>
              <h3>{translate('markAllAsRead')}</h3>
              <div className="mark-all-svg">
                <SVGImageComponentReact.IconChecked />
              </div>
            </div>
          </div>
          {notifications?.filter(notification => notification.vendor_notes === showVendorNotes)?.map((notification) => (
            notification.id && (
              <NotificationCard notification={notification} markAsRead={markAsRead}/>)
          ))}
        </div>
      ) : (
        !loading && notifications?.length <= 0 ? (
          <div style={{ position: 'absolute', top: '55%', left: '45%', transform: translate('-50%', '-50%') }}>
            <TextBase
              size={convertPxToRem(16)}
              color={Colors.grey2}
              fontWeight={500}
              style={{ marginBottom: 20 }}>
              noNotifications
            </TextBase>
          </div>
        ) : (
          <div style={{ position: 'absolute', top: '55%', left: '45%', transform: translate('-50%', '-50%') }}>
            <Loader />
          </div>
        )
      )}
    </div>
  );
};

export default Notifications;
