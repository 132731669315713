import React from 'react';
import { Modal } from 'antd';
import WarningIcon from '../../assets/image/svg/warning.svg';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import './index.css';
import { MenuApp } from './menu';
import { Col, Dropdown, Layout, Menu, Row } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LocalStorage from '../../defined/localStorage';
import { Colors } from '../../themes/Colors';
import { SVGImageComponentReact } from '../../themes/Images';
import { ROUTER_KEY, ROUTER_NAME } from '../../router/routers';
import {
  convertPxToRem,
  getUserId,
  getUserProfileByKey,
  getValueFromObjectByKeys,
  myLog,
  reloadApp,
  translate
} from '../../helper';
import { heightOfHeader, notificationType } from '../../utils/defined';
import withNavigate from '../../component/WithNavigate';
import { UserAction } from '../../redux/action';
import { css, StyleSheet } from 'aphrodite';
import { collection, onSnapshot } from 'firebase/firestore';
import { collectionKeys, db } from '../../firebase';
import moment from 'moment';
import ChangePassword from '../../component/ChangePassword';
import ReconnectingWebSocket from 'reconnecting-websocket';
import i18n from '../../I18';
import { Badge, CircularProgress, Dialog, DialogContent, DialogTitle, MenuItem, Select, Typography, styled } from '@mui/material';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Utils from '../../utils';
import TextBase from '../../common/text';
import ButtonGradient from '../../common/button-gradient';
import poweredByLogo from "../../assets/image/png/PoweredByMove_logo-100.jpg"

const brand = JSON.parse(LocalStorage.getItem('branding'))

const { Header, Content, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const allKeyMenu = [];
const keySubMenu = 'subMenu-';

const styles = StyleSheet.create({
  iconNotification: {
    width: convertPxToRem(26),
    height: convertPxToRem(29),
    marginRight: convertPxToRem(20),
    cursor: 'pointer'
  },
  iconNoNotification: {
    width: convertPxToRem(32),
    height: convertPxToRem(28),
    marginRight: convertPxToRem(5),
    cursor: 'pointer'
  },
  iconUser: {
    width: convertPxToRem(40),
    height: convertPxToRem(40),
    borderRadius: convertPxToRem(20),
    marginLeft: convertPxToRem(15),
    marginRight: convertPxToRem(10),
    border: `solid 2px #F2F2F2`
  }
});

const Title = ({ children, disabled }) => {
  const { t } = useTranslation()
  return (
    <>
      {
        disabled ? (
          <span className="nav-text" style={{ color: 'grey' }}>{t(children)}</span>
        ) : (
          <span className="nav-text">{t(children)}</span>
        )
      }
    </>
  )
}

class LayoutApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNotifications: false,
      selectedKeys: ['dashboard'],
      openKeys: [],
      collapsed: window.outerWidth <= 768,
      showChangePassword: false,
      openLanguageSelector: false,
      openLogoutModal: false,
    };
    this.socket = null;
  }

  closeChangePasswordModal = () => {
    this.setState({
      showChangePassword: false
    });
  };

  handleCloseLogoutModal = () => {
    this.setState({
      openLogoutModal: false
    })
  }

  formatMenu = (menu = []) => {
    const { userInfo } = this.props;
    const isSuperUser = userInfo?.profile?.user?.is_superuser||false;
    const teamMenu = menu.filter((menu) => {
      return !menu?.isAdminOnly;
    });
    if (isSuperUser) {
      return menu.filter(item => item.isSuperUser).map((item) => ({ ...item, route: item.route }));
    } else
    if (userInfo?.profile?.team_member) {
      return teamMenu.map((item) => ({ ...item, route: item.route }));
    } else {
      const nonParentAccountMenu = menu.filter((menu) => {
        return menu?.isChildBranding ? userInfo?.profile?.manage_branding && !userInfo?.profile?.branding_locked : !menu?.isParentAccountOnly;
      });
      if (userInfo?.profile?.user?.role == 'White Label Partner Admin') {
        return menu.map((item) => ({ ...item, route: item.route }));
      }
      return nonParentAccountMenu.map((item) => ({ ...item, route: item.route }));
    }
  };
  checkIsDetailDataWithKey = (key) => { };
  getMenu = (menu = [], route) => {
    return menu.map((itemMenu, index) => {
      if (!itemMenu.hide) {
        if (itemMenu.items) {
          const key = route
            ? keySubMenu + route + '-' + itemMenu.route
            : keySubMenu + itemMenu.route;
          if (
            itemMenu.items.length !== itemMenu.items.filter((elem) => elem.hide === true).length
          ) {
            return (
              <SubMenu key={key} title={<span>{itemMenu.title}</span>}>
                {this.getMenu(itemMenu.items, itemMenu.route)}
              </SubMenu>
            );
          }
        } else {
          const IconMenu = itemMenu.icon;
          const IconMenuActive = itemMenu.icon_active;
          const isMenuActive = this.state.selectedKeys[0].includes(itemMenu.route);
          const IconFinal = isMenuActive ? (IconMenuActive ? IconMenuActive : IconMenu) : IconMenu;

          return (
            <Menu.Item
              className={'item-menu' + (route ? ' item-sub-menu' : '')}
              key={'/' + itemMenu.route}>
              {itemMenu.route === ROUTER_KEY.logout ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <div
                    onClick={this.logout}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: convertPxToRem(35)
                    }}>
                    {IconFinal ? <IconFinal style={{ marginRight: convertPxToRem(17) }} /> : null}
                    <Title>{itemMenu.title}</Title>
                  </div>
                </div>
              ) : (
                <Link
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  to={'/' + itemMenu.route}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: convertPxToRem(35)
                    }}>
                    {IconFinal ? <IconFinal style={{ marginRight: convertPxToRem(17) }} /> : null}
                    <Title>{itemMenu.title}</Title>
                  </div>
                </Link>
              )}
            </Menu.Item>
          );
        }
      }
    });
  };
  getAllKeyMenu = (menu = [], route) => {
    menu.map((itemMenu, index) => {
      if (!itemMenu.hide) {
        if (itemMenu.items) {
          if (
            itemMenu.items.length !== itemMenu.items.filter((elem) => elem.hide === true).length
          ) {
            this.getAllKeyMenu(itemMenu.items, itemMenu.route);
          }
        } else {
          if (allKeyMenu.indexOf(itemMenu.route) === -1) {
            allKeyMenu.push(itemMenu.route);
          }
        }
      }
    });
    return allKeyMenu;
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  selectMenu = (select) => {
    this.setState({
      selectedKeys: select.selectedKeys
    });
  };
  listenNotification = async () => {
    const { userInfo } = this.props;
    myLog('userId=>', getUserId(userInfo));
    try {
      const notiCollection = collection(
        db,
        collectionKeys.notifications,
        `${getUserId(userInfo)}`,
        collectionKeys.notifications
      );
      onSnapshot(notiCollection, (snapshot) => {
        myLog('onSnapshot=>', snapshot);
        let listNoti = [];
        snapshot.forEach((item) => {
          myLog('itemMessage=>', item.data());
          const itemNoti = item.data();
          myLog('itemNoti=>', itemNoti);
          listNoti.push({ ...itemNoti, id: item.id });
        });
        listNoti = listNoti
          .filter((x) =>
            [
              notificationType.sendOrder,
              notificationType.acceptedQuote,
              notificationType.declineQuote,
              notificationType.quoteMessage,
              notificationType.respondQuote
            ].includes(x?.type)
          )
          .sort((a, b) => b.created_at - a.created_at);
        this.props.saveUserNotification(listNoti);
      });
    } catch (error) {
      myLog('onSnapshot=>error', error);
    }
  };

  showOverflow = () => {
    const routeUrl = this.props?.location?.pathname;
    return !(
      (routeUrl === '/privacy-policy' || routeUrl === '/terms-and-conditions')
    );
  };

  async componentDidMount() {
    this.listenNotification()
    this.getSelectedKeyAndOpenKey();

    const { userInfo, getUserProfile, getVendor, getNotifications } = this.props;
    getNotifications();
    this.getUpdates();
    if (userInfo && LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN)) {
      const userId = getValueFromObjectByKeys(userInfo, ['user', 'id']);
      getUserProfile && getUserProfile(userId);
      getVendor({ user: userId });
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }

  getUpdates = () => {
    const { userInfo: { token } } = this.props;
    this.socket = new ReconnectingWebSocket(
      `wss://${Utils.constants.API_URL.replace('https://', '')}ws/home/notifications/?token=${token}`
    );
    this.socket.addEventListener('open', () => {
      this.props.getNotifications();
    });

    this.socket.onmessage = (event) => {
      this.props.getNotifications();
    };
  };

  componentWillReceiveProps(nextProps) {
    this.getSelectedKeyAndOpenKey();
  }

  getSelectedKeyAndOpenKey = () => {
    let hash = window.location.pathname;
    let parentKeys = [keySubMenu + hash.split('/')[1]];
    if (hash.split('/').length > 3) {
      parentKeys = [
        keySubMenu + hash.split('/')[1],
        keySubMenu + hash.split('/')[1] + '/' + hash.split('/')[2]
      ];
    }
    hash = hash.indexOf('?') !== -1 ? hash.split('?')[0] : hash;
    let hashFormat = hash;
    if (allKeyMenu.indexOf(hashFormat) === -1) {
      let arrHashFormat = hashFormat.split('/');
      if (arrHashFormat.length > 1) {
        allKeyMenu.map((keyMenu) => {
          if (
            hashFormat.indexOf('/list') === -1 &&
            keyMenu.indexOf(hashFormat.replace(arrHashFormat[arrHashFormat.length - 1], '')) !== -1
          ) {
            hash = '/' + keyMenu;
          }
        });
      }
    }

    if (hash === '/order-detail') {
      hash = '/order';
    }
    if (hash === '/create-profile' || hash === '/privacy' || hash === '/terms') {
      hash = '/settings';
    }
    this.setState({
      selectedKeys: [hash],
      openKeys: parentKeys
    });
  };
  logout = async () => {
    try {
      localStorage.clear();
      this.props.navigate('/login');
    } catch (error) {
      reloadApp();
    }
  };

  render() {
    const menuUser = (
      <Menu
        className="dropdown-logout"
        items={[
          {
            label: translate('myProfile'),
            key: '1',
            onClick: () => {
              this.props.navigate && this.props.navigate(ROUTER_NAME.createProfile);
            },
            className: 'item-dropdown-user menu-text'
          },
          {
            label: translate('changePassword'),
            key: '2',
            onClick: () => {
              this.setState({
                showChangePassword: true
              });
            },
            className: 'item-dropdown-user menu-text'
          },
          {
            label: translate('privacyPolicy'),
            key: '3',
            onClick: () => {
              this.props.navigate && this.props.navigate(ROUTER_NAME.privacyPrivate);
            },
            className: 'item-dropdown-user menu-text'
          },
          {
            label: translate('termsAndCondition'),
            key: '4',
            onClick: () => {
              this.props.navigate && this.props.navigate(ROUTER_NAME.termsPrivate);
            },
            className: 'item-dropdown-user menu-text'
          },
          {
            label: translate('chooseLanguage'),
            key: '5',
            onClick: () => {
              this.setState({
                openLanguageSelector: true
              })
            },
            className: 'item-dropdown-user menu-text'
          },
          {
            label: translate('logout'),
            key: '6',
            className: 'item-dropdown-user menu-text',
            onClick: () => {
              this.setState({
                openLogoutModal: true
              })
            
            }
          }
        ]}
      />
    );
    const { selectedKeys, openKeys, collapsed } = this.state;
    myLog('collapsed=>', collapsed);
    const { userDetail, listNotification = [], userInfo, notificationsObj } = this.props;
    const numberNotificationNew = listNotification.filter((x) => !x.isReaded).length;
    myLog('selectedKeys=>', selectedKeys);
    myLog('openKeys=>', openKeys);

    const menuApp = this.getMenu(this.formatMenu(MenuApp()));
    const { whitelabel } = this.props
    return (
      <div>
        {
          this.state.openLanguageSelector && (
            <LanguageSelectionModal
              isOpen={this.state.openLanguageSelector}
              onClose={() => {
                this.setState({
                  openLanguageSelector: false
                })
              }}
            />
          )
        }
        <Layout
          style={{
            backgroundSize: '100%',
            minHeight: '100vh',
            backgroundPosition: 'bottom',
            position: 'relative',
            zIndex: 2,
            backgroundColor: whitelabel?.web_background_color || brand?.web_background_color || Colors.bgSider,
          }}>
          <Sider
            style={{
              overflow: 'auto',
              height: 'calc(100vh - 4rem)',
              position: 'fixed',
              left: 0,
              zIndex: 9999,
              backgroundColor: "#fff",
              top: "4rem",
            }}
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            trigger={null}
            className={collapsed ? '' : 'menu-cms'}
            onBreakpoint={(broken) => {
              myLog('onBreakpoint:', broken);
            }}
            onCollapse={(collapsed, type) => {
              myLog('onCollapse:', collapsed, type);
              this.setState({
                collapsed
              });
            }}>
            <div style={{height: "calc(100vh - 4rem)", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <Link to={ROUTER_NAME.dashboard} style={{background: whitelabel?.web_side_banner_color || brand?.web_side_banner_color || Colors.bgSider}}>
                <div className="logo">
                  {
                    (whitelabel?.web_logo || brand?.web_logo) ?
                      <LazyLoadImage src={whitelabel?.web_logo || brand?.web_logo}
                        style={{
                          minHeight: "75px",
                          maxHeight: "100px",
                          width: "auto"
                        }}
                        alt="logo"
                        placeholder={<CircularProgress />}
                      /> : <SVGImageComponentReact.LogoMoveMenu />
                  }
                </div>
              </Link>
              <div style={{height: "100%", background: whitelabel?.web_side_banner_color || brand?.web_side_banner_color || Colors.bgSider, borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}>
                <Menu
                  onSelect={(params) => {
                    this.setState({
                      selectedKeys: [params.key]
                    });
                  }}
                  onOpenChange={(openKeys) => {
                    this.setState({
                      openKeys
                    });
                  }}
                  theme="dark"
                  mode="inline"
                  openKeys={openKeys}
                  selectedKeys={selectedKeys}>
                  {menuApp}
                </Menu> 
              </div>
              <div
                style={{
                  width: '100%',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "#fff"
                }}
              >
                <img 
                  src={poweredByLogo} 
                  style={{
                    width: '80%',
                  }}
                  alt="powered-by" 
                />
              </div>
            </div>
          </Sider>
          <Layout
            style={{
              overflowY: this.showOverflow() ? 'auto' : 'hidden',
              marginLeft: collapsed ? 0 : '0px',
              backgroundColor: Colors.transparent,
              maxHeight: '100vh'
            }}>
            <Header
              style={{
                backgroundColor: whitelabel?.web_top_banner_color || brand?.web_top_banner_color || Colors.bgSider,
                padding: 0,
                minHeight: heightOfHeader,
                height: 'max-content',
                position: 'fixed',
                width: '100%',
                top: 0,
                zIndex: 9,
              }}
              id="header-private">
              <Row className="height-100">
                <Col className="txt-left" span={8} xs={24} sm={24} md={8}>
                  <div
                    style={{ paddingLeft: convertPxToRem(10) }}
                    onClick={() => {
                      this.setState({
                        collapsed: !collapsed
                      });
                    }}
                    className="flex-row-center height-100">
                    {collapsed ? (
                      <MenuUnfoldOutlined
                        style={{
                          fontSize: convertPxToRem(24),
                          color: Colors.white
                        }}
                      />
                    ) : (
                      <MenuFoldOutlined
                        style={{
                          fontSize: convertPxToRem(24),
                          color: Colors.white
                        }}
                      />
                    )}
                    <Typography
                      variant='p'
                      fontWeight={'600'}
                      color={'#fff'}
                      fontSize={'16px'}
                      sx={{ paddingLeft: convertPxToRem(30), textTransform: 'capitalize' }}
                      onClick={(evt) => evt.stopPropagation()}
                    >
                      {userInfo?.user?.vendor?.business_name || 'N/A'}
                    </Typography>
                  </div>

                </Col>
                <Col className="wrap-current-user" span={16} xs={24} sm={24} md={16}>
                  <Link className="flex relative" to={ROUTER_NAME.notification}  state={{ vendor_notes: true }} style={{ display: 'flwx', alignItems: 'flex-start', marginRight: '10px' }}>
                    <Badge badgeContent={notificationsObj?.length > 0 ? notificationsObj[notificationsObj?.length - 1].unread_vendornote_count : undefined} color="primary">
                      <SVGImageComponentReact.IconNotes
                        className={css(styles.iconNoNotification)}
                      />
                    </Badge>
                  </Link>
                  <Link className="flex relative" to={ROUTER_NAME.notification}  state={{ vendor_notes: false }} style={{ display: 'flwx', alignItems: 'center', marginRight: '20px' }}>
                    <Badge badgeContent={notificationsObj?.length > 0 ? notificationsObj[notificationsObj?.length - 2].unread_count : undefined} color="primary">
                      <SVGImageComponentReact.NoNotificationsIcon
                        className={css(styles.iconNoNotification)}
                      />
                    </Badge>
                    <Typography variant='p' color={'white'}>Notifications</Typography>
                  </Link>
                  <div className="vertical-divider"></div>
                  <Dropdown
                    className="container-dropdown-logout cursor-pointer"
                    overlay={menuUser}
                    trigger={['click']}>
                    <div className="user-dropdown-link">
                      <div className="layout-username" style={{ display: "flex" }}>
                        <div className="flex-column">
                          <span className="header-user-name">
                            {getUserProfileByKey(userInfo, 'first_name')
                              ? getUserProfileByKey(userInfo, 'first_name') +
                              (' ' + getUserProfileByKey(userInfo, 'last_name') || '')
                              : getUserProfileByKey(userInfo, 'name') || ''}
                          </span>
                          <span className="header-user-email">
                            {getUserProfileByKey(userInfo, 'email') || 'Email'}
                          </span>
                        </div>
                        {userInfo?.profile?.profile_image ? (
                          <img
                            src={userInfo?.profile?.profile_image}
                            className={css(styles.iconUser)}
                            alt="avatar"
                          />
                        ) : (
                          <SVGImageComponentReact.IconUser className={css(styles.iconUser)} />
                        )}
                        <SVGImageComponentReact.IconDropDown
                          style={{ marginRight: convertPxToRem(25) }}
                        />
                      </div>
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            </Header>
            <Content
              id={LocalStorage.DEFINE_KEY.MAIN_APP_ID}
              className={'content-main'}
              style={{
                backgroundColor: "#fff",
                marginLeft: collapsed ? 0 : '251px',
                marginTop:'4rem',
              }}>
              {this.props.children}
              <ChangePassword
                showChangePassword={this.state.showChangePassword}
                closeModal={this.closeChangePasswordModal}
              />
            </Content>
          </Layout>
        </Layout>
        <LogoutModal 
          visible={this.state.openLogoutModal}
          handleCancel={this.handleCloseLogoutModal}
          handleOk={() => {
            this.logout()
            this.handleCloseLogoutModal()
          }}
        />
      </div>
    );
  }
}

const LanguageSelectionWrapper = styled('div')(({ theme }) => ({
  '& .MuiInputBase-root': {
    'width': '100%'
  }
}))


const LanguageSelectionModal = ({ isOpen, onClose }) => {

  const [language, setLanguage] = React.useState('');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>{translate('chooseLanguage')}</DialogTitle>
      <DialogContent>
        <LanguageSelectionWrapper>
          <Select
            displayEmpty
            onChange={(evt) => {
              setTimeout(() => {
                i18n.changeLanguage(evt.target.value, () => {
                  window.location.reload();
                })
                setLanguage(evt.target.value);
              }, 500);
            }}
            value={language}
            id='languages'
            renderValue={
              language !== ''
                ? undefined
                : () => <span style={{ color: '#ababab' }}>{translate('selectLanguage')}</span>
            }
          >
            <MenuItem value={'en'}>English</MenuItem>
            <MenuItem value={'es'}>Spanish</MenuItem>
          </Select>
        </LanguageSelectionWrapper>
      </DialogContent>
    </Dialog>
  )
}

const LogoutModal = ({
  visible,
  width = 350,
  handleCancel,
  handleOk
}) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return (
    <Modal visible={visible} footer={<div />} centered closeIcon={<div />} style={{}} width={width}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px'
        }}>
          <img
            src={
              WarningIcon
            }
            style={{ height: '100px', width: '100px' }}
          />
      </div>
        <TextBase
            style={{
              color: Colors.black,
              textAlign: 'center',
              marginBottom: '20px'
            }}
            fontWeight={400}
            size={convertPxToRem(18)}
            color={Colors.titlePage}>
            {'logoutConfirmation'}
          </TextBase>
        <TextBase
            style={{
              color: Colors.black,
              textAlign: 'center',
              marginBottom: '20px'
            }}
            fontWeight={700}
            size={convertPxToRem(18)}
            color={Colors.titlePage}>
            {'sureConfirmation'}
          </TextBase>
        <div style={{ display: 'flex' }}>
          <ButtonGradient
            onClick={handleCancel}
            textStyle={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '25px',
              margin: 0,
              color: Colors.grey
            }}
            title={translate('cancel')}
            iconSize={24}
            backgroundColor={Colors.white}
            style={{
              marginRight: '10px',
              padding: '0px 10px',
              border: `1px solid ${Colors.grey}`,
              color: Colors.grey,
              textTransform: 'uppercase'
            }}
          />
          <ButtonGradient
            onClick={handleOk}
            textStyle={{ fontWeight: 700, fontSize: '18px', lineHeight: '20px', margin: 0 }}
            iconStyle={{}}
            title={translate('ok')}
            iconSize={24}
            backgroundColor={whiteLabel?.web_button_color || brand?.web_button_color || Colors.black}
            style={{
              marginLeft: '10px',
              textTransform: 'uppercase'
            }}
          />
        </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notifications', 'results']),
    notificationsObj: getValueFromObjectByKeys(state, ['User', 'notifications']),
    notifications: state.notifications,
    whitelabel: getValueFromObjectByKeys(state, ['User', 'branding']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (id) => {
      return dispatch(UserAction.getUserProfile(id));
    },
    saveUserNotification: (data) => {
      return dispatch(UserAction.saveUserNotification(data));
    },
    getVendor: (query) => {
      return dispatch(UserAction.getVendor(query));
    },
    getNotifications: () => {
      return dispatch(UserAction.getNotifications());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(LayoutApp));
