import { request } from '../connection/ApiDefine';

function getConnectedAccount(payload) {
  return request
    .get(`stripe/connected-account/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log('error=>', error);
      throw error;
    });
}

function connectStripe(payload) {
  return request
    .post(`stripe/connected-account/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log('error=>', error?.response);
      throw error;
    });
}

function disconnectStripe() {
  return request
    .delete(`stripe/connected-account/deauthorize/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log('error=>', error?.response);
      throw error;
    });
}

function getPaymentMethods(payload) {
  return request
    .get('stripe/payment-methods/')
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

function getPaymentMethod(payload) {
  return request
    .get(`stripe/payment-methods/${payload?.id}/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

function addPaymentMethod(payload) {
  return request
    .post('stripe/payment-methods/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

function updatePaymentMethod(payload) {
  return request
    .patch(`stripe/payment-methods/${payload?.id}/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

function deletePaymentMethod(payload) {
  return request
    .delete(`stripe/payment-methods/${payload.djstripe_id}/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

export const paymentAPIService = {
  getConnectedAccount,
  disconnectStripe,
  connectStripe,
  getPaymentMethods,
  getPaymentMethod,
  updatePaymentMethod,
  addPaymentMethod,
  deletePaymentMethod
};
