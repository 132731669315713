import React from 'react';
import { connect } from 'react-redux';
import { ROUTER_NAME } from '../../router/routers';
import LoginInput from '../../common/login-input';
import BasePage from '../base';
import withNavigate from '../../component/WithNavigate';
import {
  convertPxToRem,
  getValueFromObjectByKeys,
  MESSAGE_TYPE,
  messageManager,
  myLog,
  validateEmail
} from '../../helper';
import { UserAction } from '../../redux/action';
import { animate } from '../../utils/defined';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import ButtonGradient from '../../common/button-gradient';
import { motion } from 'framer-motion';
import { Col, Row } from 'antd';
import { css, StyleSheet } from 'aphrodite';
import { SVGImageComponentReact } from '../../themes/Images';
import InputError from '../../common/input-error/InputError.component';

const userFake = {
  username: 'admin',
  password: 'admin'
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginTop: 20
  }
});

class Login extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      isLogin: false,
      sending: false,
      errors: {}
    };
  }

  onChangeEmail = (value) => {
    this.setState({ email: value });
  };
  onChangePassword = (value) => {
    this.setState({ password: value });
  };
  handlerLogin = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (this.state.loging) return;
    try {
      const { email, password } = this.state;
      const { navigate, saveUserInfo, userInfo } = this.props;
      if (!email || !password) {
        messageManager({
          messageShow: 'Please fill input value!',
          type: MESSAGE_TYPE.warning
        });
        return;
      }
      if ((email !== userFake.username || password !== userFake.password) && false) {
        alert('Account or password incorrect!');
        return;
      } else {
        this.setState({ loging: true });
        console.log("adding credentials");
        const resLogin = await this.props.login({
          email: email,
          password
        });
        myLog('resLogin=>', resLogin);
        this.setState({ loging: false });
        messageManager({
          type: MESSAGE_TYPE.success,
          messageShow: 'Login Success'
        });
        navigate(ROUTER_NAME.dashboard);
      }
    } catch (error) {
      myLog('resLogin=>errr', error);
      this.setState({ loging: false });
      // messageManager({
      //   type: MESSAGE_TYPE.error,
      //   messageShow: error.message || "Login Failed!",
      // });
    }
  };
  handlerResetPassword = async (event) => {
    const errors = this.state.errors;

    if (!this.state.email) {
      errors.email = ['emailError'];
      this.setState({
        errors
      });
    } else if (!validateEmail(this.state.email)) {
      errors.email = ['emailValidationError'];
      this.setState({
        errors
      });
    } else {
      errors.mail = [];
      this.setState({ errors });
    }

    if (validateEmail(this.state.email)) {
      this.setState({ errors: {} });
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      if (this.state.sending) return;
      try {
        const { email } = this.state;
        const { navigate, saveUserInfo, userInfo, forgotPassword } = this.props;
        if (!email) {
          messageManager({
            messageShow: 'emailIsRequired',
            type: MESSAGE_TYPE.warning
          });
          return;
        }
        this.setState({ sending: true });
        const resReset = await forgotPassword({
          email: email
        });
        this.setState({ sending: false });

        messageManager({
          type: MESSAGE_TYPE.success,
          messageShow:
            getValueFromObjectByKeys(resReset, ['data', 'detail']) ||
            'mailSentAlert'
        });
        navigate(ROUTER_NAME.login);
      } catch (error) {
        this.setState({ sending: false });
        messageManager({
          type: MESSAGE_TYPE.error,
          messageShow: error.message || 'Send email failed!'
        });
      }
    } else {
    }
  };

  renderContent() {
    myLog('renderContent=>', this.props);
    const { email, password, showPassword, isLogin, loging, errors, sending } = this.state;
    const { navigate } = this.props;
    return (
      <motion.div
        initial={animate.initial}
        animate={animate.animate}
        transition={animate.transition}>
        <div className={css(styles.flex)}>
          <div
            className="flex-row-center-center cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}>
            <SVGImageComponentReact.IconArrowBack />
          </div>
          <div></div>
        </div>
        <div
          className="max-width-100"
          style={{
            paddingTop: convertPxToRem(100),
            paddingInline: convertPxToRem(20),
            paddingBottom: convertPxToRem(20)
          }}>
          <TextBase
            style={{ marginBottom: convertPxToRem(11) }}
            fontWeight={600}
            size={convertPxToRem(32)}
            color={Colors.black}>
            forgotPassword
          </TextBase>
          <TextBase size={convertPxToRem(18)} color={Colors.grey2}>
          forgotPasswordSettinUp
          </TextBase>
          <TextBase size={convertPxToRem(18)} color={Colors.grey2}>
          forgotPasswordSettinUp_2
          </TextBase>

          <div className="form-wrapper">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 40 }}>
              <Col span={24}>
                <TextBase
                  style={{ textAlign: 'left' }}
                  size={convertPxToRem(18)}
                  color={Colors.black}>
                  businessEmail
                </TextBase>
                <LoginInput
                  value={email}
                  onChange={this.onChangeEmail}
                  placeholder="enterEmail"
                  disabled={sending}
                />
                <InputError error={errors.email} />
              </Col>
            </Row>
          </div>
          <div
            style={{ marginBottom: 19, marginTop: 50 }}
            className="flex-center-all max-width-100">
            <ButtonGradient
              onClick={this.handlerResetPassword}
              textStyle={{ fontWeight: 700 }}
              title="reset"
              loading={sending}
            />
          </div>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo'])
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => {
      return dispatch(UserAction.login(params));
    },
    forgotPassword: (params) => {
      return dispatch(UserAction.forgotPassword(params));
    },
    saveUserInfo: (data) => {
      return dispatch(UserAction.saveUserInfo(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Login));
