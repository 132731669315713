import { StyleSheet } from "aphrodite";
import { heightOfHeader, sizeOfBaseButton, userKey } from '../../utils/defined'
import { convertPxToRem } from "../../helper";
import { Colors } from "../../themes/Colors";

const heightOfBottomBlackAlpha = convertPxToRem(284);

export const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(24),
    paddingLeft: convertPxToRem(32),
    backgroundColor: Colors.white,
    paddingBottom: convertPxToRem(82)
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: '100%',
    minWidth: '100vw',
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    // position: "fixed",
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    position: 'absolute'
  },
  bgOverlay: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // height: `calc(100vh - ${heightOfHeader} - ${heightOfBottomBlackAlpha})`,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)',
    zIndex: 1,
    overflow: 'hidden'
  },
  wrapText: {
    position: 'relative',
    zIndex: 4,
    maxWidth: '50%',
    height: '100%',
    minHeight: convertPxToRem(400),
    // paddingBottom: convertPxToRem(99),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  // TODO ADD MEDIA QUERY
  divider: {
    borderTop: '1px solid #E5E7EB',
    marginTop: 16,
    width: '78%',
    '@media (max-width: 1550px)': {
      width: '75%',
    },
    '@media (max-width: 1400px)': {
      width: '70%',
    },
    '@media (max-width: 1300px)': {
      width: '65%',
    }
  },
  spacingMd: {
    marginTop: 32
  },
  switchEnabled: {
    background: '#0062FF !important'
  },
  switchDisabled: {
    background: '#E5E7EB !important'
  },
});