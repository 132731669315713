import { request } from '../connection/ApiDefine';

function generateDealerCode(payload) {
  return request
    .post('vendors/my-vendor/generate-dealer-code/', payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

function lockDealerCode(payload) {
  return request
    .patch(`vendors/my-vendor/lock-dealer-code/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      throw error;
    });
}

export const vendorAPIService = {
  lockDealerCode,
  generateDealerCode
};
