import Utils from "../../../utils";
import { successChatsReducer, loaderListener, failedChatsReducer } from '../Slice/getChatSlice';
import { successCreateRoomReducer, loaderListener as roomLoaderListener, failedCreateRoomReducer } from '../Slice/createRoomSlice';
import { signInWithCustomToken } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import APIManager from "../../../connection/ApiManager";
import { auth, firebaseConfig } from '../../../firebase';

export const sendMessageApiCall = (values, setSubmitting, refetch) => {
    const dataToSend = values;

    Utils.api.postApiCall(
        `chats/firestore/order-rooms/detail/${values?.order_id}/send-message/${values?.vendor_id}/`,
        dataToSend,
        (respData) => {
            const { data } = respData
            Utils.showAlert(1, 'Successfully sent!');
            setSubmitting(false);
            refetch();
        },
        (error) => {
            console.log(error);
            const { data } = error
            Utils.showAlert(2, data.message || data?.non_field_errors[0])
            setSubmitting(false);
        }
    )
}

export const createChatRoomApiCall = (dispatch, values) => {

    dispatch(
        roomLoaderListener({
            loading: true
        })
    );

    const dataToSend = values;

    Utils.api.postApiCall(
        `${Utils.EndPoint.vendorEndpoints.chatRoomApi}`,
        dataToSend,
        (respData) => {
            const { data } = respData;
            dispatch(
                roomLoaderListener({
                    loading: false
                })
            );

            dispatch(
                successCreateRoomReducer({
                    data: data,
                    loading: false
                })
            );
        },
        (error) => {
            console.log(error);
            Utils.showAlert(2, 'Unable to create room');
            dispatch(failedCreateRoomReducer(error.detail));
        }
    )
}

firebase.initializeApp(firebaseConfig);

export const fetchChatApiCall = async (roomId, dispatch) => {

    dispatch(
        loaderListener({
            loading: true
        })
    );

    try {
        const res1 = await APIManager.getInstance().getFirebaseAuthToken();
        const resAuth = await signInWithCustomToken(auth, res1?.data?.token);
        firebase
            .firestore()
            .collection(process.env.REACT_APP_CHATS || 'order_vendor_chats')
            .doc(roomId)
            .collection('messages')
            .onSnapshot((documentSnapshot) => {
                console.log('User exists: ', documentSnapshot);
                let mess = [];
                documentSnapshot.forEach((doc) => {
                    mess.push(doc.data());
                });

                dispatch(
                    loaderListener({
                        loading: false
                    })
                );
                console.log("mess---",roomId, mess);
                dispatch(
                    successChatsReducer({
                        data: mess
                        ?.sort(function (a, b) {
                            return new Date(b.created_at) - new Date(a.created_at);
                        })
                        ?.reverse(),
                        loading: false
                    })
                );
            });
    }
    catch (error) {
        console.log(error);
        // Utils.showAlert(2, 'Something went Wrong');
        dispatch(failedChatsReducer(error.detail));
    }
}