import React from 'react';
import BasePage from '../base';
import { css, StyleSheet } from 'aphrodite';
import { convertPxToRem, getValueFromObjectByKeys, myLog } from '../../helper';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import { heightOfHeader, sizeOfBaseButton } from '../../utils/defined';
import { connect } from 'react-redux';
import withNavigate from '../../component/WithNavigate';
import { Col, Dropdown, Row, Spin, Table } from 'antd';
import { SVGImageComponentReact } from '../../themes/Images';
import { Area, Column } from '@ant-design/plots';
import { UserAction } from '../../redux/action';
import moment from 'moment/moment';
import { ROUTER_NAME } from '../../router/routers';
import APIManager from '../../connection/ApiManager';
import { translate } from '../../helper'
import LocalStorage from "../../defined/localStorage";

const brand = JSON.parse(LocalStorage.getItem('branding'))

const heightOfBottomBlackAlpha = convertPxToRem(284);

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(20),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    // backgroundColor: Colors.content_page,
    paddingBottom: convertPxToRem(82)
  },
  dashboardTitle: {
    marginBottom: convertPxToRem(52),
    marginTop: convertPxToRem(50)
  },
  bottomBlackAlpha: {
    width: '100%',
    minWidth: '100vw',
    height: heightOfBottomBlackAlpha,
    backgroundColor: Colors.black80,
    // position: "fixed",
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    position: 'absolute'
  },
  bgOverlay: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // height: `calc(100vh - ${heightOfHeader} - ${heightOfBottomBlackAlpha})`,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.56) 100%)',
    zIndex: 1,
    overflow: 'hidden'
  },
  wrapText: {
    position: 'relative',
    zIndex: 4,
    maxWidth: '50%',
    height: '100%',
    minHeight: convertPxToRem(400),
    // paddingBottom: convertPxToRem(99),
    paddingLeft: convertPxToRem(100)
  },
  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  }
});

class Analytics extends BasePage {
  constructor(props) {
    super(props);
    this.title = 'Analytics';
    this.state = {
      showOrdersMonthSelect: false,
      selectedMonth: moment().format('MMMM'),
      dataChartArea: null,
      quoteSummary: [],
      ordersReceived: 0,
      quotesSent: 0,
      quotesAccepted: 0,
      orders: [],
      showFilterOrder: false,
      filterOrdersCreated: 'month',
      configChartColumn: {
        data: [],
        isGroup: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'name',
        color: ['#2F80ED', '#002555'],
        label: {
          position: 'middle',
          layout: [
            {
              type: 'interval-adjust-position'
            },
            {
              type: 'interval-hide-overlap'
            },
            {
              type: 'adjust-color'
            }
          ]
        }
      },
      configChartArea: {
        xField: 'month',
        yField: 'count',
        xAxis: {
          range: [0, 1]
        }
      }
    };
    this.columns = [
      {
        title: translate('customerPo'),
        dataIndex: 'id',
        align: 'left',
        key: 'id',
        render: (text) => text || '2343434'
      },
      {
        title: translate('orderName'),
        dataIndex: 'fulfilled_by',
        align: 'left',
        key: 'fulfilled_by',
        render: (_, row) => {
          const quote = row.quotes.filter((q) => q.status === 'ACCEPTED');

          return quote.length > 0 ? quote?.[0]?.user?.name || 'N/A' : 'N/A';
        }
      },
      {
        title: translate('dateCreated'),
        dataIndex: 'created_at',
        align: 'left',
        key: 'created_at',
        render: (value) => {
          return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY hh:mm');
        }
      },
      {
        title: translate('status'),
        dataIndex: 'status',
        align: 'left',
        key: 'status',
        render: (value, record) => {
          const { vendor } = this.props;
          const { quotes, status } = record;
          const singleQuote = quotes && quotes[0];

          const quoteStatus = singleQuote?.status;

          return (
            <TextBase fontWeight={600} size={convertPxToRem(14)} color={Colors.grey2}>
              {this.filterQuoteStatus(quoteStatus)}
            </TextBase>
          );
        }
      },
      {
        title: translate('actions'),
        key: 'tags',
        dataIndex: 'tags',
        align: 'left',
        render: (value, item) => {
          const { navigate } = this.props;
          myLog('renderTable=>', value, item);
          return (
            <div className="flex-centser-all">
              <div
                style={{
                  paddingInline: convertPxToRem(8),
                  paddingRight: convertPxToRem(14),
                  paddingBlock: convertPxToRem(8),
                  borderRadius: convertPxToRem(5)
                }}
                className="flex-row-center width-fit-content cursor-pointer"
                onClick={() => navigate(`${ROUTER_NAME.orderDetail}?id=${item?.id}`)}>
                <TextBase size={convertPxToRem(14)} color={Colors.primary}>
                  Details
                </TextBase>
                <SVGImageComponentReact.IconChevronRight className="chevron-right" />
              </div>
            </div>
          );
        }
      }
    ];
  }

  filterQuoteStatus = (status) => {
    switch (status) {
      case 'SENT':
        return 'Sent';
      case 'ACCEPTED':
        return 'Accepted';
      case 'NS':
        return 'Pending';
      default:
        return 'Pending';
    }
  };

  renderMonthSelect = () => {
    const months = [
      {
        month: translate('month.january'),
        value: 1
      },
      {
        month: translate('month.february'),
        value: 2
      },
      {
        month: translate('month.march'),
        value: 3
      },
      {
        month: translate('month.april'),
        value: 4
      },
      {
        month: translate('month.may'),
        value: 5
      },
      {
        month: translate('month.june'),
        value: 6
      },
      {
        month: translate('month.july'),
        value: 7
      },
      {
        month: translate('month.august'),
        value: 8
      },
      {
        month: translate('month.september'),
        value: 9
      },
      {
        month: translate('month.october'),
        value: 10
      },
      {
        month: translate('month.november'),
        value: 11
      },
      {
        month: translate('month.december'),
        value: 12
      }
    ];
    return months.map((e) => (
      <TextBase
        style={{
          paddingTop: convertPxToRem(15),
          paddingBottom: convertPxToRem(8)
        }}
        className="cursor-pointer"
        size={14}
        color={Colors.black}
        onClick={() => {
          // const month =  moment().month(e.value - 1)
          this.setState(
            {
              showOrdersMonthSelect: false,
              selectedMonth: moment()
                .month(e.value - 1)
                .format('MMMM')
            },
            () => {
              this.fetchOrders();
            }
          );
        }}>
        {e.month}
      </TextBase>
    ));
  };

  _componentDidMount = () => {
    this.fetchData();
    this.fetchAcceptedOrders();
    this.fetchOrderSummary();
    this.fetchOrders();
  };

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'long' });
  }

  fetchData = async () => {
    try {
      const { getDashboardTotal } = this.props;
      const currentYear=new Date().getFullYear();
      const { data } = await getDashboardTotal({ year:currentYear });
      const ordersReceived = data?.order_received;
      const quotesSent = data?.quotes_sent;
      const quotesAccepted = data?.quotes_accepted;

      this.setState({
        ordersReceived: ordersReceived || 0,
        quotesSent: quotesSent || 0,
        quotesAccepted: quotesAccepted || 0
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchOrders = async () => {
    const month = this.state.selectedMonth;
    try {
      const { vendor } = this.props;

      const monthNumber = moment(month, 'MMMM').month();

      let query = {
        vendors__id: vendor?.id,
        created_at__month: monthNumber + 1
      };

      const resOrder = await APIManager.getInstance().getOrders(query);
      this.setState({
        orders: resOrder?.data || []
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchAcceptedOrders = async (filter = 'month') => {
    this.setState({
      dataChartArea: null
    });
    try {
      const { getAcceptedOrders } = this.props;

      const { data } = await getAcceptedOrders({ filter });
      const result = data.map((e) => {
        return { ...e, month: this.getMonthName(e.month) };
      });

      this.setState({
        dataChartArea: result || []
      });
    } catch (e) {
      console.log(e);
    }
  };

  fetchOrderSummary = async () => {
    try {
      const { getNumberOfQuotes } = this.props;

      const { data } = await getNumberOfQuotes({ filter: 'month' });
      const result = [];

      data?.forEach((e, i) => {
        result.push(
          {
            name: translate('quoteSent'),
            month: translate('month.'+e.month.toLowerCase()),
            value: e.sent
          },
          {
            name: translate('acceptedQuote'),
            month: translate('month.'+e.month.toLowerCase()),
            value: e.accepted
          }
        );
      });
      const columnsChart = this.state.configChartColumn;
      columnsChart.data = result;
      this.setState({
        configChartColumn: columnsChart
      });
    } catch (e) {
      console.log(e);
    }
  };

  renderContent() {
    const {
      dataChartArea,
      ordersReceived,
      quotesAccepted,
      quotesSent,
      showFilterOrder,
      filterOrdersCreated,
      selectedMonth,
      showOrdersMonthSelect
    } = this.state;
    return (
      <div style={{backgroundColor: this.props.whiteLabel?.web_background_color||brand?.web_background_color||"#fff"}} className={css(styles.container)}>
        <div className="flex-row-center">
          <TextBase
            fontWeight={700}
            size={convertPxToRem(25)}
            color={Colors.titlePage}
            style={{ marginBottom: 60 }}>
            acceptedQuotes
          </TextBase>
        </div>
        {/*<Row style={{ marginBottom: convertPxToRem(32) }}>*/}
        {/*  <div className="flex-row-center width-100">*/}
        {/*    <div className="flex-row-center">*/}
        {/*      <div*/}
        {/*        style={{ paddingBlock: convertPxToRem(14) }}*/}
        {/*        className="flex-row-center-between button-box-shadow cursor-pointer">*/}
        {/*        <TextBase*/}
        {/*          style={{ marginRight: convertPxToRem(13) }}*/}
        {/*          size={convertPxToRem(14)}*/}
        {/*          color={Colors.txt_sort}>*/}
        {/*          Filter*/}
        {/*        </TextBase>*/}
        {/*        <SVGImageComponentReact.IconArrowDown />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Row>*/}
        <Row gutter={[24, 24, 24]} style={{ marginBottom: convertPxToRem(24) }}>
          <Col span={8} xs={24} sm={24} md={8}>
            <div
              style={{
                backgroundColor: Colors.quote_send_14,
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative'
              }}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfOrdersReceived
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {ordersReceived}
              </TextBase>
            </div>
          </Col>
          <Col span={8} xs={24} sm={24} md={8}>
            <div
              style={{
                backgroundColor: Colors.mainColor_14,
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative'
              }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
              numberOfQuotesSent
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {quotesSent}
              </TextBase>
            </div>
          </Col>
          <Col span={8} xs={24} sm={24} md={8}>
            <div
              style={{
                backgroundColor: Colors.white,
                borderRadius: convertPxToRem(10),
                paddingTop: convertPxToRem(39),
                paddingBottom: convertPxToRem(49),
                paddingLeft: convertPxToRem(24),
                position: 'relative'
              }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <div style={{ position: 'relative' }}>
                  <SVGImageComponentReact.BgItemCardAnalytic
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0
                    }}
                  />
                </div>
              </div>
              <TextBase color={Colors.gray2} size={convertPxToRem(14)} style={{}}>
                numberOfQuotesAccepted
              </TextBase>
              <TextBase color={Colors.gray2} fontWeight={600} size={convertPxToRem(24)} style={{}}>
                {quotesAccepted}
              </TextBase>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: Colors.white,
            borderRadius: convertPxToRem(10)
          }}>
          <div className="width-100" style={{ padding: convertPxToRem(34) }}>
            <div className="flex-row-center-between" style={{ marginBottom: convertPxToRem(41) }}>
              <TextBase color={Colors.titleChart} size={convertPxToRem(20)}>
                orderCreated
              </TextBase>
              <Dropdown
                trigger={['click']}
                overlay={
                  <div
                    className="bg-white"
                    style={{
                      borderRadius: convertPxToRem(5),
                      paddingInline: convertPxToRem(10)
                    }}>
                    <TextBase
                      style={{
                        paddingTop: convertPxToRem(15),
                        paddingBottom: convertPxToRem(8)
                      }}
                      className="cursor-pointer"
                      size={14}
                      color={Colors.black}
                      onClick={() => {
                        const chartConfig = this.state.configChartArea;
                        chartConfig.xField = 'month';
                        this.setState(
                          {
                            showFilterOrder: false,
                            filterOrdersCreated: 'month',
                            configChartArea: chartConfig
                          },
                          () => {
                            this.fetchAcceptedOrders('month');
                          }
                        );
                      }}>
                      monthly
                    </TextBase>
                    <TextBase
                      style={{
                        paddingTop: convertPxToRem(8),
                        paddingBottom: convertPxToRem(15)
                      }}
                      className="cursor-pointer"
                      size={14}
                      color={Colors.black}
                      onClick={() => {
                        const chartConfig = this.state.configChartArea;
                        chartConfig.xField = 'year';
                        this.setState(
                          {
                            showFilterOrder: false,
                            filterOrdersCreated: 'year',
                            configChartArea: chartConfig
                          },
                          () => {
                            this.fetchAcceptedOrders('year');
                          }
                        );
                      }}>
                      yearly
                    </TextBase>
                  </div>
                }
                visible={showFilterOrder}>
                <div
                  className="flex-row-center-between filter-box cursor-pointer"
                  onClick={() =>
                    this.setState({
                      showFilterOrder: !showFilterOrder
                    })
                  }>
                  <TextBase
                    style={{
                      marginRight: convertPxToRem(13),
                      padding: 14
                    }}
                    size={convertPxToRem(14)}
                    color={Colors.grey}
                    fontWeight={400}>
                    {filterOrdersCreated === 'year' ? 'yearly' : 'monthly'}
                  </TextBase>
                  <div style={{ padding: 12 }}>
                    <SVGImageComponentReact.IconArrowDown />
                  </div>
                </div>
              </Dropdown>
            </div>
            {dataChartArea ? (
              <Area
                style={{ width: '100%' }}
                {...this.state.configChartArea}
                data={dataChartArea}
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin />
              </div>
            )}
          </div>
        </Row>
        <Row style={{ marginTop: convertPxToRem(48) }} gutter={[14, 14]}>
          <Col span={12} xs={24} sm={24} md={12}>
            <div
              className="width-100"
              style={{
                border: `solid 1px ${Colors.borderBoxChart}`,
                borderRadius: convertPxToRem(8),
                backgroundColor: Colors.white
              }}>
              <div
                className="flex-row-center-between width-100"
                style={{
                  marginBottom: convertPxToRem(27),
                  paddingInline: convertPxToRem(25),
                  paddingBlock: convertPxToRem(18)
                }}>
                <div>
                  <TextBase color={Colors.black} fontWeight={500} size={convertPxToRem(20)}>
                    orders
                  </TextBase>
                </div>

                <Dropdown
                  trigger={['click']}
                  overlay={
                    <div
                      className="bg-white"
                      style={{
                        borderRadius: convertPxToRem(5),
                        paddingInline: convertPxToRem(10)
                      }}>
                      {this.renderMonthSelect()}
                    </div>
                  }
                  visible={showOrdersMonthSelect}>
                  <div
                    className="flex-row-center-between filter-box cursor-pointer"
                    onClick={() =>
                      this.setState({
                        showOrdersMonthSelect: !showOrdersMonthSelect
                      })
                    }>
                    <TextBase
                      style={{
                        marginRight: convertPxToRem(13),
                        padding: 14
                      }}
                      size={convertPxToRem(14)}
                      color={Colors.grey}
                      fontWeight={400}>
                      {'month.'+selectedMonth.toLowerCase()}
                    </TextBase>
                    <div style={{ padding: 12 }}>
                      <SVGImageComponentReact.IconArrowDown />
                    </div>
                  </div>
                </Dropdown>
              </div>
              <Table
                scroll={{ y: 320 }}
                style={{ width: '100%' }}
                columns={this.columns}
                dataSource={this.state.orders}
                pagination={{
                  position: ['none']
                }}
              />
            </div>
          </Col>
          <Col span={12} xs={24} sm={24} md={12}>
            <div
              className="width-100"
              style={{
                // height: 516,
                border: `solid 1px ${Colors.borderBoxChart}`,
                borderRadius: convertPxToRem(8),
                backgroundColor: Colors.white,
                paddingInline: convertPxToRem(25),
                paddingBlock: convertPxToRem(18)
              }}>
              <div
                className="flex-row-center-between width-100"
                style={{
                  marginBottom: convertPxToRem(27)
                }}>
                <div>
                  <TextBase color={Colors.black} fontWeight={500} size={convertPxToRem(20)}>
                    quoteSentvsAcceptedQuote
                  </TextBase>
                </div>
              </div>
              <Column legend={{ position: 'bottom-right' }} {...this.state.configChartColumn} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: getValueFromObjectByKeys(state, ['User', 'userDetail']),
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo']),
    listNotification: getValueFromObjectByKeys(state, ['User', 'notification']),
    vendor: getValueFromObjectByKeys(state, ['User', 'vendor']),
    whiteLabel: getValueFromObjectByKeys(state, ['User', 'branding']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardTotal: (query) => {
      return dispatch(UserAction.getDashboardTotal(query));
    },
    getAcceptedOrders: (query) => {
      return dispatch(UserAction.getAcceptedOrders(query));
    },
    getNumberOfQuotes: (query) => {
      return dispatch(UserAction.getNumberOfQuotes(query));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Analytics));
