import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { convertPxToRem } from '../../helper';
import { Modal, Row } from 'antd';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Close as CloseIcon } from '@mui/icons-material';

const StyledGalleryWrapper = styled('div')(({ }) => ({
  '& .MuiSvgIcon-root': {
    'color': "black",
    'height': '30px',
    'width': ' 30px',
    'borderRadius': '30px',
    'background': 'white',
    'padding': '2px'
  },
}));

const ZoomWrapper = styled('div')(({ theme }) => ({
  '& .react-transform-component': {
    [theme.breakpoints.down('md')]: {
      height: '100vh !important'
    }
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    },
    '& .MuiDialog-paper': {
      maxWidth: '65% !important',
      maxHeight: 'calc(100% - -246px) !important',
      // marginTop: '320px'
    },
    '& .MuiDialog-container': {
      overflowY: 'auto !important',
    },
    '& img': {
      maxHeight: '800px',
      maxWidth: '800px'
    },
  },
  '& .MuiDialogContent-root': {
    overflowY: 'hidden',
    padding: '0 !important'
  }
}));

class ImageSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.myImageRef = React.createRef();
    this.onFullscreen = this.onFullscreen.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onMoveToSlide = this.onMoveToSlide.bind(this);
    this.showThumbnails = props.showThumbnails;
    this.showBullets = props.showBullets;
    this.fullScreen = props.fullScreen
    this.state = {
      images: [],
      clickedImage: null,
      showModal: false,
      imageSize: 500
    };
  }

  onFullscreen() {
    this.myImageRef.current.fullScreen();
  }
  onPlay() {
    this.myImageRef.current.play();
  }
  onPause() {
    this.myImageRef.current.pause();
  }
  onMoveToSlide() {
    // Index start from 0 so 2 will move to 3rd slide
    this.myImageRef.current.slideToIndex(2);
  }
  componentDidMount() { }

  onImgLoad(target) {
    this.setState({ imageSize: target?.nativeEvent?.srcElement?.naturalWidth || 500 });
  }

  handleImageClick = (event) => {
    const clickedImage = event.target;
    this.setState({
      showModal: true,
      clickedImage
    });
  };

  onClose = () => {
    this.setState({
      showModal: false
    });
  }

  render() {
    return (
      <StyledGalleryWrapper style={{ border: "2px solid #fff", borderRadius: "5px" }} className="gallery-wrapper">
        {this.props.images && (
          <ImageGallery
            items={this.props.images.map((data) => {
              return {
                original: data.image,
                thumbnail: data.image
              };
            })}
            ref={this.myImageRef}
            onClick={this.handleImageClick}
            lazyLoad={true}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={this.showThumbnails || false}
            showBullets={this.showBullets || false}
            renderLeftNav={(onClick, disabled) => (
              <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', left: '5px', zIndex: '999', borderRadius: '50px' }}>
                <ArrowBackIcon className='arrowIcon' />
              </Button>
            )}
            renderRightNav={(onClick, disabled) => (
              <Button color='primary' onClick={onClick} disabled={disabled} style={{ position: 'absolute', bottom: '40%', right: '5px', zIndex: '999', borderRadius: '50px' }}>
                <ArrowForwardIcon className='arrowIcon' />
              </Button>
            )}
          />
        )}

        <BootstrapDialog fullScreen={this.fullScreen} fullWidth open={this.state.showModal} onClose={this.onClose}>

          <IconButton
            aria-label="close"
            onClick={this.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#000',
              zIndex: '999',
              backgroundColor: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Zoom>
              {this.state.clickedImage && (
                <ZoomWrapper>
                  <Row justify="center">
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          style={{
                            width: '100%',
                            // maxHeight: '810px',
                            height: '100%',
                            // maxWidth: '800px'
                          }}
                          // onLoad={this.onImgLoad}
                          src={this.state.clickedImage.src}
                          alt={this.state.clickedImage.alt}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Row>
                </ZoomWrapper>
              )}
            </Zoom>
          </DialogContent>
        </BootstrapDialog>

      </StyledGalleryWrapper>
    );
  }
}
export default ImageSliderComponent;
