import React from 'react'
 
function  Tooltip(props) {
   
  return (
    <div tabIndex={0} onFocus={props?.onFocus} onBlur={props?.onBlur} style={{opacity: props?.isVisible ? 1 : 0 ,height: '40px', width:'min-Content', backgroundColor: 'white', display: 'flex', flexDirection: 'row', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',position:'relative', top:'40px', left:'40px' ,borderRadius:'6px',alignItems:"center" }}>
        <span tabIndex={0} style={{padding:'10px', height:'inherit'}}  onClick={props?.handlePaste}>Paste</span>
        <span tabIndex={0} style={{padding:'10px', height:'inherit',borderLeft:'1px solid gray'}} onClick={props?.handleScan}>Scan</span>
    </div>
  )
}
 
export default Tooltip