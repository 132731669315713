import { useEffect, useState } from 'react';
import { getFirebaseToken, onForegroundMessage } from '../firebase';
import { MESSAGE_TYPE, messageManager } from '../helper';
import { useDispatch } from 'react-redux';
import { UserAction } from '../redux/action';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../firebase';

const PushNotifications = () => {
  // Firebase
  const isSupported = 'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  const [showNotificationBanner, setShowNotificationBanner] = useState(
    isSupported ? Notification.permission === 'default' : false
  );

  const dispatch = useDispatch();

  useEffect(() => {
    // if (isSupported) {
    //   // Register the service worker
    //   navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //     .then((registration) => {
    //       console.log('Service Worker registered with scope:', registration.scope);
    //     })
    //     .catch((error) => {
    //       console.error('Service Worker registration failed:', error);
    //     });
    // }

    onMessage(messaging, (payload) => {
      // console.log('Received foreground message: ', payload, messaging);
      const {
        notification: { title, body }
      } = payload;
      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });
    });

    onForegroundMessage().then(payload => {
      // console.log('Received foreground message: ', payload);
      const {
        notification: { title, body }
      } = payload;
      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });
    }).catch(err => {
      console.log('failed: ', err)
    }
    );

  }, []);

  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      // console.log('Received background message:', event.data);

      const { title, body } = event.data.notification;

      messageManager({
        type: MESSAGE_TYPE.info,
        messageShow: title
      });

      // Show the notification using your preferred UI library or custom implementation
      // You can also handle different actions based on the notification data
    });
  }, []);

  useEffect(() => {
    handleGetFirebaseToken();
  }, []);

  const handleGetFirebaseToken = async () => {
    // console.log("handleGetFirebaseToken ======================>")
    // console.log('handleGetFirebaseToken===>handleGetFirebaseToken');
    // getFirebaseToken()
    //   .then((firebaseToken) => {
    //     console.log('Firebase token: ', firebaseToken);
    //     if (firebaseToken) {
    //       console.log('show notify');
    //       setShowNotificationBanner(true);
    //       registerDevice(firebaseToken);
    //     }
    //   })
    //   .catch((err) => console.error('An error occured while retrieving firebase token. ', err));

    try {
      const firebaseToken = await getFirebaseToken();
      // console.log('Firebase token: ', firebaseToken);
      if (firebaseToken) {
        // console.log('show notify');
        setShowNotificationBanner(true);
        registerDevice(firebaseToken);
      }
    } catch (error) {
      console.log('handleGetFirebaseToken err ', error.code, "<==>", error.message, "<==>", error.name, "<==>", error.stack, "<==>", error.toString);
      if (error.code === 'messaging/permission-blocked') {
        setShowNotificationBanner(false);
      } else {
        // console.error('An error occurred while retrieving firebase token. ', error);
      }
    }

  };

  const registerDevice = (token) => {
    const formData = {
      registration_id: token,
      type: 'web'
    };
    // console.log("push notification")
    dispatch(UserAction.registerDevice(formData));
  };
};

export default PushNotifications;
