import React, { Component } from "react";

export default class BasePage extends Component {
  constructor(props) {
    super(props);
    this.title = "MOVE";
  }
  componentDidMount() {
    // document.title = this.title || "MOVE";
    this._componentDidMount && this._componentDidMount();
  }
  componentWillUnmount() {}
  render() {
    if (this.renderContent) {
      return this.renderContent();
    }
    return <div />;
  }
}
