import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Modal, Row, Table } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import {
  useConnectStripe,
  useDisconnectStripe,
  useGetConnectedAccounts
} from '../../hooks/usePayment';
import TextBase from '../../common/text';
import { Colors } from '../../themes/Colors';
import LoadingBase from '../../common/loading';
import { convertPxToRem } from '../../helper';
import AppLogo from '../../assets/image/svg/app-logo.svg';
import { useQueryClient } from '@tanstack/react-query';
import StripeLogo from '../../assets/image/svg/stripe.svg';
import StripeWordsLogo from '../../assets/image/svg/stripe-words.svg';
import ConvertIcon from '../../assets/image/svg/convert.svg';

import { styles } from './styles';
import LocalStorage from '../../defined/localStorage';
import { useSelector } from 'react-redux';

const brand = JSON.parse(LocalStorage.getItem('branding'))

const { confirm } = Modal;

function ConnectStripe() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const whiteLabel = useSelector((state) => state.User?.branding);

  const { connectedAccount, isLoadingConnectedAccount, refetch, isFetched } =
    useGetConnectedAccounts(onError, queryClient);
  const { connectStripe, isConnecting } = useConnectStripe(onSuccessConnect);
  const { disconnectStripe, isDisconnecting } = useDisconnectStripe(onSuccessDisconnect);

  const [connected, setConnected] = useState({});

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const handleConnectSubmit = (code) => {
    console.log('handleConnectSubmit');
    if (!code) return;
    console.log('handleConnectSubmit 2');
    connectStripe({ code: code });
  };

  React.useEffect(() => {
    document.title = 'Connect Stripe';
  }, []);

  useEffect(() => {
    console.log('code', code);
    handleConnectSubmit(code);
  }, [code]);

  useEffect(() => {
    console.log('connectedAccount', connectedAccount);
  }, [connectedAccount]);

  useEffect(() => {
    handleCheckConnected();
  }, [connectedAccount]);

  const handleCheckConnected = () => {
    if (connectedAccount?.id) {
      setConnected({
        display_name: connectedAccount?.account?.settings?.dashboard?.display_name,
        ...connectedAccount
      });
    }
  };

  const handleDisconnect = () => {
    disconnectStripe();
  };

  const handleConnect = () => {
    const callback_url = window.location.origin + '/';
    // window.location.href = `https://www.move2vendor.com/stripe/accounts/connect/authorize/web/?callback_url=${callback_url}connect-stripe`;
       window.location.href = `https://${window.location.host}/stripe/accounts/connect/authorize/web/?callback_url=https://move2-llc-move-3295-staging.botics.co/connect-stripe&state=${callback_url}connect-stripe`;
  };

  const showDisconnectConfirm = () => {
    confirm({
      title: 'Are you sure you want disconnect?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        handleDisconnect();
      },
      onCancel() {}
    });
  };

  function clearSearchParams() {
    navigate({
      search: ''
    });
  }

  function onSuccessConnect() {
    refetch();
    clearSearchParams();
  }

  function onSuccessDisconnect() {
    refetch();
  }

  function onError() {
    console.log('error here');
    setConnected({});
  }

  return (
    <div style={{backgroundColor: whiteLabel?.web_background_color||brand?.web_background_color||"#fff"}} className={css(styles.container)}>
      <div className="flex-row-center">
        <TextBase
          style={{ marginBottom: 20 }}
          fontWeight={600}
          size={convertPxToRem(24)}
          color={Colors.titlePage}>
          acceptPayments
        </TextBase>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
          height: '100%',
          width: '100%',
          paddingTop: '5%'
        }}>
        <div style={{ maxWidth: '551px' }}>
          <div
            style={{
              backgroundColor: '#fff',
              minHeight: '198px',
              display: 'flex',
              justifyContent: 'center',
              minWidth: '300px',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '8px'
            }}>
            {connected?.id ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                    marginBottom: '30px'
                  }}>
                  <img src={StripeWordsLogo} style={{ height: '50px', width: '128px' }} />
                </div>
                <div>
                  <TextBase
                    style={{ textAlign: 'center' }}
                    fontWeight={600}
                    size={convertPxToRem(24)}
                    color={Colors.success}>
                    stripeIsSuccessfullyConnected
                  </TextBase>
                </div>

                <Button
                  onClick={showDisconnectConfirm}
                  style={{
                    backgroundColor: '#FFFFFF',
                    height: '50px',
                    minWidth: '500px',
                    borderColor: Colors.stripe,
                    borderRadius: '4px',
                    width: '80%',
                    margin: '10px',
                    marginBottom: '30px'
                  }}
                  loading={isDisconnecting}>
                  {!isDisconnecting && (
                    <TextBase
                      style={{}}
                      fontWeight={600}
                      size={convertPxToRem(16)}
                      color={Colors.stripe}>
                      disconnectStripeAccount{' '}
                    </TextBase>
                  )}
                </Button>
              </div>
            ) : !isLoadingConnectedAccount ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '20px',
                    borderRadius: 50,
                    width: '150px',
                    backgroundColor: '#F6F8FA'
                  }}>
                  <img src={AppLogo} style={{ height: '36px', width: '36px' }} />
                  <img src={ConvertIcon} style={{ height: '20px', width: '20px' }} />
                  <img src={StripeLogo} style={{ height: '36px', width: '36px' }} />
                </div>

                <Button
                  type="primary"
                  loading={isConnecting}
                  onClick={handleConnect}
                  style={{
                    backgroundColor: whiteLabel?.web_button_color||brand?.web_button_color||'#635BFF',
                    height: '50px',
                    minWidth: '200px',
                    borderRadius: '4px',
                    width: '80%',
                    margin: '10px'
                  }}>
                  {!isConnecting && (
                    <TextBase
                      style={{}}
                      fontWeight={600}
                      size={convertPxToRem(16)}
                      color={Colors.white}>
                      connectStripe
                    </TextBase>
                  )}
                </Button>
              </div>
            ) : isLoadingConnectedAccount && !isFetched ? (
              <LoadingBase />
            ) : null}
          </div>

          {!connected?.id && !isLoadingConnectedAccount && (
            <TextBase
              size={14}
              fontWeight={400}
              color={Colors.black}
              style={{ textAlign: 'center', marginTop: '50px' }}>
              striprInterfaceStatement{' '}
            </TextBase>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectStripe;
