import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Colors } from "../../themes/Colors";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: Colors.blackTitle }} spin />
);
const LoadingBase = () => {
  return <Spin indicator={antIcon} />;
};
export default LoadingBase;
