import React, { Component } from 'react';
import { convertPxToRem, myLog } from '../../helper';
import { Colors } from '../../themes/Colors';
import TextBase from '../text';
import { css, StyleSheet } from 'aphrodite';
import { SVGImageComponentReact } from '../../themes/Images';
import './index.css';
import { sizeBase } from '../../utils/defined';

const styles = StyleSheet.create({
  container: {
    // width: "fit-content",
    zIndex: 999
  },
  selectContainer: {
    // width: sizeBase.inputForm.width,
    width: '100%',
    height: sizeBase.inputForm.height,
    borderRadius: sizeBase.borderRadiusInputForm,
    border: 'solid 1px',
    borderColor: Colors.borderSelect,
    marginTop: convertPxToRem(8),
    background: Colors.grey4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: sizeBase.paddingInput.left,
    paddingRight: sizeBase.paddingInput.right,
    cursor: 'pointer'
  },
  optionContainer: {
    width: sizeBase.inputForm.width,
    maxHeight: convertPxToRem(280),
    backgroundColor: Colors.white,
    borderRadius: sizeBase.borderRadiusInputForm,
    border: 'solid 1px',
    borderColor: Colors.borderSelect,
    overflowY: 'scroll',
    position: 'absolute',
    zIndex: 9999999
  },
  optionItem: {
    width: '100%',
    backgroundColor: Colors.white,
    fontSize: convertPxToRem(14),
    fontWeight: 400,
    color: Colors.borderCheckbox,
    height: sizeBase.inputForm.height,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: sizeBase.paddingInput.left,
    paddingRight: sizeBase.paddingInput.right,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colors.bgSubSelect,
      color: Colors.blackTitle
    }
  },
  optionSubItem: {
    width: '100%',
    backgroundColor: Colors.white,
    fontSize: convertPxToRem(14),
    fontWeight: 400,
    color: Colors.borderCheckbox,
    height: sizeBase.inputForm.height,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: convertPxToRem(56),
    cursor: 'pointer',
    ':hover': {
      backgroundColor: Colors.bgSubSelect,
      color: Colors.blackTitle
    }
  },
  txtOption: {
    color: '',
    width: '100%',
    ':hover': {
      color: Colors.blackTitle
    }
  },
  subSelect: {
    width: '100%',
    backgroundColor: Colors.bgSubSelect,
    height: sizeBase.inputForm.height,
    paddingLeft: sizeBase.paddingInput.left,
    paddingRight: sizeBase.paddingInput.right,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  optionFistBorder: {
    borderTopLeftRadius: sizeBase.borderRadiusInputForm,
    borderTopRightRadius: sizeBase.borderRadiusInputForm
  },
  optionLastBorder: {
    borderBottomLeftRadius: sizeBase.borderRadiusInputForm,
    borderBottomRightRadius: sizeBase.borderRadiusInputForm
    // borderBottom: "solid 1px",
    // borderBottomColor: Colors.borderSelect,
  }
});
export default class SelectCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSelect: false,
      openKeys: [],
      search: ''
    };
    this.refContainer = React.createRef();
    this.items = [
      {
        title: 'Retirement Accounts',
        key: 1,
        items: [
          {
            key: '2_1',
            title: 'Roth IRA'
          },
          {
            key: '2_2',
            title: '401k'
          },
          {
            key: '2_3',
            title: 'Pension Holder'
          },
          {
            key: '2_4',
            title: 'IRA'
          },
          {
            key: '2_5',
            title: '403B'
          }
        ]
      },
      {
        title: 'Non-Retirement Accounts',
        key: 2
      }
    ];
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setSearch = (terms) => {
    this.setState({ search: terms });
  };
  handleClickOutside = (event) => {
    if (this.refSelect && !this.refSelect.contains(event.target)) {
      if (this.state.openSelect) {
        this.setState({
          openSelect: false
        });
      }
    }
  };
  getOptions = (items = [], isSubOptions = false, lastItem = null) => {
    myLog('getOptions=>', items);
    const { openKeys } = this.state;
    const filterResults = items.filter((val) => {
      return val?.title.toLowerCase().includes(this.state.search);
    });

    return items.map((item, index) => {
      if (item.items) {
        const isOpen = openKeys.includes(item.key);
        return (
          <div key={item.key} className={index === 0 ? css(styles.optionFistBorder) : ''}>
            <div
              onClick={() => {
                if (!isOpen) {
                  this.setState({
                    openKeys: [...openKeys, item.key]
                  });
                } else {
                  this.setState({
                    openKeys: openKeys.filter((x) => x !== item.key)
                  });
                }
              }}
              className={
                css(styles.subSelect) + ' ' + (index === 0 ? css(styles.optionFistBorder) : '')
              }>
              <TextBase color={Colors.blackTitle} size={convertPxToRem(14)}>
                {item.title}
              </TextBase>
              {isOpen ? (
                <SVGImageComponentReact.IconUpArrow />
              ) : (
                <SVGImageComponentReact.IconDownArrow />
              )}
            </div>
            {openKeys.includes(item.key) ? this.getOptions(item.items, true, lastItem) : null}
          </div>
        );
      } else {
        return (
          <div
            key={item.key}
            className={
              isSubOptions
                ? css(styles.optionSubItem)
                : css(styles.optionItem) +
                  ' ' +
                  (lastItem && item.key === lastItem.key ? css(styles.optionLastBorder) : '')
            }
            onClick={() => {
              myLog('ClickSelect=>', item);
              this.setState({
                openSelect: false
              });
              this.props.onSelect && this.props.onSelect(item.key);
            }}>
            {item.title}
          </div>
        );
      }
    });
  };
  getTitleShow = (items = []) => {
    const { value } = this.props;
    let titleReturn = '';
    items.map((item) => {
      if (item.items) {
        titleReturn = this.getTitleShow(item.items);
      } else {
        if (item.key === value) {
          titleReturn = item?.short || item.title || '';
        }
      }
    });
    return titleReturn;
  };
  render() {
    const { openSelect } = this.state;
    const { placeholder, value, items } = this.props;
    const options = this.getOptions(
      items || [],
      false,
      items[items.length - 1].items
        ? items[items.length - 1].items[items[items.length - 1].items.length - 1]
        : items[items.length - 1]
    );
    const titleShow = this.getTitleShow(items);
    myLog('renderSelect=>', titleShow);
    return (
      <div
        className={css(styles.container) + ' position-relative'}
        ref={(ref) => (this.refSelect = ref)}>
        <div
          onClick={() => {
            if (!this.props.disabled) {
              this.setState({
                openSelect: !openSelect
              });
            }
          }}
          className={css(styles.selectContainer)}>
          <TextBase
            color={titleShow ? Colors.black : Colors.borderCheckbox}
            size={convertPxToRem(16)}>
            {titleShow || placeholder || 'Select item'}
          </TextBase>
          {openSelect ? (
            <SVGImageComponentReact.IconUpArrow />
          ) : (
            <SVGImageComponentReact.IconDownArrow />
          )}
        </div>
        {openSelect ? (
          <div className={css(styles.optionContainer) + ' container-select-custom'}>
            {/*<input type="text" onChange={this.setSearch} />*/}

            {options}
          </div>
        ) : null}
      </div>
    );
  }
}
