import React, {Component} from "react"
import "./index.css"
import {convertPxToRem, myLog} from "../../helper"
import {StyleSheet} from "aphrodite"
import {Col, Layout, Row} from "antd"
import {Colors} from "../../themes/Colors"
import {SVGImageComponentReact} from "../../themes/Images"
import TextBase from "../../common/text"
import withNavigate from "../../component/WithNavigate";
import Logo from '../../assets/image/png/logo.png';

const {Header, Footer, Sider, Content} = Layout
const styles = StyleSheet.create({
    iconApp: {
        width: convertPxToRem(144, true),
        height: convertPxToRem(95, true)
    },
    contentContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        // paddingLeft: convertPxToRem(134, true),
        // paddingTop: convertPxToRem(46, true),
        textAlign: "left",
        right: 0,
        bottom: 0,
        overflow: "hidden"
    },
    containerRight: {
        // paddingLeft: convertPxToRem(134, true),
        paddingTop: convertPxToRem(46, true)
    }
})

class PublicLayout extends Component {
    constructor(props) {
        super(props)
    }

    showOverflow = () => {
        const routeUrl = this.props?.location?.pathname
        console.log('url', this.props)
        return !(routeUrl === '/privacy' || routeUrl === '/terms');
    }

    render() {
        const {t, isLandingPage, children} = this.props
        myLog("isLandingPage", isLandingPage)
        return (
            <Row
                style={{
                    height: "100vh",
                    overflow: this.showOverflow() ? 'auto' : 'hidden',
                }}

            >
                <Col span={8} xs={24} sm={24} md={12} lg={8}>
                    <div
                        style={{
                            // backgroundColor: Colors.black30,
                            flex: 1,
                            display: "flex",
                            height: "100%",
                            backgroundImage: `url(${require("../../assets/image/auth-bg.jpg")})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            paddingInline: convertPxToRem(8)
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                backgroundSize: "100%",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                height: convertPxToRem(258),
                                alignItems: "flex-end",
                                justifyContent: "center"
                            }}
                        >
                            <img src={Logo} style={{ height: '140px', width: '140px' }} />
                            {/* <SVGImageComponentReact.LogoMove/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                backgroundSize: "100%",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                flexDirection: "column",
                                justifyContent: "center",
                                height: convertPxToRem(258)
                            }}
                        >
                            <TextBase
                                style={{marginBottom: 20}}
                                size={convertPxToRem(24)}
                                color={Colors.white}
                                fontWeight={600}
                            >
                                makeOrderingVeryEasy
                            </TextBase>
                        </div>
                    </div>
                </Col>
                <Col span={16} xs={24} sm={24} md={12} lg={16}>
                    {children}
                </Col>
            </Row>
        )
    }
}

export default (withNavigate(PublicLayout))

