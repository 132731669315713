import React from 'react';
import { InputLabel, TextField, TextareaAutosize, styled } from '@mui/material';

const StyledInput = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        'borderRadius': '5px',
        'backgroundColor': '#fff',
        'padding': '10px 0px',
        'height': '100%',
    },
    '& .MuiInputBase-root::after': {
        'borderBottom': '2px solid #000000',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        'borderColor': '#f2eeee'
    },
    '& .MuiFormHelperText-root': {
        'marginLeft': '0px',
        'borderRadius': 'inherit',
    },
    '& input': {
        'padding': '10px',
        'backgroundColor': '#F8F8F8',
        'height': '100%',
    },
    '& .MuiFormLabel-root': {
        'color': '#000',
        'fontWeight': '500',
    },
    '& .MuiInputLabel-shrink': {
        'color': '#000 !important',
        'fontWeight': '500 !important',
        'fontSize': '1rem !important'
    },
    '.css-m4bgub input': {
        'padding': '0px 23px!important',
    }
}));

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
    'boxSizing': 'border-box',
    'background': '#F8F8F8',
    'width': '100%',
    'padding': '8px 12px',
    'borderRadius': '8px',
    'borderColor': '#C8C8C8'
}));

export const Label = styled(InputLabel)(({ theme }) => ({
    'fontSize': '13px',
    'fontWeight': '600',
    'marginBottom': '7px',
    'color': '#000',
    'lineHeight': 'normal'
}));

export default function TextInput({
    label,
    placeholder,
    value,
    onChange,
    id,
    name,
    onBlur,
    onFocus,
    onClick,
    error,
    type,
    asTextArea,
    rows,
    disabled,
    className,
    InputProps,
    fullWidth,
    helperText,
    minRows,
    style={},
    ...rest
}) {
    const MainComponent = asTextArea ? StyledTextArea : StyledInput;
    return (
        <>
            {label && (
                <Label htmlFor={id}>{label}</Label>
            )}
            <MainComponent
                className={className}
                data-testid={rest['data-testid']}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                id={id}
                name={name}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onClick}
                error={error}
                type={type}
                disabled={disabled}
                InputProps={InputProps}
                fullWidth={fullWidth}
                helperText={helperText}
                minRows={minRows}
                style={{...style, height: "50px"}}
            />
        </>
    );
}
