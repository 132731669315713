import * as yup from 'yup';

export const commentsSchema = yup.object().shape({
    comment: yup
        .string()
        .required("mandatory"),
});

export const createNoteSchema = yup.object().shape({
    customer_po: yup
        .string()
        .required("customerPORequired"),
    order_name: yup
        .string()
        .required("orderNameRequired"),
    note: yup
        .string()
        .required("noteRequired"),
});

export const messageSchema = yup.object().shape({
    body: yup
        .string()
        .required("messageBodyRequired"),
});

export const quotesSchema = yup.object().shape({
    order_fullfill_date: yup
        .string()
        .required("orderFullfillmentRequired"),
    quote: yup
        .string()
        .required("quoteRequired"),
});

export const childAccountSchema = yup.object().shape({
    location_name: yup.string().required('locationNameRequired'),
    location: yup.string().required('locationRequired'),
    country_code: yup.string().required('countryCodeRequired'),
    mobile_number: yup.string()
        .required("mobileNumberRequired"),
    admin_email_1: yup.string().email('validEmail').required('adminEmail1Required'),
    admin_name_1: yup.string().required('adminName1Required'),
    admin_email_2: yup.string().email('validEmail')
});

export const createWhiteLabelPartnerScheme = yup.object().shape({
    company_name: yup.string().required("companyNameRequired"),
    contact_details: yup.string().required("contactNumberRequired"),
    email: yup.string().email('validEmail').required('emailRequired'),
    role: yup.string().required("roleRequired"),
});

export const verifyChildAccountSchema = yup.object().shape({
    new_password: yup
        .string()
        .trim()
        .required("newPasswordRequired")
        .min(8, "passwordMinLimit")
        .max(16, "passwordMaxLimit")
        .matches(
            /(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,8}/,
            "validPassword"
        ),
    confirm_password: yup
        .string()
        .trim()
        .min(8, "passwordMinLimit")
        .max(16, "passwordMaxLimit")
        .oneOf([yup.ref("new_password"), null], "passwordMatch")
        .required("confirmPasswordRequired"),
    email: yup.string().email('validEmail').required('emailRequired'),
    key: yup.string().required("yeyRequired"),
});

export const inviteTeamSchema = yup.object().shape({
    first_name: yup
        .string()
        .required("firstNameRequired"),
    last_name: yup
        .string()
        .required("lastNameRequired"),
    email: yup.string().email('validEmail').required('emailRequired'),
    // country_code: yup.string().required('countryCodeRequired'),
    phone_number: yup.string()
        .required("mobileNumberRequired")
        .matches(/^(\+\d{1,3})?\d{6,14}$/, "Phone Number should be in international format"),
    role: yup.string().required("roleRequired"),
});