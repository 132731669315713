import React, { useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Whiteboard } from './lib';
import styles from './app.module.scss';

const App = ({ onClose, onSave, imageUrl}) => {
  const saveRef = useRef(null)
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "5%",
          marginBottom: "5px"
        }}
      >
        <CloseIcon onClick={onClose} style={{color: "#fff"}} />
        <button  
          onClick={()=>saveRef.current.saveImage()}
          style={{
            backgroundColor: "orande",
            border: "none",
            borderRadius: "3px",
            fontWeight: 600,
            padding: "3px 10px",
            height: "30px"
          }}
        >
            Done
        </button>
      </div>
      <div
        style={{
            display: "flex",
            justifyContent: "center",
            height: "95%",
            width: "100%"
        }}
      >
        <div style={{width: "100%"}} className={styles.app}>
          <main>
            <Whiteboard ref={saveRef} onClose={onClose} onSave={onSave} imageUrl={imageUrl} aspectRatio={4 / 3} />
          </main>
        </div>
      </div>
    </div>
  );
};

export default App;
