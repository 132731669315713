import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
}

const termsAndConditionsSlice = createSlice({
    name: 'termsAndCondition',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successTermsAndConditionsReducer: (state, action) => {
            state.data = action.payload.data;
        },
        failedTermsAndConditionsReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successTermsAndConditionsReducer, failedTermsAndConditionsReducer, loaderListener } = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;