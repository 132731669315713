import { combineReducers } from "redux";
import User from "../reducers/user";

import authmodelSlice from '../../mobile/modules/Auth/Slice/authSlice';
import quotesSlice from "../../mobile/modules/Quotes/Slice/quotesSlice";
import quotesDetailSlice from "../../mobile/modules/Quotes/Slice/quotesDetailSlice";
import ordersSlice from "../../mobile/modules/Orders/Slice/ordersSlice";
import ordersDetailSlice from "../../mobile/modules/Orders/Slice/ordersDetailSlice";
import userProfileSlice from "../../mobile/modules/UserProfile/Slice/userProfileSlice";
import notesListSlice from "../../modules/notes/Slice/notesListSlice";
import commentsListSlice from "../../modules/notes-detail/Slice/commentsListSlice";
import vendorOrdersDetailSlice from "../../modules/order-detail/Slice/orderDetailSlice";
import getChatSlice from "../../common/chat/Slice/getChatSlice";
import createRoomSlice from "../../common/chat/Slice/createRoomSlice"; 
import orderListSlice from "../../modules/order/Slice/orderListSlice";
import childAccountsListSlice from "../../modules/child-accounts/Slice/childAccountsListSlice";
import vendorsListSlice from "../../modules/notes-detail/Slice/vendorsListSlice";
import teamListSlice from "../../modules/team/Slice/teamListSlice";
import teamDetailSlice from "../../modules/team-detail/Slice/teamDetailSlice";
import termsAndConditionsSlice from '../../mobile/modules/TermsAndConditions/Slice/getContentSlice';
import privacyPolicySlice from '../../mobile/modules/PrivacyPolicy/Slice/getContentSlice'; 

export const MainListReducers = {
  User,

  auth: authmodelSlice,
  quotes: quotesSlice,
  quotes_detail: quotesDetailSlice,
  orders: ordersSlice,
  orderDetail: ordersDetailSlice,
  user_detail: userProfileSlice,

  notesList_vendor: notesListSlice,
  commentsList_vendor: commentsListSlice,
  orderDetail_vendor: vendorOrdersDetailSlice,
  orderList_vendor: orderListSlice,
  childAccountsList_vendor: childAccountsListSlice,
  vendorsList: vendorsListSlice,
  teamsList_vendor: teamListSlice,
  teamDetail_vendor: teamDetailSlice,

  messages: getChatSlice,
  createdRoom: createRoomSlice,
  termsAndConditions_user: termsAndConditionsSlice,
  privacypolicy_user: privacyPolicySlice,
};

const root = combineReducers({ ...MainListReducers });

export default root;
