import React from "react";
import { useSelector } from 'react-redux';

import Header from "./Header";
import Footer from "./Footer";
import LocalStorage from "../../defined/localStorage";

const brand = JSON.parse(LocalStorage.getItem('branding'))

const Layout = ({ children, noAuth, noFooter, ...rest }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  const { showLargeHeader } = rest
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: whiteLabel?.mobile_background_color || brand?.mobile_background_color || "#fff"
      }}
    >
      <Header noAuth={noAuth} {...rest}/>
      <div
        style={{
          width: "100%",
          height: showLargeHeader ? "64%": noFooter ? "88%": "76%",
          marginTop: "-5%"
        }}
      >
        <div
          style={{
            width: "100%",
            height: noFooter ? "100%": showLargeHeader ? "100%": "100%",
            borderRadius: "22px 22px 0 0",
            overflowY: "auto",
            overflowX: "auto",
            backgroundColor: whiteLabel?.mobile_background_color || brand?.mobile_background_color || "#fff",
          }}
        >
          {children}
        </div>
      </div>
      {(!noAuth && !noFooter) ? <Footer />: <></>}
    </div>
  );
};

export default Layout;
