import Nav from "../../components/NavigationBar"
import Layout from "../../components/Layout"
import Utils from "../../../utils"
import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Stack } from '@mui/material'
import deleteIcon from '../../../../src/assets/image/png/delete.png';
import logout from '../../assets/logout.png'
import { OutlinedButton } from '../../common/inputs/Button';
import { Box, Container, styled, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccountAPI } from "../../modules/Auth/Action";
import { translate } from "../../../helper";
import i18n from "../../../I18";
import { signin } from "../../modules/Auth/Slice/authSlice";
import LocalStorage from '../../../defined/localStorage';
import "./style.css"
import { successUserDetailReducer } from "../../modules/UserProfile/Slice/userProfileSlice";
import { userDetailApiCall } from "../../modules/UserProfile/Action";

const brand = JSON.parse(LocalStorage.getItem('branding'))

const PageBox = styled('div')(({ }) => ({
    'position': "relative",
    'width': "100%",
    'marginTop': '-15px',
    'textAlign': "center",
    'height': '100%',
    'overflowY': 'auto'
}));

function Settings() {
    const whiteLabel = useSelector((state) => state.User?.branding);
    const { data: authData } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openLanguageSelector, setOpenLanguageSelector] = React.useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const { data: { user: { profile } = {} } } = useSelector(state => state.auth);

    React.useEffect(() => {
        userDetailApiCall(dispatch, authData);
      }, [dispatch]);

    const handleClose = () => {
        setOpen(false);
        setIsLogoutModalOpen(false)
    };

    const handleLogout = () => {
        localStorage.clear();
        Utils.showAlert(1, 'Logout successfully');
        dispatch(
            successUserDetailReducer({
                data: null
            })
        )
        dispatch(signin({ data: {} }))
        navigate(Utils.EndPoint.userMobileEndpoints.signin);
    }



    return (
        <Layout showCentreProfile showLargeHeader centreProfile>
            <PageBox className='settings'
                sx={{
                    'backgroundColor': whiteLabel?.mobile_background_color || brand?.mobile_background_color || 'white',
                    'paddingTop': "30px"
                }}
            >
                <Nav style={{ marginTop: "20px" }} path={Utils.EndPoint.userMobileEndpoints.updateProfile} label={translate('myProfile')} />
                <Nav path={Utils.EndPoint.userMobileEndpoints.paymentMethod} label={translate('payment')} />
                <Nav path={Utils.EndPoint.userMobileEndpoints.changePassword} label={translate('changePassword')} />
                <Nav path={Utils.EndPoint.userMobileEndpoints.notifications} label={translate('notifications')} />
                <Nav path={Utils.EndPoint.userMobileEndpoints.terms} label={translate('termsAndConditions')} />
                <Nav path={Utils.EndPoint.userMobileEndpoints.privacy} label={translate('privacyAndPolicy')} />
                <Nav onClick={() => setOpen(true)} label={translate('deleteAccount')} />
                <Nav onClick={() => setOpenLanguageSelector(true)} label={translate('chooseLanguage')} />
                <Nav onClick={() => setIsLogoutModalOpen(true)} label={translate('log_out')} />
            </PageBox>
            <Container>
                <div>
                    {
                        openLanguageSelector && (
                            <LanguageSelectionModal
                                isOpen={openLanguageSelector}
                                onClose={() => setOpenLanguageSelector(false)}
                            />
                        )
                    }
                    <Dialog fullWidth={true} open={open || isLogoutModalOpen} onClose={handleClose}>
                        <DialogTitle style={{ textAlign: 'center' }}>
                            <img style={{ width: '100px', height: '87px' }} src={open ? deleteIcon : logout} />
                            <Typography fontSize={'16px'} fontWeight={'400'} margin={'30px 0'}>{open ? translate('accountDeletingConfirmation') : translate('logoutConfirmation')} </Typography>
                            <Typography fontSize={'16px'} fontWeight={'600'} margin={'20px 0'}>{translate('confirmation')}?</Typography>
                        </DialogTitle>
                        <DialogContent style={{ padding: '30px 0 0 0' }}>
                            <DialogContentText style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }} className='warningButton' onClick={() => handleClose()}>
                                    <OutlinedButton style={{ width: '100%', border: 'none', borderTop: '1px solid rgba(255, 255, 255, 0.5)', borderRadius: 0, borderRight: '1px solid rgba(255, 255, 255, 0.5)', boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', color: "#000" }} type='submit'>{translate('no')}</OutlinedButton>
                                </div>
                                <div style={{ width: '50%' }} className='warningButton'>
                                    <OutlinedButton style={{ width: '100%', border: 'none', borderTop: '1px solid rgba(255, 255, 255, 0.5)', borderRadius: 0, borderRight: '1px solid rgba(255, 255, 255, 0.5)', boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.25)', color: "#FF2020" }} type='submit' onClick={() => {
                                        if (open) deleteAccountAPI(profile.id)
                                        if (isLogoutModalOpen) handleLogout()
                                    }}>{translate('yes')}</OutlinedButton>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
            </Container>
        </Layout>
    )
}

const LanguageSelectionWrapper = styled('div')(({ theme }) => ({
    '& .MuiInputBase-root': {
        'width': '100%'
    }
}))

const LanguageSelectionModal = ({ isOpen, onClose }) => {

    const [language, setLanguage] = React.useState('');

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>{translate('chooseLanguage')}</DialogTitle>
            <DialogContent>
                <LanguageSelectionWrapper>
                    <Select
                        displayEmpty
                        onChange={(evt) => {
                            setTimeout(() => {
                                i18n.changeLanguage(evt.target.value, () => {
                                    window.location.reload();
                                })
                                setLanguage(evt.target.value);
                            }, 500);
                        }}
                        value={language}
                        id='languages'
                        renderValue={
                            language !== ''
                                ? undefined
                                : () => <span style={{ color: '#ababab' }}>{translate('selectLanguage')}</span>
                        }
                    >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'es'}>Spanish</MenuItem>
                    </Select>
                </LanguageSelectionWrapper>
            </DialogContent>
        </Dialog>
    )
}

export default Settings