import React from 'react';
import { Avatar, Badge, IconButton, Stack, Typography, styled } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Utils from '../../utils';
import MainLogo from '../../../src/assets/image/png/Logotheme.png';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { getValueFromObjectByKeys, translate } from '../../helper';
import LocalStorage from '../../defined/localStorage';
import { UserAction } from '../../redux/action';
import withNavigate from '../../component/WithNavigate';
import ImageZoom from '../../common/image-zoom';
import bellring from '../../assets/image/svg/notification.svg'
import ReconnectingWebSocket from 'reconnecting-websocket';
import { userDetailApiCall } from '../modules/UserProfile/Action';
import { useDispatch } from 'react-redux';
// import { toast } from 'react-toastify';

const brand = JSON.parse(LocalStorage.getItem('branding'))

const StyledHeader = styled('div')(({ theme, isLarge }) => {
  const whiteLabel = useSelector((state) => state.User?.branding);
  return ({
    'backgroundColor': whiteLabel?.mobile_top_banner_color || brand?.mobile_top_banner_color || '#ffd22f',
    'padding': '15px 0px 30px 0px',
    'textTransform': 'uppercase',
    'display': 'flex',
    'alignItems': 'flex-end',
    'justifyContent': 'center !important',
    'height': isLarge ? '24%' : '12%',
    'minHeight': '100px',
    '& .imageContainer': {
      'backgroundImage': 'url("../../Images/signupTop.png")',
      'backgroundPosition': 'center',
      'backgroundRepeat': 'no-repeat',
      'backgroundSize': 'cover',
      'display': 'flex',
      'position': 'relative',
    },
    '& button': {
      'color': '#fff'
    },
    '& .MuiBadge-badge': {
      'right': '1px !important',
      'background': 'red',
      'fontWeight': '700',
    }
  })
});

let wsNotifications = null;

function Header({ title, showCentreProfile, noAuth, showNotification, showProfileIcon, showNavigation, backTo, notificationsData, getNotifications, showLargeHeader = false, centreProfile, withoutname }) {
  const { data: authData } = useSelector(state => state.auth);
  const { data: userDetail, loading } = useSelector(state => state.user_detail);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whiteLabel = useSelector((state) => state.User?.branding);

  React.useEffect(() => {
    if (!authData.token) {
      return;
    }
    if (wsNotifications) {
      return;
    }
    wsNotifications = new ReconnectingWebSocket(
      `wss://${Utils.constants.API_URL.replace('https://', '')}ws/home/notifications/?token=${authData.token}`
    );
    // wsNotifications.addEventListener('open', async () => {
    //   const notifications = await getNotifications();
    //   toast(notifications.data[0].content);
    // });

    wsNotifications.onmessage = (event) => {
      getNotifications();
    };
    if (authData.token) {
      userDetailApiCall(dispatch, authData);
    }
  }, [authData])

  return (
    <StyledHeader isLarge={showLargeHeader}>
      {
        noAuth ? (
          <div className='imageContainer'>
            <Avatar
              alt="Profile Image"
              sx={{ width: 70, height: 70, borderRadius: '5px' }}
              src={whiteLabel?.mobile_logo || brand?.mobile_logo || MainLogo}
            />
          </div>
        ) : (

          <Stack style={{ width: "100%" }} direction='row' justifyContent={'space-between'} alignItems={'center'} margin={'0 20px'}>
            {
              showProfileIcon && (
                <ImageZoom
                  imageSrc={userDetail?.profile_image}
                  altSrc={userDetail?.profile_image}
                  fullScreen
                >
                  <Avatar
                    alt="Profile Image"
                    sx={{ width: 40, height: 40, border: '1px solid white', borderRadius: '50px'}}
                    src={userDetail?.profile_image}
                  />
                </ImageZoom>
              )
            }
            {
              showNavigation && (
                <IconButton
                  onClick={() => {
                    if (backTo) {
                      backTo();
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
              )
            }
            {
              !showProfileIcon && !showNavigation && centreProfile && <div style={{ width: "40px" }} />
            }
            {
              title && (
                <Typography color='#fff' fontSize='18px' fontWeight={'600'} sx={{ textTransform: 'uppercase' }}>{translate(title)}</Typography>
              )
            }
            {
              showCentreProfile && (
                <ImageZoom
                  imageSrc={userDetail?.profile_image}
                  altSrc={userDetail?.profile_image}
                  fullScreen
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: !withoutname ? "12px": "0px" }}>
                    <Avatar
                      alt="Profile Image"
                      sx={{ width: withoutname?70:75, height: withoutname?70:75, border: '1px solid white', borderRadius: '50px', marginBottom: !withoutname ? "12px": "0px" }}
                      src={userDetail?.profile_image}
                    />
                    {!withoutname&&<Typography marginTop={'2px'} color={'white'} lineHeight={'15.9px'} fontWeight={'600'} variant='span'>{userDetail?.full_name}</Typography>}
                  </div>
                </ImageZoom>
              )
            }
            {
              showNotification && (
                <IconButton onClick={() => navigate(Utils.EndPoint.userMobileEndpoints.userNotification)}>
                  <Badge badgeContent={notificationsData?.length > 0 ? notificationsData[notificationsData?.length - 2].unread_count : undefined}>
                    <img style={{ width: '30px', height: '30px' }} src={bellring} />
                  </Badge>
                </IconButton>

              )
            }
            {/* This is added to put profile photo oin center by adjusting some margin */}
            {
              !showNotification && (
                <IconButton>
                  <ArrowBackIosIcon style={{ visibility: 'hidden' }} />
                </IconButton>
              )
            }
          </Stack>
        )
      }

    </StyledHeader>
  )
}

const mapStateToProps = (state) => {
  return {
    notificationsData: getValueFromObjectByKeys(state, ['User', 'notifications']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: () => {
      return dispatch(UserAction.getNotifications());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Header));
