import { request } from '../connection/ApiDefine';

function getProfile() {
  return request
    .get(`accounts/profile/vendor/`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log('error=>', error);
      throw error;
    });
}

export const profileAPIService = {
  getProfile
};
