import { StyleSheet } from 'aphrodite';
import { heightOfHeader, sizeOfBaseButton } from '../../utils/defined';
import { convertPxToRem } from '../../helper';
import { Colors } from '../../themes/Colors';

const heightOfBottomBlackAlpha = convertPxToRem(284);

export const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    whiteSpace: 'pre-line',
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(30),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    // backgroundColor: Colors.content_page,
    paddingBottom: convertPxToRem(82)
  }
});
