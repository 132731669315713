import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
    error: {},
}

const createRoomSlice = createSlice({
    name: 'createdRoom',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successCreateRoomReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedCreateRoomReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successCreateRoomReducer, failedCreateRoomReducer, loaderListener } = createRoomSlice.actions;

export default createRoomSlice.reducer;