/* eslint-disable no-unused-vars */
// import axios from 'axios';
import SessionQueue from './SessionQueue';
import {
  convertObjectToQuery,
  getValueFromObjectByKeys,
  messageManager,
  MESSAGE_TYPE,
  myLog,
  reloadApp
} from '../helper';
import applyAppTokenRefreshInterceptor from './AxiosRefreshToken';
import axios from 'axios';
import { headerRequestKey } from '../utils/defined';
import LocalStorage from '../defined/localStorage';
import { size } from 'lodash';
import nProgress from 'nprogress';

// applyAppTokenRefreshInterceptor(axios);
const ConfigAxiosDefault = {
  withCredentials: false,
  mode: 'no-cors'
};

export const TYPE_METHOD = {
  GET: 'get',
  POST: 'post',
  OPTION: 'option',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
};

export const STATUS_CODE = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  UNAUTHORIZE: 401,
  SERVER: 497,
  CREATED: 201,
  BAD_REQUEST: 400,
  TOKEN_EXPIRED: 406,
  UNKNOWN_ERROR: 520,
  FORBIDDEN: 403,
  SIGNATURE_ERROR: 411,
  USER_BLOCK: 412,
  DEVICE_BLOCK: 413,
  REQUIRE_LOGIN: 402,
  NOT_FOUND: 404
};

export const ERROR_CODE = {
  AXIOS: { errorCode: 1, errorNameCS: 'AXIOS' },
  SERVER: { errorCode: 2, errorNameCS: 'SERVER' },
  PARSE_DATA: { errorCode: 3, errorNameCS: 'PARSE_DATA' },
  NO_INTERNET: { errorCode: 4, errorNameCS: 'NO_INTERNET' },
  OTHER: { errorCode: 5, errorNameCS: 'OTHER' },
  TIMEOUT: 'ECONNABORTED'
};

const ConfigDefault = {
  url: '',
  token: '',
  method: TYPE_METHOD.GET,
  useToken: true,
  useTmpToken: false,
  useRefreshToken: true,
  timeout: 30000,
  query: {},
  params: {},
  dataTmp: {},
  acceptCancelRequest: true,
  showMessage: true,
  useMultipartFormData: false
};

export default class Connector {
  constructor() {
    this._init();
  }

  _init() {
    Object.keys(ConfigDefault).map((row) => {
      this[row] = ConfigDefault[row];
    });
  }
  // Set Url for request
  setUrl(url) {
    myLog('setUrl--->', url);
    this.url = url;
    return this;
  }

  setAcceptCancelRequest(isAcceptCancelRequest) {
    this.acceptCancelRequest = isAcceptCancelRequest;
    return this;
  }

  setAcceptType = (accept) => {
    this.accept = accept;
    return this;
  };

  setResponseType = (responseType) => {
    this.responseType = responseType;
    return this;
  };

  setTimeOut(timeout) {
    this.timeout = timeout;
    return this;
  }

  setAccessToken(token) {
    this.token = token;
    return this;
  }

  setMethod(method) {
    this.method = method;
    return this;
  }

  setUseToken(useToken) {
    this.useToken = useToken;
    return this;
  }

  setUseTmpToken(tmpToken) {
    this.useTmpToken = tmpToken;
    return this;
  }

  setUseRefreshToken(useRefreshToken) {
    this.useRefreshToken = useRefreshToken;
    return this;
  }

  setQuery(query) {
    this.query = query;
    return this;
  }
  setShowMessage(isShow) {
    this.showMessage = isShow;
    return this;
  }

  setParams(params) {
    this.params = params;
    return this;
  }
  setUseMultipartFormData(i) {
    this.useMultipartFormData = i;
    return this;
  }

  setSessionKey(sessionKey) {
    this.sessionKey = sessionKey;
    return this;
  }

  setDataTmp(dataTmp) {
    this.dataTmp = dataTmp;
    return this;
  }

  setSession(session) {
    this.session = session;
    return this;
  }

  async getPromise() {
    return await this._fetchDataHttp();
  }

  getHeaderSetup = () => {
    const headers = {
      Accept: this.accept || 'application/json'
    };

    const accessToken = LocalStorage.getItem(LocalStorage.DEFINE_KEY.ACCESS_TOKEN);
    const tmpAccessToken = LocalStorage.getItem(LocalStorage.DEFINE_KEY.TMP_ACCESS_TOKEN);
    myLog('accessToken=>', accessToken);
    if (this.useToken) {
      headers[headerRequestKey] = `Token ${accessToken}`;
    }
    if (this.useTmpToken) {
      headers[headerRequestKey] = `Token ${tmpAccessToken}`;
    }
    if (this.useMultipartFormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    return headers;
  };

  _fetchDataHttp = async () => {
    const headers = this.getHeaderSetup();
    // myLog('headers=>', headers);
    const inputData = {
      method: this.method,
      url: this.url,
      params: this.query,
      headers: headers,
      ...ConfigAxiosDefault,
      timeout: this.timeout
    };

    if (this.responseType) {
      inputData.responseType = this.responseType;
    }

    if (this.method !== TYPE_METHOD.GET) {
      inputData.data = this.params;
    }
    myLog('----inputData---', inputData, this.query, this.params);
    const arg = {
      arg: {
        url: this.url,
        params: this.params,
        query: this.query
      }
    };
    try {
      //for session
      let session = null;
      if (this.sessionKey) {
        session = SessionQueue.addSession(this.sessionKey);
        // myLog('session_connector', this.sessionKey, session);
      }
      const fullUrl = this.url + convertObjectToQuery(this.query);
      myLog('---fetchDataStart--->', fullUrl);
      myLog('---fetchDataStartQuery--->', this.query);
      nProgress.start();
      const response = await axios(inputData);
      myLog('---fetchDataEnd--->', fullUrl);
      // myLog('---response---', response);
      const data = getValueFromObjectByKeys(response, ['data']);
      nProgress.done();
      myLog('---data after encode--', response.status, data, inputData, this.query, this.params);
      if (data === null) {
        throw {
          ...ERROR_CODE.PARSE_DATA,
          ...arg
        };
      }

      if (this.sessionKey) {
        data.session = session;
      }
      if (data && data.accessToken) {
        // LocalStorage.setItem(
        //   LocalStorage.DEFINE_KEY.accessToken,
        //   data.accessToken
        // )
      }
      switch (response.status) {
        case STATUS_CODE.SUCCESS:
        case STATUS_CODE.NO_CONTENT:
        case STATUS_CODE.CREATED:
          return {
            arg: {
              url: this.url,
              params: this.params,
              query: this.query
            },
            data
          };
        case STATUS_CODE.UNAUTHORIZE:
          // reloadApp();
          throw {
            statusCode: response.status,
            ...arg
          };
        case STATUS_CODE.NOT_FOUND:
          throw {
            statusCode: response.status,
            ...arg
          };
        default:
          throw {
            statusCode: response.status,
            ...data,
            ...ERROR_CODE.OTHER,
            ...arg
          };
      }
    } catch (error) {
      nProgress.done();
      myLog('errorBase=>', error, this.params);
      const status = getValueFromObjectByKeys(error, ['response', 'status']);
      if (status === STATUS_CODE.UNAUTHORIZE) {
        // reloadApp();
        messageManager({
          type: MESSAGE_TYPE.warning,
          messageShow: 'Please login again!'
        });
        return;
      }
      let messageError = error.message || 'Connected failed!';
      let isErrorMessage = false;
      const dataFromError = getValueFromObjectByKeys(error, ['response', 'data']);
      const finalError = size(dataFromError) ? dataFromError : error;
      Object.keys({ ...this.params, non_field_errors: 'non_field_errors' }).map((key) => {
        if (finalError[key] && !isErrorMessage) {
          if (typeof finalError[key] === 'string') {
            isErrorMessage = true;
            messageError = finalError[key];
          } else if (Array.isArray(finalError[key]) && finalError[key].length) {
            messageError = finalError[key][0];
            isErrorMessage = true;
          }
        }
      });
      // messageManager({ type: MESSAGE_TYPE.error, messageShow: 'messageError' })
      throw finalError;
    }
  };
}
