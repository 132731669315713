import React from 'react';
import { connect } from 'react-redux';
import { ROUTER_NAME } from '../../../router/routers';
import LoginInput from '../../../common/login-input';
import BasePage from '../../base';
import withNavigate from '../../../component/WithNavigate';
import {
  convertPxToRem,
  getValueFromObjectByKeys,
  MESSAGE_TYPE,
  messageManager,
  myLog,
  renderCountries,
  renderCountriesPhoneCode,
  renderStates
} from '../../../helper';
import { UserAction } from '../../../redux/action';
import TextBase from '../../../common/text';
import { Colors } from '../../../themes/Colors';
import ButtonGradient from '../../../common/button-gradient';
// import { SVGImageComponentReact } from "../../themes/Images"
import { trim } from 'lodash';
import './SignUpStepTwo.styles.css';
import { motion } from 'framer-motion';
import { Col, Grid, Row } from 'antd';
import SelectCustom from '../../../common/select-custom';
import { SVGImageComponentReact } from '../../../themes/Images';
import { css, StyleSheet } from 'aphrodite';
import { heightOfHeader, sizeOfBaseButton } from '../../../utils/defined';
import InputError from '../../../common/input-error/InputError.component';

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    // whiteSpace: "pre-line",
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: `calc(100vh - ${heightOfHeader})`,
    paddingTop: convertPxToRem(40),
    paddingLeft: convertPxToRem(32),
    paddingRight: convertPxToRem(32),
    backgroundColor: Colors.content_page,
    paddingBottom: convertPxToRem(82),
    overflow: 'none'
  },

  buttonAction: {
    width: sizeOfBaseButton.width,
    height: sizeOfBaseButton.height,
    marginBottom: convertPxToRem(33)
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    marginTop: 20
  }
});

const { useBreakpoint } = Grid;

class SignUpStepTwo extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      businessEmail: '',
      businessName: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      taxID: '',
      country: '',
      phoneCode: '',
      state: '',
      city: '',
      zip: '',
      address: '',
      showPassword: false,
      isLogin: false,
      isAcceptTerm: true,
      username: '',
      registering: false,
      errors: {},
      states: [],
      isSmScreen: window.innerWidth < 600
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    console.log('window.innerWidth', window.innerWidth);
    this.setState({
      isSmScreen: window.innerWidth < 600
    });
  };

  onChangePhone = (value) => {
    this.props.handleState('phoneNumber', value);
  };

  onChangeTaxID = (value) => {
    this.props.handleState('taxID', value);
    this.setState({ taxID: value });
  };

  onPhoneSelect = (value) => {
    console.log('value', value);
    this.props.handleState('phoneCode', value);
    this.setState({
      phoneCode: value
    });
  };

  onChangeCountry = (value) => {
    if (value !== 'US' || value !== 'CA') {
      this.props.handleState('state', undefined);
    }
    this.props.handleState('country', value);
  };

  onChangeState = (value) => {
    this.props.handleState('state', value);
  };

  onChangeCity = (value) => {
    this.props.handleState('city', value);
  };

  onChangeZip = (value) => {
    this.props.handleState('zip', value);
  };

  onChangeAddress = (value) => {
    this.props.handleState('address', value);
  };

  checkIfError = (error) => {
    console.log('error stata', error);
    return error.length === 0;
  };

  validateStepTwo = (data) => {
    const errors = this.state.errors;

    const { phoneNumber, taxID, country, state, city, zip, address } = this.props?.data;

    // Phone validation
    if (!phoneNumber) {
      errors.phone_number = ['phoneError'];
      this.setState({
        errors
      });
    } else {
      errors.phone_number = [];
      this.setState({ errors });
    }

    // Tax ID
    if (!taxID) {
      errors.tax_id = ['taxIdError'];
      this.setState({
        errors
      });
    } else {
      errors.tax_id = [];
      this.setState({ errors });
    }

    // Country validation
    if (!country) {
      errors.country = ['countryError'];
      this.setState({
        errors
      });
    } else {
      errors.country = [];
      this.setState({ errors });
    }

    // State validation
    if (country === 'US' || country === 'CA') {
      if (!state) {
        errors.state = ['stateError'];
        this.setState({
          errors
        });
      } else {
        errors.state = [];
        this.setState({ errors });
      }
    } else {
      errors.state = [];
      this.setState({ errors });
    }

    // City validation
    if (!city) {
      errors.city = ['cityError'];
      this.setState({
        errors
      });
    } else {
      errors.city = [];
      this.setState({ errors });
    }

    // Zip validation
    if (!zip) {
      errors.zip = ['zipError'];
      this.setState({
        errors
      });
    } else {
      errors.zip = [];
      this.setState({ errors });
    }

    // Address validation
    if (!address) {
      errors.address = ['addressError'];
      this.setState({
        errors
      });
    } else {
      errors.address = [];
      this.setState({ errors });
    }

    return (
      this.checkIfError(this.state.errors?.phone_number) &&
      this.checkIfError(this.state.errors?.tax_id) &&
      this.checkIfError(this.state.errors?.country) &&
      this.checkIfError(this.state.errors?.state) &&
      this.checkIfError(this.state.errors?.city) &&
      this.checkIfError(this.state.errors?.zip) &&
      this.checkIfError(this.state.errors?.address)
    );
  };

  handlerSignup = async (event) => {
    if (this.validateStepTwo()) {
      const { navigate, saveUserInfo, userInfo, register } = this.props;

      const {
        businessEmail,
        businessName,
        password,
        confirmPassword,
        phoneNumber,
        phoneCode,
        taxID,
        country,
        state,
        city,
        zip,
        address
      } = this.props?.data;

      try {
        if (
          trim(businessEmail) &&
          trim(password) &&
          phoneNumber &&
          taxID &&
          city &&
          zip &&
          address
        ) {
          const formData = {
            email: businessEmail,
            password1: password,
            password2: confirmPassword,
            business_name: businessName,
            phone_number: {
              country_code: phoneCode,
              national_number: phoneNumber
            },
            tax_id: taxID,
            country,
            state,
            city,
            zip,
            address
          };
          const res = await register(formData);
          navigate(ROUTER_NAME.createProfile);

          messageManager({
            type: MESSAGE_TYPE.success,
            messageShow: 'Account created, admin will approve you account!',
            duration: 5
          });
        } else {
        }
      } catch (errors) {
        this.setState({ errors: errors });
        if (
          errors.hasOwnProperty('email') ||
          errors.hasOwnProperty('password1') ||
          errors.hasOwnProperty('password2')
        ) {
          this.props.toggleSteps();
          this.props.handleErrors(errors);
        }
        this.setState({ registering: false });
        // messageManager({
        //   type: MESSAGE_TYPE.error,
        //   messageShow: error.message || "Login Failed!",
        // });
      }
    }
  };

  renderContent() {
    myLog('renderContent=>', this.props);
    const { phoneNumber, taxID, country, phoneCode, state, city, zip, address } = this.props.data;
    const { registering, errors, isSmScreen } = this.state;

    const { navigate } = this.props;

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.4,
          delay: 0.2,
          ease: [0, 0.71, 0.2, 1.01]
        }}>
        <div className={css(styles.flex)}>
          <div
            className="flex-row-center-center cursor-pointer"
            onClick={() => {
              this.props.toggleSteps();
            }}>
            <SVGImageComponentReact.IconArrowBack />
          </div>
          <div></div>
        </div>

        <div
          className="max-width-100"
          style={{
            paddingTop: convertPxToRem(50),
            paddingInline: convertPxToRem(20),
            paddingBottom: convertPxToRem(20)
          }}>
          <TextBase
            style={{ marginBottom: convertPxToRem(11) }}
            size={convertPxToRem(32)}
            fontWeight={600}
            color={Colors.black}>
            createBusinessProfile
          </TextBase>
          <TextBase
            size={convertPxToRem(18)}
            color={Colors.titleSignIn80}
            style={{ marginBottom: 50 }}>
            fillTheInformation_businessProfile
          </TextBase>
        </div>
        <div>
          <div className="wrapper">
            <Row gutter={{ xs: 32, sm: 16, md: 24, lg: 32 }}>
              {/* {isSmScreen} */}
              <Col span={isSmScreen ? 24 : 12}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    className="ellipsis"
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    mobileNumber
                  </TextBase>

                  <div style={{ display: 'flex' }}>
                    <div style={{ minWidth: '150px', marginRight: '10px', zIndex: 22000 }}>
                      <SelectCustom
                        className="full-width"
                        value={phoneCode}
                        placeholder="Code"
                        items={renderCountriesPhoneCode()}
                        onSelect={this.onPhoneSelect}
                      />
                    </div>
                    <div>
                      <LoginInput
                        value={phoneNumber}
                        onChange={this.onChangePhone}
                        placeholder="enterMobile"
                      />
                      <InputError error={errors.phone_number} />
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    taxId
                  </TextBase>
                  <LoginInput
                    value={taxID}
                    onChange={this.onChangeTaxID}
                    placeholder="enterTaxId"
                  />
                  <InputError error={errors.tax_id} />
                </div>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
              <Col span={12}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    headquarterCountry
                  </TextBase>
                  <SelectCustom
                    placeholder="Select country"
                    className="full-width"
                    value={country}
                    items={renderCountries()}
                    onSelect={this.onChangeCountry}
                  />
                  <InputError error={errors.country} />
                </div>
              </Col>

              {(country === 'US' || country === 'CA') && (
                <Col span={12}>
                  <div>
                    <TextBase
                      style={{ textAlign: 'left' }}
                      size={convertPxToRem(16)}
                      color={Colors.black}>
                      state
                    </TextBase>
                    <SelectCustom
                      placeholder="Select state"
                      value={state}
                      items={renderStates(country)}
                      onSelect={this.onChangeState}
                    />
                    <InputError error={errors.state} />
                  </div>
                </Col>
              )}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
              <Col span={12}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    city
                  </TextBase>
                  <LoginInput value={city} onChange={this.onChangeCity} placeholder="enterCity" />
                  <InputError error={errors.city} />
                </div>
              </Col>

              <Col span={12}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    zip
                  </TextBase>
                  <LoginInput value={zip} onChange={this.onChangeZip} placeholder="enterZip" />
                </div>
                <InputError error={errors.zip} />
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="row-margin">
              <Col span={24}>
                <div>
                  <TextBase
                    style={{ textAlign: 'left' }}
                    size={convertPxToRem(16)}
                    color={Colors.black}>
                    address
                  </TextBase>
                  <LoginInput
                    value={address}
                    onChange={this.onChangeAddress}
                    placeholder="enterAddress"
                  />
                  <InputError error={errors.address} />
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{ marginBottom: 19, marginTop: 50 }}
            className="flex-center-all max-width-100">
            <ButtonGradient
              onClick={this.handlerSignup}
              textStyle={{ fontWeight: 600 }}
              title="signUp"
              loading={registering}
            />
          </div>
        </div>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: getValueFromObjectByKeys(state, ['User', 'userInfo'])
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (params) => {
      return dispatch(UserAction.login(params));
    },
    saveUserInfo: (data) => {
      return dispatch(UserAction.saveUserInfo(data));
    },
    register: (data) => {
      return dispatch(UserAction.register(data));
    },
    createVendor: (data) => {
      return dispatch(UserAction.createVendor(data));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(SignUpStepTwo));
